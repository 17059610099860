import React, { Fragment, useState, useEffect, useRef } from "react";
import { Card, CardBody, Col, Container, Row, Table, Button, Input } from 'reactstrap';
import { Breadcrumbs } from "../../../../AbstractElements";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";
import { currencyFormatter, defaultOrderStatusColorCodes, capitalize, defaultOrderFontCode } from '../../../api/helper'
import CancelOrderModal from "./CancelOrderModal";
import { Eye, XCircle } from "react-feather";
import moment from "moment";
import { useSelector } from "react-redux";
import AgGridTable from "../../AgGridTable";
import { useCallback } from "react";
import OrderDetails from "./OrderDetails";

const ViewOrders = () => {
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [orderData, setOrderData] = useState();
    const [asoList, setAsoList] = useState([]);
    const [selectedAsoOrg, setSelectedAsoOrg] = useState();
    const [orderDetailsOpen, setOrderDetailsOpen] = useState(false);
    const [dataId, setDataId] = useState()
    const [orgId, setOrgId] = useState()
    const orderRef = useRef(null);
    
    const loadOrderData = async (val) => {
        setLoading(true)
        const options = {
            method: 'GET',
            body: {
                assetOwnerId: val
            }
        }
        const url = ['ASM_ADMIN', 'ASM_USER'].includes(userType) ? `/orders/assetOwner?assetOrgId=${val}`
            : `/orders/assetOwner`;
        await fetchWrapper(`${API_URL}${url}`)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    setLoading(false)
                    setData(res.json)
                }
            }, (err) => {
                setLoading(false)
                console.log(err)
            })
    }

    const loadAsoOrgList = async () => {
        await fetchWrapper(`${API_URL}/organization/assetmanager/mappedOrgs`)
            .then((res) => {
                const data = res.json;
                setAsoList(data?.mappedAssetOwners)
            }, (err) => { console.log(err) })
    }

    const defaultTypes = {
        ASR_USER: 'user',
        ASM_ADMIN: 'asm-admin',
        ASM_USER: 'asm-user',
        ASR_ADMIN: 'asr-admin',
        ASO_USER: 'user',
        ASO_ADMIN: 'aso-admin',
        SYS_ADMIN: 'admin',
        SYS_USER: 'back-office'
    }

    const userType = useSelector(state => state?.user?.userType);

    const handleViewOrder = (id) => {
        setOrderDetailsOpen(true)
        setDataId(id)
        if (['ASM_ADMIN', 'ASM_USER'].includes(userType)) {
            // navigate(`${process.env.PUBLIC_URL}/${defaultTypes[userType]}/carbon-trading/orders-details/${id}/${selectedAsoOrg}`)
            setOrgId(selectedAsoOrg)
        }
    }

    const tableColumns = [
        {
            headerName: 'Order ID',
            field: 'orderId',
            filter: true,
            flex: 1,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Order Date',
            field: 'orderCreatedDate',
            filter: true,
            flex: 1,
            valueGetter: (params) => {
                return moment(params.data.orderCreatedDate).format('DD MMM, YYYY')
            },
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Project Name',
            field: 'projectName',
            filter: true,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Unit Type',
            field: 'currency',
            filter: true,
            flex: 1,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Unit Rate',
            field: 'midRate',
            filter: false,
            flex: 1,
            sortable: true,
            suppressMovable: true,
            valueGetter: (params) => {
                return currencyFormatter(params.data.midRate, 'USD')
            },
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            cellClass: 'text-right pr-30'
        },
        {
            headerName: 'Quantity',
            field: 'quantity',
            filter: false,
            flex: 1,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            // cellClass: 'text-right'
        },
        {
            headerName: 'Monetary value',
            field: 'quantity',
            filter: false,
            flex: 1,
            sortable: true,
            suppressMovable: true,
            valueGetter: (params) => {
                return currencyFormatter(params.data.monetaryValue, params.data.orgCurrency)
            },
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            cellClass: 'text-right pr-30'
        },
        {
            headerName: 'Status',
            filter: false,
            width: 150,
            sortable: true,
            suppressMovable: true,
            cellRenderer: (params) => {
                return (
                    <div>
                        <p style={{
                            width: '125px', fontSize: '14px', backgroundColor: defaultOrderStatusColorCodes[params.data.orderStatus],
                            padding: '2px 10px', fontWeight: 400, borderRadius: '8px', border: `1px solid ${defaultOrderStatusColorCodes[params.data.orderStatus]}`,
                            textAlign: 'center', color: defaultOrderFontCode[params.data.orderStatus], marginTop: '7px'
                        }}>
                            {capitalize(params.data.orderStatus)}</p>
                    </div>
                )
            }
        },
        {
            headerName: 'View',
            width: 60,
            cellRenderer: params => {
                return (
                    <div className="mr-21">
                        <div onClick={() => handleViewOrder(params.data.orderId)}>
                            <Eye className={`action-icon`} />
                        </div>
                    </div>
                )
            },
            suppressMovable: true,
        },
        {
            headerName: 'Cancel',
            width: 80,
            cellRenderer: params => {
                return (
                    <div onClick={() => handleCancel(params.data, params.data.orderStatus)}>
                        <XCircle className={` ${params.data.orderStatus === "NEW" ? 'trash-icon' : 'disable-icon mt-10'}`} />
                    </div>
                )
            },
            suppressMovable: true,
        }
    ]

    const loadData = ({ target }) => {
        setSelectedAsoOrg(target.value);
        loadOrderData(target.value);
    }

    useEffect(() => {
        if (['ASM_ADMIN', 'ASM_USER'].includes(userType)) {
            loadAsoOrgList()
        }

    }, [open])

    const handleCancel = (item, status) => {
        if (status === 'NEW') {
            setOpen(true)
            setOrderData(item)
        }
    }

    const onGridReady = useCallback(async (params) => {
        orderRef.current.api.showLoadingOverlay();
        const url = ['ASM_ADMIN', 'ASM_USER'].includes(userType) ? `/orders/assetOwner?assetOrgId=${val}`
            : `/orders/assetOwner`;
        await fetchWrapper(`${API_URL}${url}`)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    orderRef.current.api.hideOverlay();
                    setData(res.json)
                }
            }, (err) => {
                orderRef.current.api.hideOverlay();
                console.log(err)
                if (err.status === 404) {
                    orderRef.current.api.showNoRowsOverlay();
                }
            })
    })

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <div className="d-flex mtop-1">
                            <Breadcrumbs parent="Carbon Trading" mainTitle='Orders' title="Orders" />
                            {
                                (['ASM_ADMIN', 'ASM_USER'].includes(userType)) && <div className="d-flex aso-dropdown">
                                    <select className="form-select" onChange={(e) => loadData(e)}>
                                        <option value=''>Select Asset Owner Organization</option>
                                        {
                                            asoList?.length > 0 && asoList?.map((item) => {
                                                return (
                                                    <option value={item?.assetOwnerId}>{item?.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            }
                        </div>
                        {open && <CancelOrderModal value={open} setOpenModal={setOpen} data={orderData} />}
                        <Card className="trading_orders">
                            <CardBody className="data-tables ptop-0">
                                <div className="order-table">
                                    <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                        <AgGridTable
                                            columnData={tableColumns}
                                            tableData={data}
                                            agRef={orderRef}
                                            onGridReady={onGridReady}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {
                orderDetailsOpen && <OrderDetails value={orderDetailsOpen} setOpenModal={setOrderDetailsOpen} dataId={dataId} orgId={selectedAsoOrg} />
            }
        </Fragment>
    )
}

export default ViewOrders;