import React, { Fragment, useEffect, useState } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import { Card, CardBody } from 'reactstrap';
import Accordion from 'react-bootstrap/Accordion';
import { carbonCredits, ghgGeneral, GHGPlatform, groups, locations, dataCapture, Net0TracePlatform, projects, general, existingProject, wallet, trading } from './faqContent';
import { useLocation } from 'react-router';
import ghg from '../../../assets/images/faq/ghg.png';
import generalImg from '../../../assets/images/faq/general.png';
import technical from '../../../assets/images/faq/tech.png';
import carbon from '../../../assets/images/faq/carbon.png';

const FaqHelp = () => {
    const location = useLocation();

    const handleScroll = (hash) => {
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    useEffect(() => {
        handleScroll(location.hash)
    }, [location]);

    return (
        <Fragment>
            <Breadcrumbs mainTitle={"FAQ & Help"} />
            <div className='d-flex justify-center'>
                <Card className='faq-card'>
                    <div className='faq-card color-dark-primary' onClick={() => handleScroll('#general')}>
                        <img src={generalImg} width={50} height={50} />
                        <h5 className='f-s-17 mtop-1'>General</h5>
                    </div>
                </Card>
                <Card className='faq-card ml-1'>
                    <div className='faq-card color-dark-primary' onClick={() => handleScroll('#technical')}>
                        <img src={technical} width={50} height={50} />
                        <h5 className='f-s-17 mtop-1'>Technical</h5>
                    </div>
                </Card>
                <Card className='faq-card ml-1'>
                    <div className='faq-card color-dark-primary' onClick={() => handleScroll('#Net0Trace')}>
                        <img src={ghg} width={50} height={50} />
                        <h5 className='f-s-17 mtop-1'>Net0Trace</h5>
                    </div>
                </Card>
                <Card className='faq-card ml-1'>
                    <div className='faq-card color-dark-primary' onClick={() => handleScroll('#carbonCredits')}>
                        <img src={carbon} width={50} height={50} />
                        <h5 className='f-s-17 mtop-1'>Carbon Credits</h5>
                    </div>
                </Card>
            </div>
            <Card className='faq'>
                <CardBody>
                    <div className='general'>
                        <h2>General</h2>
                        {
                            <Accordion flush className='mtop-1'>
                                {
                                    general?.map((item, index) => (
                                        <Accordion.Item eventKey={index}>
                                            <Accordion.Header>{item.query}</Accordion.Header>
                                            <Accordion.Body>
                                                <p>
                                                    {item.ans}
                                                </p>
                                                <ul>
                                                    {
                                                        item?.isListObjectPaired == true && item?.list?.map((i, index) => (
                                                            <li className='ml-1 mtop-1 d-flex'>
                                                                <div className='f-s-14'>
                                                                    <strong>* {i?.split('-')[0]}</strong> - {i?.split('-')[1]}
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                    {
                                                        item?.isListObjectPaired === false && item?.list?.map((i, index) => (
                                                            <li className='ml-1 mtop-1 d-flex' >
                                                                <div className='f-s-14'>
                                                                    <strong>*</strong> {i}
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                                
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))
                                }
                            </Accordion>
                        }

                    </div>
                    <div id='technical' className='mtop-1'>
                        <h2>Technical Information</h2>
                        <div className='mtop-1' id='projects'>
                            <h3 className='f-s-17'>Projects</h3>
                            <Accordion flush className='mtop-1'>
                                {
                                    projects?.map((item, index) => (
                                        <Accordion.Item eventKey={index}>
                                            <Accordion.Header>{item.query}</Accordion.Header>
                                            <Accordion.Body>
                                                <p>
                                                    {item.ans}
                                                </p>
                                                <ul>
                                                    {
                                                        item?.list?.map((i, index) => (
                                                            <li className='ml-1 mtop-1 d-flex'>
                                                                <div className='f-s-14'>
                                                                    <strong>* {i?.split('-')[0]}</strong> - {i?.split('-')[1]}
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))
                                }
                            </Accordion>
                        </div>

                        <div className='mtop-1' id='groups'>
                            <h3 className='f-s-17'>Groups</h3>
                            <Accordion flush className='mtop-1'>
                                {
                                    groups?.map((item, index) => (
                                        <Accordion.Item eventKey={index}>
                                            <Accordion.Header>{item.query}</Accordion.Header>
                                            <Accordion.Body>
                                                <p>
                                                    {item.ans}
                                                </p>
                                                <ul>
                                                    {
                                                        item?.list?.map((i, index) => (
                                                            <li className='ml-1 mtop-1 d-flex'>
                                                                <div className='f-s-14'>
                                                                    <span> <b>*</b> {i}</span>
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))
                                }
                            </Accordion>
                        </div>
                        <div className='mtop-1' id='locations'>
                            <h3 className='f-s-17'>Locations</h3>
                            <Accordion flush className='mtop-1'>
                                {
                                    locations?.map((item, index) => (
                                        <Accordion.Item eventKey={index}>
                                            <Accordion.Header>{item.query}</Accordion.Header>
                                            <Accordion.Body>
                                                <p>{item.ans}</p>
                                                <ul>
                                                    {
                                                        item?.isListObjectPaired === true && item?.list?.map((i, index) => (
                                                            <li className='ml-1 mtop-1 d-flex'>
                                                                <div className='f-s-14'>
                                                                    <strong>* {i?.split('-')[0]}</strong> - {i?.split('-')[1]}
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                    {
                                                        item?.isListObjectPaired === false && item?.list?.map((i, index) => (
                                                            <li className='ml-1 mtop-1 d-flex'>
                                                                <div className='f-s-14'>
                                                                    <strong>*</strong> {i}
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))
                                }
                            </Accordion>
                        </div>
                        <div className='mtop-1' id='dataCapture'>
                            <h3 className='f-s-17'>dataCapture</h3>
                            <Accordion flush className='mtop-1'>
                                {
                                    dataCapture?.map((item, index) => (
                                        <Accordion.Item eventKey={index}>
                                            <Accordion.Header>{item.query}</Accordion.Header>
                                            <Accordion.Body>{item.ans}</Accordion.Body>
                                        </Accordion.Item>
                                    ))
                                }
                            </Accordion>
                        </div>
                    </div>
                    <div className='mtop-1' id='Net0Trace'>
                        <h2>Net0Trace Information</h2>
                        <Accordion flush className='mtop-1'>
                            {
                                Net0TracePlatform?.map((item, index) => (
                                    <Accordion.Item eventKey={index}>
                                        <Accordion.Header>{item.query}</Accordion.Header>
                                        <Accordion.Body>
                                            <p>
                                                {item.ans}
                                            </p>
                                            <ul>
                                                {
                                                    item?.isListObjectPaired === true && item?.list?.map((i, index) => (
                                                        <li className='ml-1 mtop-1 d-flex'>
                                                            <div className='f-s-14'>
                                                                <div>
                                                                    <span>
                                                                        <strong>* {i?.split('-')[0]}</strong>
                                                                    </span> - {i?.split('-')[1]}
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                                {
                                                    !item?.isListObjectPaired && item?.list?.map((i, index) => (
                                                        <li className='ml-1 mtop-1 d-flex'>
                                                            <div className='f-s-14'>
                                                                <div>
                                                                    <span>
                                                                        <strong>*</strong> {i}
                                                                    </span> 
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))
                            }
                        </Accordion>
                    </div>
                    <div className='mtop-1' id='carbonCredits'>
                        <h2>Carbon Credits</h2>
                        <Accordion flush className='mtop-1'>
                            {
                                carbonCredits?.map((item, index) => (
                                    <Accordion.Item eventKey={index}>
                                        <Accordion.Header>{item.query}</Accordion.Header>
                                        <Accordion.Body>{item.ans}</Accordion.Body>
                                    </Accordion.Item>
                                ))
                            }
                        </Accordion>
                    </div>
                    <div className='mtop-1' id='existingProjects'>
                        <h3 className='f-s-17'>Existing Projects</h3>
                        <Accordion flush className='mtop-1'>
                            {
                                existingProject?.map((item, index) => (
                                    <Accordion.Item eventKey={index}>
                                        <Accordion.Header>{item.query}</Accordion.Header>
                                        <Accordion.Body>{item.ans}</Accordion.Body>
                                    </Accordion.Item>
                                ))
                            }
                        </Accordion>
                    </div>
                    <div className='mtop-1' id='wallet'>
                        <h3 className='f-s-17'>Wallets</h3>
                        <Accordion flush className='mtop-1'>
                            {
                                wallet?.map((item, index) => (
                                    <Accordion.Item eventKey={index}>
                                        <Accordion.Header>{item.query}</Accordion.Header>
                                        <Accordion.Body>{item.ans}</Accordion.Body>
                                    </Accordion.Item>
                                ))
                            }
                        </Accordion>
                    </div>
                    <div className='mtop-1' id='trading'>
                        <h3 className='f-s-17'>Trading</h3>
                        <Accordion flush className='mtop-1'>
                            {
                                trading?.map((item, index) => (
                                    <Accordion.Item eventKey={index}>
                                        <Accordion.Header>{item.query}</Accordion.Header>
                                        <Accordion.Body>{item.ans}</Accordion.Body>
                                    </Accordion.Item>
                                ))
                            }
                        </Accordion>
                    </div>
                </CardBody>
            </Card>

        </Fragment>
    )
}

export default FaqHelp;