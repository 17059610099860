import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { Nav, Tab, Row, Col, Tabs, Accordion, Card, FormGroup } from "react-bootstrap";
import { CardBody, Container } from "reactstrap";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";
import LayoutLoader from '../../LayoutLoader/LayoutLoader';
import { populateFiscalTwoYears, populateFiscalYear } from "../../../api/helper";
import { Fragment } from "react";
import SustainableSafePractices from "./SustainableSafePractices";
import EnvironmentalProtection from "./EnvironmentalProtection";
import ResponsibleConsumerEngagement from "./ResponsibleConsumerEngagement";

const Environmental = () => {
    const [activeKey, setActiveKey] = useState(1);
    const [financialYear, setFinancialYear] = useState();
    const [startMonth, setStartMonth] = useState();
    const [fiscalYearArray, setFiscalYear] = useState(populateFiscalTwoYears());
    const [financialYearArray, setFinancialYearArray] = useState(populateFiscalYear());
    const [previousYear, setPreviousYear] = useState();
    const [previous2Years, setPrevious2Years] = useState();
    const [mode, setMode] = useState('Edit')

    const getFinancialYearSetup = async () => {
        await fetchWrapper(`${API_URL}/orgattributes`)
            .then((res) => {
                console.log(res.json?.startMonth)
                setStartMonth(res.json?.startMonth)
            }, (err) => { console.log(err) })
    }

    const handleFinancialYear = async(value) => {
        await checkFiledStatus(value)
        setFinancialYear(value)
        if(startMonth === 'January') {
            const index = financialYearArray?.findIndex(value);
            setPreviousYear(financialYearArray[index + 1])
            setPrevious2Years(financialYearArray[index + 2])
        } else {
            const index = fiscalYearArray?.findIndex((item) => item === value);
            setPreviousYear(fiscalYearArray[index + 1])
            setPrevious2Years(fiscalYearArray[index + 2])
        }
    }

    const checkFiledStatus = async(financialYear) => {
        const bodyData = {
            reportType: "BRSR",
            financialYear: financialYear
        }
        const options = {
            method: 'POST',
            body: bodyData
        }
        await fetchWrapper(`${API_URL}/api/docx/isreportfiled`, options)
        .then((res) => {
            if(res?.status === 200 && res?.json?.reportFiledStatus === 'Filed') {
                setMode('View')
            } else {
                setMode('Edit')
            }
        }, (err) => {
            if(err?.status === 404) {
                setMode('Edit')
            }
        })
    }

    useEffect(() => {
        getFinancialYearSetup()
    }, [activeKey])

    return (
        <Fragment>
            <Container fluid={true}>
                <LayoutLoader />
                <div className="d-flex">
                    <Breadcrumbs mainTitle={'Environmental'} />
                </div>
                <Card>
                    <CardBody>
                        <Tab.Container activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                            <div className="d-flex company-profile justify-between">
                                <Nav variant="underlines">
                                    <Nav.Item className="mr-5px">
                                        <Nav.Link eventKey={'1'} className={activeKey == 1 ? 'application-active' : ''}>Sustainable Safe Practices</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={'2'} className={activeKey == 2 ? 'application-active' : ''} >Environmental Protection Efforts</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={'3'} className={activeKey == 3 ? 'application-active' : ''} >Responsible Consumer Engagement</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <div className="d-flex justify-end ">
                                    <label>Financial Year</label>
                                    {
                                        startMonth === 'January' ?
                                            <select className="form-select ml-1 w-12" value={financialYear} onChange={(e) => handleFinancialYear(e.target.value)}>
                                                <option value={''}>-Select Fiscal Year-</option>
                                                {
                                                    financialYearArray?.length > 0 && financialYearArray?.map((item, index) =>
                                                        (<option value={item} id={index}>{item}</option>))
                                                }
                                            </select> :
                                            <select className="form-select ml-1 w-12" value={financialYear} onChange={(e) => handleFinancialYear(e.target.value)}>
                                                <option value={''}>-Select Fiscal Year-</option>
                                                {
                                                    fiscalYearArray?.length > 0 && fiscalYearArray?.map((item, index) =>
                                                        (<option value={item}>{item}</option>))
                                                }
                                            </select>
                                    }
                                </div>
                            </div>
                            <Tab.Content className="mtop-1">
                                <Tab.Pane eventKey={1}>
                                    <SustainableSafePractices setActive={setActiveKey} financialYear={financialYear} mode={mode}
                                    previousYear={previousYear} previous2Years={previous2Years}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey={2}>
                                    <EnvironmentalProtection setActive={setActiveKey} financialYear={financialYear} mode={mode}
                                    previousYear={previousYear} previous2Years={previous2Years} />
                                </Tab.Pane>
                                <Tab.Pane eventKey={3}>
                                    <ResponsibleConsumerEngagement setActive={setActiveKey} financialYear={financialYear} mode={mode}
                                    previousYear={previousYear} previous2Years={previous2Years} />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    )
}

export default Environmental;