import React, { Fragment, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import {
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Button
} from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { X } from "react-feather";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import DatePicker from 'react-datepicker';
import { convertMonthToDate } from "../GhgDataCapture/ghgHelper";
import moment from "moment";

const BiocharMonitoringForm = ({ value, setOpenModal, monitoringData, projectId, frequency, gridData, setAdd }) => {
    const [open, setOpen] = useState(value)
    const { register, handleSubmit, formState: { errors }, reset, setValue, control } = useForm({ defaultValues: monitoringData });
    const [vendorId, setVendorId] = useState();
    const [vendorList, setVendorList] = useState([]);
    const [feedstockType, setFeedstockType] = useState();
    const [month, setMonth] = useState();
    const [facilityType, setFacilityType] = useState();
    const [facilityTypesArray, setFacilityTypesArray] = useState([]);
    const [productionMethodTypes, setProductionMethodTypes] = useState([]);
    const [productionMethod, setProductionMethod] = useState();
    const [productionProcess, setProductionProcess] = useState();
    const [productionProcessTypes, setProductionProcessTypes] = useState([]);
    const [feedstockArray, setFeedstockArray] = useState([]);

    const getFeedstockData = async () => {
        await fetchWrapper(`${API_URL}/codeMasterData/CC_BIO_CHAR_FEED_STOCK`)
            .then((res) => {
                setFeedstockArray(res?.json)
            }, (err) => {
                console.log(err)
            })
    }

    const getProductionMethodTypes = async () => {
        await fetchWrapper(`${API_URL}/codeMasterData/CC_BIO_CHAR_PRODUCTION_METHOD`)
            .then((res) => {
                setProductionMethodTypes(res?.json)
            }, (err) => {
                console.log(err)
            })
    }

    const getProductionProcessTypes = async () => {
        await fetchWrapper(`${API_URL}/codeMasterData/CC_BIO_CHAR_PRODUCTION_PROCESS`)
            .then((res) => {
                setProductionProcessTypes(res?.json)
            }, (err) => {
                console.log(err)
            })
    }

    const getProductionFacilityTypes = async () => {
        await fetchWrapper(`${API_URL}/codeMasterData/CC_BIO_CHAR_PRODUCTION_FACILITY`)
            .then((res) => {
                setFacilityTypesArray(res?.json)
            }, (err) => {
                console.log(err)
            })
    }

    const getAllVendors = async () => {
        await fetchWrapper(`${API_URL}/vendor/project/${projectId}`)
            .then((res) => {
                console.log(res.json)
                setVendorList(res.json);
            }, (err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getAllVendors()
        getFeedstockData()
        getProductionFacilityTypes()
        getProductionMethodTypes()
        getProductionProcessTypes()
    }, [])

    const onClose = () => {
        setOpenModal(false);
        setOpen(false);
    }

    useEffect(() => {
        if (monitoringData) {
            console.log(monitoringData)
            setFacilityType(monitoringData?.facilityType);
            setVendorId(monitoringData?.vendorId)
            setProductionMethod(monitoringData?.productionMethod)
            setFeedstockType(monitoringData?.feedStockType);
            setProductionProcess(monitoringData?.productionProcess)
            setMonth(new Date(convertMonthToDate(monitoringData?.period, monitoringData?.year)))
        }
        reset(monitoringData)
    }, [monitoringData])

    const onSubmit = async (data) => {
        console.log(data)
        let bodyData = { ...data };
        bodyData.totalMass = Number(data?.totalMass) || 0;
        bodyData.appliedMass = Number(data?.appliedMass) || 0;
        bodyData.producedMass = Number(data?.producedMass) || 0;
        bodyData.moistureContent = Number(data?.moistureContent) || 0;
        bodyData.avgTemperature = Number(data?.avgTemperature) || 0;
        bodyData.pretreatmentElectricityUtilized = Number(data?.pretreatmentElectricityUtilized) || 0;
        bodyData.pretreamentFossilFuelUtilized = Number(data?.pretreamentFossilFuelUtilized) || 0;
        bodyData.productionElectricityUtilized = Number(data?.productionElectricityUtilized) || 0;
        bodyData.productionFossilFuelUtilized = Number(data?.productionFossilFuelUtilized) || 0;
        bodyData.applicationElectricityUtilized = Number(data?.applicationElectricityUtilized) || 0;
        bodyData.applicationFossilFuelUtilized = Number(data?.applicationFossilFuelUtilized) || 0;
        bodyData.biocharFossilFuelUtilized = Number(data?.biocharFossilFuelUtilized) || 0;
        bodyData.biocharFertilizerUtilized = Number(data?.biocharFertilizerUtilized) || 0;
        bodyData.biocharTransportProductionToSite = Number(data?.biocharTransportProductionToSite) || 0;
        bodyData.biocharTransportSourceProdction = Number(data?.biocharTransportSourceProdction) || 0;
        if (!monitoringData) {
            bodyData.projectId = projectId;
            bodyData.frequency = frequency;
            bodyData.period = moment(month)?.format('MMMM')
            bodyData.year = Number(moment(month)?.format('yyyy'));
        }
        if (!gridData?.some(ele => (ele?.year === bodyData?.year && ele?.period === bodyData?.period && ele?.vendorId && bodyData?.vendorId
            && ele?.feedStockType === bodyData?.feedStockType && ele?.productionMethod === bodyData?.productionMethod
            && ele?.productionProcess === bodyData?.productionProcess && ele?.facilityType === bodyData?.facilityType && !ele?.id
        ))) {
            const options = {
                method: !monitoringData ? 'POST' : 'PUT',
                body: bodyData
            }
            let url = monitoringData ? `biocharmonitoring/${monitoringData?.id}` : `biocharmonitoring`;
            await fetchWrapper(`${API_URL}/${url}`, options)
                .then((result) => {
                    console.log(result)
                    if (result.status === 200) {
                        toast.success('Data submitted successfully!')
                        onClose();
                    }
                }).catch((err) => {
                    console.log(err)
                    if (err.status === 200) {
                        toast.success('Data submitted successfully!')
                        onClose();
                        setAdd(true)
                    } else if (err.status === 500) {
                        toast.error('Something went wrong!')
                    }
                });
        } else {
            toast?.error('Data alrdy exist for these combination..')
            return;
        }
    }

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size="lg">
                <ModalHeader>
                    <H3 attrH3={{ className: 'modal-title-color' }}>Biochar Monitoring</H3>
                    <X className="x-button" onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    <Form className="biochar-monitoring" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col lg='6'>
                                <label style={{ display: 'block' }}>Select Month <span className="text-danger">*</span></label>
                                <DatePicker
                                    className="datepicker-here form-control"
                                    selected={month}
                                    onChange={(e) => setMonth(e)}
                                    showMonthYearPicker
                                    dateFormat="MMMM, yyyy"
                                    showTwoColumnMonthYearPicker
                                    placeholderText="-Month-"
                                    maxDate={new Date(moment(new Date()).subtract(1, 'month'))}
                                />
                            </Col>
                            <Col sm='6'>
                                <FormGroup>
                                    <Label>Unit <span className="text-danger">*</span></Label>
                                    <select className='form-select' name='vendor' value={vendorId}
                                        {...register('vendorId', { required: true })}
                                        onChange={(e) => setVendorId(e.target.value)}>
                                        <option value={''}>-Select Unit-</option>
                                        {
                                            vendorList?.filter(ele => (ele?.status === 'ACTIVE') && (ele?.name !== 'Self'))?.map((item) => (
                                                <option value={item?.id}>{item?.name}</option>
                                            ))
                                        }
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='6'>
                                <FormGroup>
                                    <label>Production Method <span className="text-danger">*</span></label>
                                    <select className='form-select' name="productionMethod" value={productionMethod}
                                        {...register('productionMethod', { required: true })}
                                        onChange={(e) => setProductionMethod(e.target.value)}
                                    >
                                        <option value=''>-Select Production Method-</option>
                                        {
                                            productionMethodTypes?.map((item) => (
                                                <option value={item?.value}>{item?.value}</option>
                                            ))
                                        }
                                    </select>
                                    <span className="text-danger">{errors.productionMethod && errors.productionMethod.type === 'required'
                                        && 'Production Method Required'}</span>
                                </FormGroup>
                            </Col>
                            <Col sm='6'>
                                <FormGroup>
                                    <label>Production Process <span className="text-danger">*</span></label>
                                    <select className='form-select' name="productionProcess" value={productionProcess}
                                        {...register('productionProcess', { required: true })}
                                        onChange={(e) => setProductionProcess(e.target.value)}
                                    >
                                        <option value=''>-Select Production Process-</option>
                                        {
                                            productionProcessTypes?.map((item) => (
                                                <option value={item?.value}>{item?.value}</option>
                                            ))
                                        }
                                    </select>
                                    <span className="text-danger">{errors.productionProcess && errors.productionProcess.type === 'required'
                                        && 'Production Process Required'}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='6'>
                                <FormGroup className='mb-3'>
                                    <label>Facility Type <span className="text-danger">*</span></label>
                                    <select className='form-select' name="facilityType" value={facilityType}
                                        {...register('facilityType', { required: true })}
                                        onChange={(e) => setFacilityType(e.target.value)}
                                    >
                                        <option value=''>-Select Facility Type-</option>
                                        {
                                            facilityTypesArray?.map((item) => (
                                                <option value={item?.value}>{item?.value}</option>
                                            ))
                                        }
                                    </select>
                                    <span className="text-danger">{errors.facilityType && errors.facilityType.type === 'required'
                                        && 'Facility Type Required'}</span>
                                </FormGroup>
                            </Col>
                            <Col sm='6'>
                                <FormGroup>
                                    <label>Feedstock Type <span className="text-danger">*</span></label>
                                    <select className='form-select' name='feedStockType' value={feedstockType}
                                        {...register('feedStockType', { required: true })}
                                        onChange={(e) => setFeedstockType(e.target.value)}>
                                        <option value={''}>-Select Feedstock-</option>
                                        {
                                            feedstockArray?.map((item) => (<option value={item?.value}>{item?.value}</option>))
                                        }
                                    </select>
                                    <span className="text-danger">{errors.feedStockType && errors.feedStockType.type === 'required'
                                        && 'Feedstock Type Required'}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='6'>
                                <FormGroup>
                                    <label>Total Mass (Tonnes) <span className="text-danger">*</span></label>
                                    <input className={`form-control`} placeholder='' type="number" name='totalMass'
                                        {...register('totalMass', { valueAsNumber: true, required: true, pattern: /^[0-9]*\.?[0-9]+$/ })} />
                                    <span className='text-danger'>{errors.totalMass && errors.totalMass.type === 'required'
                                        && 'Total Mass is Required'}</span>
                                    <span className='text-danger'>{errors.totalMass && errors.totalMass.type === 'pattern'
                                        && 'Total Mass Value must be decimal'}</span>
                                </FormGroup>
                            </Col>
                            <Col sm='6'>
                                <FormGroup>
                                    <label>Produced Mass (Tonnes) <span className="text-danger">*</span></label>
                                    <input className={`form-control`} placeholder='' type="number" name='producedMass'
                                        {...register('producedMass', { required: true, pattern: /^[0-9]*\.?[0-9]+$/ })} />
                                    <span className='text-danger'>{errors.producedMass && errors.producedMass.type === 'required'
                                        && 'Produced Mass is Required'}</span>
                                    <span className='text-danger'>{errors.producedMass && errors.producedMass.type === 'pattern'
                                        && 'Produced Mass Value must be decimal'}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='6'>
                                <FormGroup>
                                    <label>Applied Mass (Tonnes) <span className="text-danger">*</span></label>
                                    <input className={`form-control`} placeholder='' type="number" name='appliedMass'
                                        {...register('appliedMass', { required: true, pattern: /^[0-9]*\.?[0-9]+$/ })} />
                                    <span className='text-danger'>{errors.appliedMass && errors.appliedMass.type === 'required'
                                        && 'Applied Mass is Required'}</span>
                                    <span className='text-danger'>{errors.appliedMass && errors.appliedMass.type === 'pattern'
                                        && 'Applied Mass Value must be decimal'}</span>
                                </FormGroup>
                            </Col>
                            <Col sm='6'>
                                <FormGroup>
                                    <label>Moisture Content (%) <span className="text-danger">*</span></label>
                                    <input className={`form-control`} placeholder='' type="number" name='moistureContent'
                                        {...register('moistureContent', { required: true, pattern: /^[0-9]*\.?[0-9]+$/, max: 100, min: 0 })} />
                                    <span className='text-danger'>{errors.moistureContent && errors.moistureContent.type === 'required'
                                        && 'Moisture Content is Required'}</span>
                                    <span className='text-danger'>{errors.moistureContent && errors.moistureContent.type === 'pattern'
                                        && 'Moisture Content Value must be decimal'}</span>
                                    <span className="text-danger">{errors.moistureContent && (errors.moistureContent.type === 'max'
                                        || errors.moistureContent.type === 'min') && 'Moisture Content value should be in between 0 to 100'}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='6'>
                                <FormGroup>
                                    <label>Average Temperature (°C)</label>
                                    <input className={`form-control`} placeholder='' type="number" name='avgTemperature'
                                        {...register('avgTemperature', { pattern: /^[0-9]*\.?[0-9]+$/, max: 100, min: 0 })} />
                                    <span className='text-danger'>{errors.avgTemperature && errors.avgTemperature.type === 'required'
                                        && 'Average Temperature is Required'}</span>
                                    <span className='text-danger'>{errors.avgTemperature && errors.avgTemperature.type === 'pattern'
                                        && 'Average Temperature Value must be decimal'}</span>
                                    <span className="text-danger">{errors.avgTemperature && (errors.avgTemperature.type === 'max'
                                        || errors.avgTemperature.type === 'min') && 'Average Temperature value should be in between 0 to 100'}</span>
                                </FormGroup>
                            </Col>
                            <Col sm='6'>
                                <FormGroup>
                                    <label>Electricity utilized for pre treatment (MWh)</label>
                                    <input className={`form-control`} placeholder='' type="number" name='pretreatmentElectricityUtilized'
                                        {...register('pretreatmentElectricityUtilized', { pattern: /^[0-9]*\.?[0-9]+$/ })} />
                                    <span className='text-danger'>{errors.pretreatmentElectricityUtilized && errors.pretreatmentElectricityUtilized.type === 'pattern'
                                        && 'Value must be decimal'}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='6'>
                                <FormGroup>
                                    <label>Fossil fuels utilized for pre-treatment (Liter)</label>
                                    <input className={`form-control`} placeholder='' type="number" name='pretreamentFossilFuelUtilized'
                                        {...register('pretreamentFossilFuelUtilized', { pattern: /^[0-9]*\.?[0-9]+$/ })} />
                                    <span className='text-danger'>{errors.pretreamentFossilFuelUtilized && errors.pretreamentFossilFuelUtilized.type === 'pattern'
                                        && 'Value must be decimal'}</span>
                                </FormGroup>
                            </Col>
                            <Col sm='6'>
                                <FormGroup>
                                    <label>Electricity utilized for starting the reactor at production (MWh)</label>
                                    <input className={`form-control`} placeholder='' type="number" name='productionElectricityUtilized'
                                        {...register('productionElectricityUtilized', { pattern: /^[0-9]*\.?[0-9]+$/ })} />
                                    <span className='text-danger'>{errors.productionElectricityUtilized && errors.productionElectricityUtilized.type === 'pattern'
                                        && 'Value must be decimal'}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='6'>
                                <FormGroup>
                                    <label>Fossil fuels utilized for starting the reactor at production (Liter)</label>
                                    <input className={`form-control`} placeholder='' type="number" name='productionFossilFuelUtilized'
                                        {...register('productionFossilFuelUtilized', { pattern: /^[0-9]*\.?[0-9]+$/ })} />
                                    <span className='text-danger'>{errors.productionFossilFuelUtilized && errors.productionFossilFuelUtilized.type === 'pattern'
                                        && 'Value must be decimal'}</span>
                                </FormGroup>
                            </Col>
                            <Col sm='6'>
                                <FormGroup>
                                    <label>Electricity utilized for processing biochar for application (MWh)</label>
                                    <input className={`form-control`} placeholder='' type="number" name='applicationElectricityUtilized'
                                        {...register('applicationElectricityUtilized', { pattern: /^[0-9]*\.?[0-9]+$/ })} />
                                    <span className='text-danger'>{errors.applicationElectricityUtilized && errors.applicationElectricityUtilized.type === 'pattern'
                                        && 'Value must be decimal'}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='6'>
                                <FormGroup>
                                    <label>Fossil fuels utilized for processing of biochar for application (Liter)</label>
                                    <input className={`form-control`} placeholder='' type="number" name='applicationFossilFuelUtilized'
                                        {...register('applicationFossilFuelUtilized', { pattern: /^[0-9]*\.?[0-9]+$/ })} />
                                    <span className='text-danger'>{errors.applicationFossilFuelUtilized && errors.applicationFossilFuelUtilized.type === 'pattern'
                                        && 'Value must be decimal'}</span>
                                </FormGroup>
                            </Col>
                            <Col sm='6'>
                                <FormGroup>
                                    <label>Fossil fuel used for utilization of biochar (Liter)</label>
                                    <input className={`form-control`} placeholder='' type="number" name='biocharFossilFuelUtilized'
                                        {...register('biocharFossilFuelUtilized', { pattern: /^[0-9]*\.?[0-9]+$/ })} />
                                    <span className='text-danger'>{errors.biocharFossilFuelUtilized && errors.biocharFossilFuelUtilized.type === 'pattern'
                                        && 'Value must be decimal'}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='6'>
                                <FormGroup>
                                    <label>Fertilizer products used for utilization of biochar (Kg)</label>
                                    <input className={`form-control`} placeholder='' type="number" name='biocharFertilizerUtilized'
                                        {...register('biocharFertilizerUtilized', { pattern: /^[0-9]*\.?[0-9]+$/ })} />
                                    <span className='text-danger'>{errors.biocharFertilizerUtilized && errors.biocharFertilizerUtilized.type === 'pattern'
                                        && 'Value must be decimal'}</span>
                                </FormGroup>
                            </Col>
                            <Col sm='6'>
                                <FormGroup>
                                    <label>Transportation of waste biomass from sourcing to biochar production facility (Km)</label>
                                    <input className={`form-control`} placeholder='' type="number" name='biocharTransportSourceProdction'
                                        {...register('biocharTransportSourceProdction', { pattern: /^[0-9]*\.?[0-9]+$/ })} />
                                    <span className='text-danger'>{errors.biocharTransportSourceProdction && errors.biocharTransportSourceProdction.type === 'pattern'
                                        && 'Distance must be in decimal'}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='6'>
                                <FormGroup>
                                    <label>Transportation of biochar from the production facility to the site of end application (Km)</label>
                                    <input className={`form-control`} placeholder='' type="number" name='biocharTransportProductionToSite'
                                        {...register('biocharTransportProductionToSite', { pattern: /^[0-9]*\.?[0-9]+$/ })} />
                                    <span className='text-danger'>{errors.biocharTransportProductionToSite && errors.biocharTransportProductionToSite.type === 'pattern'
                                        && 'Distance must be in decimal'}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className="d-flex mtop-1">
                            <Button color="primary">Submit</Button>
                            <Button className="ml-1" onClick={onClose}>Back</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default BiocharMonitoringForm;