import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Row, Col, Card, CardBody, Badge, Label, Button } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { useNavigate, useParams } from 'react-router-dom';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import AgGridTable from '../AgGridTable';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { defaultUserType, thousandSeparator } from '../../api/helper';
import { Edit, Trash2 } from 'react-feather';
import { toast } from 'react-toastify';
import { Plus } from "react-feather";
import ButtonLoader from '../ButtonLoader';
import VendorModal from './VendorModal';


const VendorList = ({ projectId }) => {
    const [vendors, setVendors] = useState([]);
    const [open, setOpen] = useState(false);
    const vendorRef = useRef(null);
    const [action, setAction] = useState('Add');
    const [data, setData] = useState();

    const getAllVendors = async () => {
        await fetchWrapper(`${API_URL}/vendor/project/${projectId}`)
            .then((res) => {
                console.log(res.json)
                setVendors(res.json)
            }, (err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getAllVendors()
    }, [])

    const handleEdit = (item) => {
        setData(item)
        setAction('Edit')
        setOpen(true)
    }

    const handleStatus = async (id, status) => {
        let updateStatus = status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
        const options = {
            method: 'PUT'
        }
        await fetchWrapper(`${API_URL}/vendor/${id}/${updateStatus}`, options)
            .then((res) => {
                if (res.status === 200) {
                    if (res?.json?.status === 'INACTIVE') {
                        toast.error('Vendor has been disabled!')
                    } else {
                        toast.success('Vendor has been enabled!')
                    }
                    getAllVendors()
                }
            })
            .catch(err => console.log(err))
    }

    const columnData = [
        {
            headerName: 'Name',
            field: 'name',
            filter: true,
            flex: 2.5,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap'
        },
        {
            headerName: 'Email Id',
            field: 'email',
            filter: true,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Status',
            filter: false,
            width: 100,
            sortable: true,
            suppressMovable: true,
            cellRenderer: (params) => {
                return (
                    <div className='mr-36'>
                        <label className="switch">
                            <input type="checkbox" id="togBtn" checked={params.data.status === 'ACTIVE' ? true : false}
                                onChange={() => handleStatus(params.data.id, params.data.status)}
                            />
                            <div className="slider round">
                                <span className="on"></span>
                                <span className="off"></span>
                            </div>
                        </label>
                    </div>
                )
            }
        },
        {
            headerName: 'Edit',
            width: 90,
            cellRenderer: params => {
                return (
                    <div onClick={() => handleEdit(params.data)}>
                        <Edit className={`edit-icon`} />
                    </div>
                )
            },
            suppressMovable: true,
        },
    ];

    const handleOpen = () => {
        setData()
        setAction('Add')
        setOpen(true)
    }

    return (
        <Fragment>
            <Row>
                <Col sm='12'>
                    <Card className=" newtable">
                        <CardBody className='data-tables ptop-0'>
                            <div className="d-flex mtop-1">
                                <h3>Vendors List</h3>
                                <div className="text-right  action_button vendor-add" >
                                    <div className="link" onClick={handleOpen}>
                                        <i style={{ fontSize: '25px', color: "#2b7704" }} className="icofont  icofont-plus-circle" ></i>
                                        &nbsp;&nbsp;{"Add New"}</div>
                                </div>
                            </div>

                            <div className="groups-table mtop-1">
                                <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                    <AgGridTable
                                        columnData={columnData}
                                        tableData={vendors}
                                        agRef={vendorRef}
                                    // onGridReady={onGridReady}
                                    />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                open && <VendorModal value={open} setOpenModal={setOpen} setUpdate={(e) => {e === true && getAllVendors()}} preData={data} action={action} projectId={projectId} />
            }
        </Fragment>
    )
}

export default VendorList;