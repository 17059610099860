import React, { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { Link } from 'react-router-dom';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import AddSubOrganization from './AddSubOrganization';
import { useSelector } from 'react-redux';
import { capitalize, thousandSeparator } from '../../api/helper';
import AgGridTable from '../AgGridTable';
import { GiGearHammer } from "react-icons/gi";
import moment from 'moment';

const SubOrganizationList = () => {
    const [open, setOpen] = useState(false);
    const [orgList, setOrgList] = useState([])
    const [addStatus, setAddStatus] = useState(false);
    
    const orgRef = useRef(null);

    const loadData = async () => {
        orgRef?.current?.api?.showLoadingOverlay();
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/ownership/getActiveChildOrgDetails`, options)
            .then(res => {
                console.log(res)
                orgRef?.current?.api?.hideOverlay();
                setOrgList(res.json)
            })
            .catch((err) => {
                orgRef?.current?.api?.hideOverlay();
                console.log(err)
            })
    }

    const columnData = [
        {
            headerName: 'Company Name',
            field: 'companyName',
            filter: true,
            flex: 1.5,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap'
        },
        {
            headerName: 'Country',
            field: 'country',
            flex: 1,
            sortable: false,
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap'
        },
        {
            headerName: 'Control Type',
            field: 'controlType',
            filter: false,
            flex: 0.7,
            sortable: false,
            suppressMovable: true,
            valueGetter: (params) => {
                return capitalize(params.data.controlType);
            }
        },
        {
            headerName: 'Control Percentage (%)',
            field: 'controlTypePercentage',
            filter: false,
            flex: 0.9,
            sortable: false,
            suppressMovable: true,
            cellRenderer: (params) => {
                return <div className="text-right w-150px">{params?.data?.controlTypePercentage ?
                    thousandSeparator(params?.data?.controlTypePercentage.toFixed(2)) : ''}</div>;
            },
        },
        {
            headerName: 'Effective Date',
            field: 'effectiveDate',
            filter: false,
            flex: 0.7,
            sortable: false,
            suppressMovable: true,
            valueGetter: (params) => {
                return moment(params.data?.effectiveDate).format('DD MMM, YYYY')
            }
        },
        {
            headerName: 'End Date',
            field: 'endDate',
            filter: false,
            flex: 0.5,
            sortable: false,
            suppressMovable: true,
            cellRenderer: (params) => {
                return params.data.endDate ? <div>{moment(params.data?.endDate).format('DD MMM, YYYY')}</div> : <div className='active-bg d-flex'>Active</div>
            }
        },
        {
            headerName: 'Manage Ownership',
            flex: 0.9,
            filter: true,
            cellRenderer: (params) => (
                <Link to={`${process.env.PUBLIC_URL}/ownership-history/${params.data.childOrgId}/${params.data.companyName}`}>
                    <GiGearHammer className={`ownership-icon`} />
                </Link>
            ),
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            }
        }

    ];

    const onGridReady = useCallback(async (params) => {
        loadData()
    })

    useEffect(() => {
        if (addStatus === true) {
            loadData()
        }
    }, [addStatus])

    const handleClick = () => setOpen(true);

    return (
        <Fragment>
            <LayoutLoader />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <div className="d-flex">
                            <Breadcrumbs mainTitle="Child Companies" parent="Organizations" title="List" />
                            <div className="text-right action_button ml-1">
                                <div className="link" onClick={handleClick}>
                                    <i style={{ fontSize: '25px', color: "#2b7704" }} className="icofont  icofont-plus-circle" ></i> New
                                </div>
                            </div>
                        </div>
                        <Card className=" newtable">
                            <CardBody className='data-tables responsive-view ptop-0'>
                                <div className='ownershipHistory'>
                                    <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                        <AgGridTable
                                            columnData={columnData}
                                            tableData={orgList}
                                            agRef={orgRef}
                                            onGridReady={onGridReady}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    {open && <AddSubOrganization value={open} setOpenModal={setOpen} setAdd={setAddStatus} childOrgs={orgList} />}
                </Row>

            </Container>
        </Fragment>
    );
};

export default SubOrganizationList;