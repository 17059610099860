import React, { Fragment, useState } from "react";
import { Card, CardBody, Table } from "reactstrap";
import { capitalize, thousandSeparator } from "../../api/helper";
import moment from "moment";
const DetailsPage = ({ projectData }) => {
    const defaultSize = {
        'LESSTHAN20K': '<20,000',
        'BETWEEN20K': '20,000-100,000',
        'BETWEEN1M': '100,001–1,000,000',
        'GREATERTHAN1M': '>1,000,000'
    }

    return (
        <Card>
            <CardBody>
                <div className="table-responsive">
                    <Table className="table ">
                        <tbody>
                            <tr>
                                <td className=" fw-td-bold">Country</td> <td> {projectData?.country}, {projectData?.region}</td>
                            </tr>
                            <tr>
                                <td className="fw-td-bold">Project Category</td> <td>{projectData?.projectCategoryName}</td>
                            </tr>
                            <tr>
                                <td className="fw-td-bold">Project Subcategory</td> <td>{projectData?.projectSubCategoryName}</td>
                            </tr>
                            <tr>
                                <td className="fw-td-bold">Registry Type </td><td>{projectData?.registryName}</td>
                            </tr>
                            <tr>
                                <td className="fw-td-bold">Unit Type</td> <td>{projectData?.unitType}</td>
                            </tr>
                            <tr>
                                <td className="fw-td-bold">Assessor </td>
                                <td>{projectData?.assessor_org_name ?? 'NIL'}</td>
                            </tr>
                            <tr>
                                <td className="fw-td-bold">Assessment Type</td> <td>{projectData?.assessmenttype === "ONETIME" ? 'One Time Assessment' : 'Periodic Assessment'}</td>
                            </tr>
                            {
                                projectData?.assessmenttype === "PERIODIC" && <tr>
                                    <td className="fw-td-bold">Periodic Assessment Type</td> <td>{capitalize(projectData?.periodicassessmenttype)}</td>
                                </tr>
                            }
                            {
                                projectData?.assessmenttype === 'PERIODIC' && projectData?.assessment_due_date && <tr>
                                    <td className="fw-td-bold">Assessment Date</td>
                                    <td>{moment(projectData?.assessment_due_date, 'YYYY-MM-DD').format('DD MMM, YYYY')}</td>
                                </tr>
                            }
                            {
                                projectData?.assessmenttype === "PERIODIC" && <tr>
                                    {
                                        projectData?.frequency && <td className="fw-td-bold">Assessment Frequency</td>
                                    }
                                    {
                                        projectData?.frequency && <td>{capitalize(projectData?.frequency)}</td>
                                    }
                                    {
                                        projectData?.volume && <td className="fw-td-bold">Volume</td>
                                    }
                                    {
                                        projectData?.volume && <td>{projectData?.volume}</td>
                                    }
                                </tr>
                            }
                            <tr>
                                <td className="fw-td-bold">Registry URL</td>
                                <td>{projectData?.registry_url ? <a href={projectData?.registry_url} target="_blank">{projectData?.registry_url}</a> : 'NIL'}</td>
                            </tr>
                            {
                                (projectData?.periodicassessmenttype === 'FREQUENCY') && <tr>
                                    <td className="fw-td-bold">Data Collection Frequency</td>
                                    <td>{capitalize(projectData?.dataCollectionFrequency)}</td>
                                </tr>
                            }
                            {
                                projectData?.methodology && (projectData?.subCategoryItemName !== 'EV Charging Stations') && <tr>
                                    <td className="fw-td-bold">Methodology</td>
                                    <td>{projectData?.methodology}</td>
                                </tr>
                            }
                            {
                                projectData?.size && (projectData?.subCategoryItemName !== 'EV Charging Stations') && <tr>
                                    <td className="fw-td-bold">Project Size </td> <td>{defaultSize[projectData?.size]} {projectData?.size && 'tCO2e'}</td>
                                </tr>
                            }

                            {
                                projectData?.registryName === 'Oncra' && <tr>
                                    <td className="fw-td-bold">Carbon Baseline</td><td>{projectData?.carbon_baseline}</td>
                                </tr>
                            }
                            {
                                projectData?.average_annual_volume_of_vc_us && projectData?.subCategoryItemName !== 'EV Charging Stations' && <tr>
                                    <td className="fw-td-bold">Average Annual Carbon Units </td> <td>{thousandSeparator(projectData?.average_annual_volume_of_vc_us)} </td>
                                </tr>
                            }
                            {
                                projectData?.registryName === 'Verra' && projectData?.subCategoryItemName !== 'EV Charging Stations' && <tr>
                                    <td className="fw-td-bold">Project Associated with JNR Program </td> <td>{projectData?.isassociatedwithjnr ? 'Yes' : 'No'}</td>
                                </tr>
                            }
                            {
                                projectData?.registryName === 'Oncra' && <tr>
                                    <td className="fw-td-bold">Surface Area (ha)</td><td>{thousandSeparator(projectData?.surface_area)}</td>
                                </tr>
                            }
                            {
                                projectData?.registryName === 'Oncra' && <tr>
                                    <td className="fw-td-bold">Estimation of Carbon Capacity</td><td>{projectData?.estimation_carbon_capacity}</td>
                                </tr>
                            }
                            {
                                projectData?.registryName === 'Oncra' && <tr>
                                    <td className="fw-td-bold">Specification of full life cycle analysed operational impact</td><td>{projectData?.operational_impacts}</td>
                                </tr>
                            }
                            {
                                projectData?.registryName === 'Oncra' && <tr>
                                    <td className="fw-td-bold">Potential per ha per year (tCO2/ha/y)</td><td>{projectData?.potential_per_ha_year}</td>
                                </tr>
                            }
                            {
                                projectData?.registryName === 'Oncra' && <tr>
                                    <td className="fw-td-bold">Total sequestration potential</td><td>{projectData?.total_sequestration_potential}</td>
                                </tr>
                            }
                            {
                                projectData?.registryName === 'Oncra' && <tr>
                                    <td className="fw-td-bold">Name of field or facility</td><td>{projectData?.facility_field_name}</td>
                                </tr>
                            }
                            {
                                projectData?.registryName === 'Oncra' && <tr>
                                    <td className="fw-td-bold">Legal ownership of field or facility</td><td>{projectData?.legal_ownership}</td>
                                </tr>
                            }
                            {
                                projectData?.registryName === 'Oncra' && <tr>
                                    <td className="fw-td-bold">Total gross sequestration potential</td><td>{projectData?.total_gross_sequestration_potential}</td>
                                </tr>
                            }
                            {
                                projectData?.registryName === 'Oncra' && <tr>
                                    <td className="fw-td-bold">Project duration (Years)</td><td>{projectData?.project_duration}</td>
                                </tr>
                            }
                            {
                                projectData?.registryName === 'Oncra' && <tr>
                                    <td className="fw-td-bold">Potential whole project per year (tCO2/y)</td><td>{projectData?.potential_whole_project_year}</td>
                                </tr>
                            }
                            {
                                projectData?.registryName === 'Oncra' && <tr>
                                    <td className="fw-td-bold">Estimated carbon income at ETS price</td><td>{projectData?.estimated_carbon_income}</td>
                                </tr>
                            }
                            {
                                projectData?.registryName === 'Oncra' && <tr>
                                    <td className="fw-td-bold">Geo-location</td><td>{projectData?.geo_location}</td>
                                </tr>
                            }
                            {
                                projectData?.registryName === 'Oncra' && <tr>
                                    <td className="fw-td-bold">Remove & store carbon</td><td>{projectData?.remove_store_carbon}</td>
                                </tr>
                            }
                            {
                                projectData?.subCategoryItemName === 'EV Charging Stations' && <tr>
                                    <td className="fw-td-bold">Monitoring Level</td> <td>{projectData?.monitoringLevel}</td>
                                </tr>
                            }
                            <tr>
                                <td className="fw-td-bold">Carbon Credit Calculation Day</td>
                                <td>{projectData?.carbonCreditsCalculationDay}<sup>th</sup> of Every Month</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </CardBody>
        </Card>

    )
}

export default DetailsPage;