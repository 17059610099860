import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Badge } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import { Link } from 'react-router-dom';
import fetchWrapper from '../../../api/fetchWrapper';
import { API_URL } from '../../../Config/Config';
import { Eye, Edit, Trash2 } from 'react-feather';
import { toast } from 'react-toastify';
import AgGridTable from '../../AgGridTable';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import AddSupplier from './AddSupplier';

const dummyData = [
    {
        name: 'EcoGreen Supplies Ltd',
        contactPerson: 'John Doe',
        emailId: 'john.doe@ecogreen.com',
        phone: '+1 2345678901'
    },
    {
        name: 'EnviroCare Innovations',
        contactPerson: 'Priya Menon',
        emailId: 'priya.menon@sustaintech.in',
        phone: '+91 9987654321'
    },
    {
        name: 'GreenTech Solutions Pvt. Ltd',
        contactPerson: 'Ananya Sharma',
        emailId: 'ananya.sharma@greentech.in',
        phone: '+91 9876543210'
    }
];

const SupplierList = () => {
    const [tableData, setTableData] = useState(dummyData);
    const [showFilter, setShowFilter] = useState(false)
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [dataId, setDataId] = useState();
    const [selectedData, setSelectedData] = useState();
    const [addStatus, setAddStatus] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(false);
    const supplierRef = useRef(null);

    const userType = sessionStorage.getItem('userType');
    const rolesObject = useSelector(state => state?.user?.userRoles);

    const loadData = async (val) => {
        supplierRef?.current?.api?.showLoadingOverlay();
        await fetchWrapper(`${API_URL}/suppliers`)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    supplierRef?.current?.api?.hideOverlay();
                    setTableData(res.json)
                }
            }, (error) => {
                supplierRef?.current?.api?.hideOverlay();
                console.log(error)
                if (err.status === 404) {
                    supplierRef?.current?.api?.showNoRowsOverlay();
                }
            })
    }

    const handleDelete = (id) => {
        setDataId(id)
        setDeleteOpen(true)
    }

    const handleStatus = async (id, status) => {
        let updateStatus = status === true ? false : true;
        const options = {
            method: 'PUT'
        }
        await fetchWrapper(`${API_URL}/group/status/${id}/${updateStatus}`, options)
            .then((res) => {
                if (res.status === 200) {
                    if (res?.json?.isactive === true)
                        toast.success('Group Status enabled Successfully!')
                    else
                        toast.error('Group Status disabled Successfully!')
                    loadData()
                }
            })
            .catch(err => console.log(err))
    }

    const tableColumns = [
        {
            headerName: 'Name',
            field: 'name',
            filter: false,
            flex: 1.5,
            sortable: true,
            suppressMovable: true,
        },
        {
            headerName: 'Contact Person',
            field: 'contactPerson',
            filter: true,
            flex: 1,
            sortable: true,
            suppressMovable: true,
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Email ID',
            field: 'emailId',
            filter: true,
            flex: 1.5,
            sortable: true,
            suppressMovable: true,
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Mobile Number',
            field: 'phone',
            filter: true,
            flex: 1.5,
            sortable: true,
            suppressMovable: true,
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Status',
            filter: false,
            width: 100,
            sortable: true,
            suppressMovable: true,
            cellRenderer: (params) => {
                return (
                    <div className='mr-36'>
                        <label className="switch">
                            <input type="checkbox" id="togBtn" checked={true}
                                onChange={() => handleStatus(params.data.id, params.data.isactive)}
                            />
                            <div className="slider round">
                                <span className="on"></span>
                                <span className="off"></span>
                            </div>
                        </label>
                    </div>
                )
            }
        },
        {
            headerName: 'Edit',
            width: 60,
            cellRenderer: params => {
                return (
                    <div>
                        <Edit className={`edit-icon `} />
                    </div>
                )
            },
            suppressMovable: true,
        }
    ]

    const handleClick = () => {
        setOpen(true)
    }

    useEffect(() => {
        if ((addStatus === true) || (deleteStatus === true)) {
            loadData();
        }
    }, [addStatus, deleteStatus])

    const onGridReady = useCallback(async (params) => {
        loadData();
    })

    return (
        <Fragment>
            {/* {deleteOpen && <DeleteGroup value={deleteOpen} setOpenModal={setDeleteOpen} dataId={dataId} setDelete={setDeleteStatus} />} */}
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <div className="d-flex mtop-1">
                            <Breadcrumbs mainTitle="Suppliers Mgmt" />
                            {
                                rolesObject[2][0] === 'EMISSIONS_ADMIN' && <div className="text-right action_button mr-13">
                                    <div className="link" onClick={handleClick}>
                                        <i style={{ fontSize: '25px', color: "#2b7704" }} className="icofont  icofont-plus-circle" ></i>
                                        &nbsp;&nbsp;{"Add Supplier"}
                                    </div>
                                </div>
                            }
                        </div>
                        <Card className="newtable">
                            <CardBody className='data-tables responsive-view ptop-0'>
                                <div className="groups-table">
                                    <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                        <AgGridTable
                                            columnData={tableColumns}
                                            tableData={tableData}
                                            agRef={supplierRef}
                                            onGridReady={onGridReady}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {
                    open && <AddSupplier value={open} setOpenModal={setOpen} supplierData={selectedData} />
                }
            </Container>
        </Fragment>
    )
}

export default SupplierList;