import React, { useState } from "react";
import { Fragment } from "react";
import { H4, H3 } from '../../../AbstractElements'
import { Card, CardBody, Row, Col, Table } from "reactstrap";
import { baselineEmission, projectEmission } from "./CalculationHelper";
import EmissionModal from "./EmissionModal";

const HydroCalculation = () => {
    return (
        <Fragment>
            <Row>
                <Col lg='8'>
                    <Card>
                        <CardBody>
                            <div>
                                <div>
                                    <H3 attrH3={{ className: 'f-w-600' }}>ERy(Carbon credits) = BEy - PEy</H3>
                                </div>
                                <div className="mtop-1">
                                    <H4 attrH4={{ className: 'f-w-600' }}>Description</H4>
                                    <ul>
                                        <li>
                                            <p>PEy = Project emission in year(y) (tCO2e)</p>
                                        </li>
                                        <li>
                                            <p>BEy = Baseline emission in year(y) (tCO2e)</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg="8">
                    <Card>
                        <CardBody>
                            <p>Example</p>
                            <Table bordered striped>
                                <thead>
                                    <tr>
                                        <th className="d-flex justify-center">Year</th>
                                        <th>
                                            <div className="d-flex justify-center">
                                                Baseline Emission 
                                            </div>
                                        </th>
                                        <th>
                                            <div className="d-flex justify-center">
                                                Project Emission
                                            </div>
                                        </th>
                                        <th className="d-flex justify-center">Carbon Credits</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>2024</td>
                                        <td className="text-right">1410.50</td>
                                        <td className="text-right">0</td>
                                        <td className="text-right">1410.50</td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td className="text-right">1410.50</td>
                                        <td className="text-right">0</td>
                                        <td className="text-right">1410.50</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default HydroCalculation;