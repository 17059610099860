import React, { Fragment, useEffect, useState } from "react";
import { CardBody, CardFooter, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { X } from "react-feather";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { currencySymbol } from "../../api/currencyData";
import { currencyFormatter, handleDate } from "../../api/helper";
import ButtonLoader from '../ButtonLoader';
import { Controller, useForm } from "react-hook-form";
import DatePicker from 'react-datepicker';
import moment from "moment";
import { CustomInput } from "../../api/helper";

const UpdateOwnershipData = ({ value, setOpenModal, data, gridData, orgId, setUpdate }) => {
    const orgControl = {
        'Equity': 'EQUITY',
        'Operational': 'OPERATIONAL',
        'Financial': 'FINANCIAL'
    }
    const [open, setOpen] = useState(value);
    const { register, handleSubmit, formState: { errors }, reset, setValue, control } = useForm({ defaultValues: data });
    const [controlType, setControlType] = useState(data?.controlType);
    const [controlPercentage, setControlPercentage] = useState(orgControl[data?.controlTypePercentage])
    const [effectiveDate, setEffectiveDate] = useState(data?.effectiveDate ? new Date(data.effectiveDate) : '');
    const [endDate, setEndDate] = useState(data?.endDate ? new Date(data?.endDate) : '');

    const onClose = () => {
        setOpenModal(false);
        setOpen(false);
    }

    const isDateExceed = (date) => {
        // const startDate = new Date("2024-08-30");
        // const endDate = new Date("2025-10-23");
        // return date >= startDate && date <= endDate;
    }

    const onSubmit = async (data) => {
        console.log(data)
        const bodyData = {
            ...data,
            effectiveDate: moment(data?.effectiveDate).format('YYYY-MM-DD'),
            endDate: data?.endDate ? moment(data?.endDate).format('YYYY-MM-DD') : null,
            childOrgId: orgId
        }
        const options = {
            method: 'POST',
            body: bodyData
        }
        await fetchWrapper(`${API_URL}/ownership`, options)
            .then((res) => {
                console.log(res.json)
                if (res?.status === 200) {
                    toast.success('Ownership data saved successfully!')
                    onClose()
                }
            }, (err) => {
                console.log(err)
                if (err?.status === 200) {
                    toast.success('Ownership data saved successfully!')
                    setUpdate(true)
                    onClose()
                } else if (err.status === 500) {
                    toast.error('Something went wrong!')
                }
            })
    }

    const isDateInRange = (data, dateToCheck) => {
        return data.some((item) => {
            const effectiveDate = moment(item.effectiveDate);
            const endDate = moment(item.endDate);
            const checkDate = moment(dateToCheck);

            return checkDate.isBetween(effectiveDate, endDate, null, "[]"); // Inclusive of bounds
        });
    };

    const validateEffectiveDate = (dateValue) => {
        let activeRecord = gridData?.find(ele => (!ele?.endDate && ele?.status !== 'REJECTED'));
        if (
            ((endDate && moment(dateValue)?.isBefore(endDate)) || !endDate)
            && ((activeRecord && moment(dateValue)?.isBefore(activeRecord?.effectiveDate)) || !activeRecord)
            && !isDateInRange(gridData, dateValue)
        ) {
            setEffectiveDate(dateValue)
        }
    }

    const validateEndDate = (dateValue) => {
        let activeRecord = gridData?.find(ele => (!ele?.endDate && ele?.status !== 'REJECTED'));
        if (
            ((effectiveDate && moment(dateValue)?.isAfter(effectiveDate)) || !effectiveDate)
            && ((activeRecord && moment(dateValue)?.isBefore(activeRecord?.effectiveDate)) || !activeRecord)
            && !isDateInRange(gridData, dateValue)
        ) {
            setEndDate(dateValue)
        }
    }

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size="lg">
                <ModalHeader>
                    <H3 attrH3={{ className: 'modal-title-color' }}>Ownership Details</H3>
                    <X className="x-button" onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col lg='6'>
                                <FormGroup>
                                    <Label>Control Type<span className='required'>*</span></Label>
                                    <select className="form-select" name="controlType" value={orgControl[controlType]}
                                        {...register('controlType', { required: true })}
                                        onChange={e => setControlType(e.target.value)}
                                    >
                                        <option value=''>-Select Control Type-</option>
                                        <option value="EQUITY">Equity</option>
                                        <option value="OPERATIONAL">Operational</option>
                                        <option value="FINANCIAL">Financial</option>
                                    </select>
                                    <span className="text-danger">{errors?.controlType && 'Control Type is required'}</span>
                                </FormGroup>
                            </Col>
                            {console.log(errors)}
                            <Col lg='6'>
                                <FormGroup >
                                    <Label>Control Percentage (%)<span className='required'>*</span></Label>
                                    <input
                                        className="form-control"
                                        name="controlTypePercentage"
                                        placeholder="%"
                                        type="number"
                                        value={controlPercentage}
                                        {...register('controlTypePercentage', { required: true, min: 0, max: 100 })}
                                        onChange={(e) => setControlPercentage(e.target.value)}
                                    />
                                    <span className="text-danger">{errors.controlTypePercentage?.type === 'required' && 'Control Percentage (%) is Required'}</span>
                                    <span className="text-danger">{errors.controlTypePercentage?.type === 'min' && 'The value should be greater than 0'}</span>
                                    <span className="text-danger">{errors.controlTypePercentage?.type === 'max' && 'The value should be less than 100'}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg='6'>
                                <FormGroup className="mb-3 project-form">
                                    <label>Effective Date <span className="text-danger">*</span> </label>
                                    <Controller
                                        name='effectiveDate'
                                        control={control}
                                        rules={{
                                            required: true,
                                        }}
                                        onChange={(e) => validateEffectiveDate(e)}
                                        value={handleDate(data?.effectiveDate)}
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <DatePicker
                                                    className="datepicker-here form-control"
                                                    selected={value ? new Date(value) : ''}
                                                    onChange={e => {
                                                        validateEffectiveDate(e);
                                                        onChange(e)
                                                    }}
                                                    showMonthDropdown
                                                    dateFormatCalendar="MMMM"
                                                    showYearDropdown
                                                    yearDropdownItemNumber={15}
                                                    dropdownMode="select"
                                                    dateFormat="dd MMM, yyyy"
                                                    customInput={<CustomInput />}
                                                    maxDate={endDate ? new Date(endDate) : gridData?.find(ele => (!ele?.endDate && ele?.status !== 'REJECTED'))
                                                        ? new Date(gridData?.find(ele => (!ele?.endDate && ele?.status !== 'REJECTED'))?.effectiveDate) : ''}
                                                    excludeDateIntervals={gridData?.filter(ele => (ele?.effectiveDate && ele?.endDate && ele?.status !== 'REJECTED'))
                                                        ?.map(ele => ({ start: new Date(moment(ele?.effectiveDate)), end: new Date(ele?.endDate) }))}
                                                />
                                            )
                                        }}
                                    />
                                    <span className='text-danger'>{errors.effectiveDate && errors.effectiveDate.type === 'required' && 'Effective Date is required'}</span>
                                </FormGroup>
                            </Col>
                            {
                                effectiveDate && moment(effectiveDate)?.isSameOrBefore(new Date()) && <Col lg='6'>
                                    <FormGroup className="mb-3 project-form">
                                        <label>End Date {(gridData?.some(ele => (!ele?.endDate && ele?.status !== 'REJECTED'))
                                            || gridData?.some(ele => moment(ele?.effectiveDate).isAfter(effectiveDate))) && <span className="text-danger">*</span>}</label>
                                        <Controller
                                            name='endDate'
                                            control={control}
                                            rules={{
                                                required: (gridData?.some(ele => (!ele?.endDate && ele?.status !== 'REJECTED')) || gridData?.some(ele => moment(ele?.effectiveDate).isAfter(effectiveDate))) ? true : false
                                            }}
                                            onChange={(e) => validateEndDate(e)}
                                            value={handleDate(data?.endDate)}
                                            render={({ field: { onChange, value } }) => {
                                                return (
                                                    <DatePicker
                                                        className="datepicker-here form-control"
                                                        selected={value}
                                                        onChange={e => {
                                                            validateEndDate(e);
                                                            onChange(e)
                                                        }}
                                                        showMonthDropdown
                                                        dateFormatCalendar="MMMM"
                                                        showYearDropdown
                                                        yearDropdownItemNumber={15}
                                                        dropdownMode="select"
                                                        dateFormat="dd MMM, yyyy"
                                                        customInput={<CustomInput />}
                                                        minDate={effectiveDate ? new Date(effectiveDate) : ''}
                                                        maxDate={gridData?.find(ele => (!ele?.endDate && ele?.status !== 'REJECTED'))
                                                            ? new Date(gridData?.find(ele => (!ele?.endDate && ele?.status !== 'REJECTED'))?.effectiveDate) : new Date()}
                                                        excludeDateIntervals={gridData?.filter(ele => (ele?.effectiveDate && ele?.endDate && ele?.status !== 'REJECTED'))
                                                            ?.map(ele => ({ start: new Date(moment(ele?.effectiveDate)), end: new Date(ele?.endDate) }))}
                                                    />
                                                )
                                            }}
                                        />
                                        <span className='text-danger'>{errors.endDate && errors.endDate.type === 'required' && 'End Date is required'}</span>
                                    </FormGroup>
                                </Col>
                            }

                        </Row>
                        <div className="mtop-1">
                            <Button color="primary">Submit</Button>
                        </div>
                    </Form>

                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default UpdateOwnershipData;