import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { createProject } from '../../Common/Constant';
import fetchWrapper from '../../Common/api/fetchWrapper';
import { API_URL } from '../../Common/Config/Config';
import { Eye, Edit, Trash2 } from 'react-feather';
import DeleteProject from '../../Common/Component/DeleteProject/DeleteProject';
import { defaultStatus, defaultUserType, goalDescriptions, sdgImgList, statusIconList } from '../../Common/api/helper';
import AgGridTable from '../../Common/Component/AgGridTable';
import { useRef } from 'react';
import { useCallback } from 'react';
import ReactCountryFlag from "react-country-flag";
import { Tooltip } from 'react-tooltip';

const DataTables = (props) => {
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false)
  const [dataId, setDataId] = useState('');
  const projectRef = useRef(null);
  
  const navigate = useNavigate();

  const preValidatedStatus = ['ACCEPTED', 'SUBMITTED_TO_REGISTRY', 'APPROVED_BY_REGISTRY', 'ACTIVE', 'SUSPENDED', 'RETIRED', 'INASSESSMENT_ALLOCATED']

  const loadData = async () => {
    projectRef?.current.api.showLoadingOverlay();
    const options = {
      method: 'GET'
    }
    await fetchWrapper(`${API_URL}/projects`, options)
      .then((res) => {
        const data = res.json;
        setTableData(data)
        projectRef?.current.api.hideOverlay();
      })
      .catch((err) => {
        projectRef?.current.api.hideOverlay();
        if (err.status === 404) {
          projectRef?.current.api.showNoRowsOverlay();
        }
        console.log(err)
      });
  }

  

  const onGridReady = useCallback(async (params) => {
    loadData()
  })

  

  const ActionButtons = (params) => {
    const options = [
      { value: 1, label: 'View' },
      { value: 2, label: 'Edit' },
      { value: 3, label: 'Delete' },
    ]
    return (
      <div className='d-flex justify-center'>
         <div>
          <Link to={`${process.env.PUBLIC_URL}/${defaultUserType[sessionStorage.getItem('userType')]}/view-project/${params.data.id}`} >
            <Eye className={`action-icon`} />
          </Link>
        </div>
        <div className='ml-2'>
          <Link to={`${process.env.PUBLIC_URL}/${defaultUserType[sessionStorage.getItem('userType')]}/edit-project/${params.data.id}`}
            className={`${preValidatedStatus.includes(params.data.status) === true ? 'disable-icon' : ''}`} >
            <Edit className={` ${preValidatedStatus.includes(params.data.status) === true ? 'disable-icon mt-10' : 'edit-icon'}`} />
          </Link>
        </div>
        <div className='ml-2' onClick={() => handleModal(params.data.id, params.data.status)} >
          <Trash2 className={` ${params.data.status === 'DRAFT' ? 'trash-icon' : 'disable-icon mt-10  '}`} />
        </div>
      </div>
    )
  }

  const columnData = [
    {
      headerName: 'Name',
      field: 'name',
      filter: true,
      flex: 2.5,
      sortable: true,
      suppressMovable: true,
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 0,
        buttons: ['reset']
      }
    },
    {
      headerName: 'Status',
      flex: 1,
      tooltipValueGetter: (params) => defaultStatus[params.data.status],
      cellRenderer: params => {
        return <div >
          <img src={statusIconList[params.data.status]} height={50} width={50} className='padd-8px'/>
        </div>
      },
      suppressMovable: true,
    },
    {
      headerName: 'UN SDGs',
      flex: 3,
      cellRenderer: (params) => {
        let goalList = params.data.sustainableDevelopmentGoals?.split(',');
        return params.data.sustainableDevelopmentGoals ? <div>{goalList.map((i, index) => (
          <a data-tooltip-id="my-tooltip-no-padding" data-tooltip-content={goalDescriptions[i]}>
            <img src={sdgImgList[i]} height={50} width={50} className='pad-5px'/>
          </a>
        ))}
          <Tooltip id="my-tooltip-no-padding" />
        </div> : '--'
      },
      suppressMovable: true,
    },
    {
      headerName: 'Project Subcategory Type',
      field: 'subCategoryItemName',
      filter: true,
      flex: 3,
      sortable: true,
      suppressMovable: true,
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 0,
        buttons: ['reset']
      }
    },
    {
      headerName: 'Country',
      field: 'country',
      tooltipField: 'country',
      cellRenderer: (params) => {
        return (
          <div className='ml-1'>
            <ReactCountryFlag countryCode={params.data.countryCode} style={{
              width: '2em',
              height: '2em',
            }} svg />
          </div>
        )
      },
      width: 80,
      sortable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Actions',
      width: 140,
      cellRendererSelector: params => {
        return {
          component: ActionButtons
        }
      }
    }
  ]

  useEffect(() => {
    loadData();
  }, [open])

  return (
    <Fragment>
      {/* <LayoutLoader /> */}
      <Row>
        <Col sm="12">
          <div className="d-flex">
            <Breadcrumbs parent="Carbon Credits" subParent="Projects" title="Project List" mainTitle="Project List" />
            <div className="text-right action_button mr-13">
              <Link className="link" to={`${process.env.PUBLIC_URL}/aso-admin/project-initiation/Internal`} style={{ fontSize: '18px' }}>
                <i style={{ fontSize: '25px', color: "#2b7704" }} className="icofont  icofont-plus-circle" ></i>
                &nbsp;&nbsp;{createProject}</Link>
            </div>
          </div>
          {open && <DeleteProject value={open} setOpenModal={setOpen} dataId={dataId} />}
          <Container fluid={true}>
            <Card className=" newtable project_data">
              <CardBody className='data-tables responsive-view'>
                <div className='project-data'>
                  <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                    <AgGridTable
                      columnData={columnData}
                      tableData={tableData}
                      agRef={projectRef}
                      onGridReady={onGridReady}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Container>
        </Col>
      </Row>
    </Fragment>
  );

};

export default DataTables;