import React, { Fragment, useState, useEffect } from "react";
import { CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Button
} from "reactstrap";
import { H3 } from "../../../../AbstractElements";
import { useForm } from "react-hook-form";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";
import { toast } from "react-toastify";
import ButtonLoader from "../../ButtonLoader";
import { X } from "react-feather";

const AddSupplier = ({ value, setOpenModal, supplierData }) => {
    const [open, setOpen] = useState(value);
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({ defaultValues: supplierData });
    const [loading, setLoading] = useState(false)

    const onClose = () => {
        setOpen(false);
        setOpenModal(false)
    }

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='lg'>
                <ModalHeader style={{ display: 'flex' }}>
                    <H3 attrH3={{ className: 'modal-title-color' }}>New Supplier
                        <X className="x-button" onClick={onClose} />
                    </H3>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg='6'>
                            <FormGroup >
                                <Label>Supplier Name <span className='required'>*</span> </Label>
                                <input
                                    className="form-control"
                                    name="name"
                                    placeholder=""
                                    type="text"
                                    defaultValue={supplierData?.name || ''}
                                    {...register('name', { required: true })}
                                />
                                <span className="text-danger">{errors.name && 'Supplier Name is Required'}</span>
                            </FormGroup>
                        </Col>
                        <Col lg='6'>
                            <FormGroup >
                                <Label>Contact Person <span className='required'>*</span> </Label>
                                <input
                                    className="form-control"
                                    name="contactPerson"
                                    placeholder=""
                                    type="text"
                                    defaultValue={supplierData?.contactPerson || ''}
                                    {...register('contactPerson', { required: true })}
                                />
                                <span className="text-danger">{errors.contactPerson && 'Contact Person is Required'}</span>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='6'>
                            <FormGroup style={{ marginBottom: "8px " }}>
                                <Label for="exampleSelect">Email <span className='required'>*</span></Label>
                                <input
                                    className="form-control"
                                    name="email"
                                    placeholder=""
                                    type="text"
                                    defaultValue={supplierData?.email || ''}
                                    {...register('email', { required: true, pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })}
                                />
                                <span className="text-danger">{errors.email?.type === 'required' && 'email is Required'}</span>
                                <span className="text-danger">{errors.email?.type === 'pattern' && 'Invalid Email'}</span>
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup >
                                <Label for="exampleEmail">Phone</Label>
                                <input
                                    className="form-control"
                                    name="phone"
                                    placeholder=""
                                    type="text"
                                    defaultValue={supplierData?.phone || ''}
                                    {...register('phone', { pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/ })}
                                />
                                <span className="text-danger">{errors.phone?.type === 'required' && 'Phone is Required'}</span>
                                <span className="text-danger">{errors.phone?.type === 'pattern' && 'Invalid Phone Number'}</span>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <div className="d-flex " style={{ marginTop: "25px" }}>
                            {
                                loading ? <ButtonLoader loaderText={`Sumitting`} color="primary" /> : <Button color="primary" >{'Submit'}</Button>
                            }

                            <Button color="secondary" className="ml-1" onClick={() => onClose()}> Cancel</Button>
                        </div>
                    </Row>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default AddSupplier;
