import React, { Fragment, useEffect, useState } from "react";
import CarbonCredits from '../../../assets/images/logo/logo-icon.png';
import NetTrace from '../../../assets/images/logo/net.png';
import AQI from '../../../assets/images/logo/aqiLogo.png';
import decarbon from '../../../assets/images/logo/decarbon.png';
import { useDispatch, useSelector } from "react-redux";
import { Card } from "reactstrap";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import Header from "../../Layout/Header";
import { CURRENT_APP } from "../../Constant";
import { useNavigate } from "react-router";
import LayoutLoader from "../LayoutLoader/LayoutLoader";
import nettraceSvg from '../../../assets/images/logo/net0trace-home.svg';

const AppList = () => {
    const userProfile = useSelector(state => state?.user?.userDetails);
    const [subscriptionList, setSubscriptionList] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const licences = Object.keys(useSelector(state => state?.user?.userRoles));

    const getSubscriptionList = async () => {
        await fetchWrapper(`${API_URL}/subscriptions/customer`)
            .then(res => {
                console.log(res?.json)
                setSubscriptionList(res?.json?.filter(ele => ele?.issubscribed === true))
            }, (err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        console.log(licences)
        getSubscriptionList()
    }, [])

    const selectApp = (id) => {
        dispatch({
            type: CURRENT_APP,
            payload: id
        })
        navigate(`${process.env.PUBLIC_URL}/settings/application-settings`)
    }

    return (
        <Fragment>
            <LayoutLoader />
            <div className={`page-wrapper compact-wrapper`} id="pageWrapper">
                <div className={`page-header`}>
                    <Header />
                </div>
                <div className="d-flex justify-center" >
                    <div className="app-list-position">
                        <h2>G'Day {`${userProfile?.firstName} ${userProfile?.lastName}`}</h2>
                        <div className="mtop-2">
                            <h3>Apps</h3>
                            <div className="d-flex">
                                {
                                    <Card className={`${(subscriptionList?.some(ele => ele?.moduleId == 1) && licences?.includes('1')) === false ? 'disable-cursor' : ''} app-tile mr-1`}
                                        onClick={() => selectApp(1)}>
                                        <div className={`${(subscriptionList?.some(ele => ele?.moduleId == 1) && licences?.includes('1')) === false ? 'bg-gray disable-cursor' : ''} 
                                        app-tile color-dark-primary`}>
                                            <div className={`${(subscriptionList?.some(ele => ele?.moduleId == 1) && licences?.includes('1')) === false ? 'bg-gray' : ''} app-img-div`}>
                                                <img className="app-logo-img" src={CarbonCredits} height={65} width={65} />
                                            </div>
                                            <div className="w-13 d-flex justify-center">
                                                <h5 className='app-tile-font'>Carbon Credits</h5>
                                            </div>
                                        </div>
                                    </Card>
                                }
                                {
                                    <Card className={`${(subscriptionList?.some(ele => ele?.moduleId == 2) && licences?.includes('2')) === false ? 'disable-cursor' : ''} app-tile mr-1`}
                                        onClick={() => selectApp(2)}>
                                        <div className={`${(subscriptionList?.some(ele => ele?.moduleId == 2) && licences?.includes('2')) === false ? 'bg-gray disable-cursor' : ''} 
                                        app-tile color-dark-primary`}>
                                            <div className={`${(subscriptionList?.some(ele => ele?.moduleId == 2) && licences?.includes('2')) === false ? 'bg-gray' : ''} app-img-div`}>
                                                <img className="app-logo-img" src={nettraceSvg} height={75} width={75} />
                                            </div>
                                            <div className="w-13 d-flex justify-center">
                                                <h5 className='app-tile-font'>Net0Trace</h5>
                                            </div>
                                        </div>
                                    </Card>
                                }
                                {
                                    <Card className={`${(subscriptionList?.some(ele => ele?.moduleId == 5) && licences?.includes('5')) === false ? 'disable-cursor' : ''} app-tile mr-1`}
                                        onClick={() => selectApp(5)}>
                                        <div className={`${(subscriptionList?.some(ele => ele?.moduleId == 5) && licences?.includes('5')) === false ? 'bg-gray disable-cursor' : ''} 
                                        app-tile color-dark-primary`}>
                                            <div className={`${(subscriptionList?.some(ele => ele?.moduleId == 5) && licences?.includes('5')) === false ? 'bg-gray' : ''} app-img-div`}>
                                                <img className="app-logo-img" src={AQI} width={65} height={65} />
                                            </div>
                                            <div className="w-13 d-flex justify-center">
                                                <h5 className='app-tile-font'>AQI Insights</h5>
                                            </div>
                                        </div>
                                    </Card>
                                }
                                {
                                    <Card className={`${(subscriptionList?.some(ele => ele?.moduleId == 3) && licences?.includes('3')) === false ? 'disable-cursor' : ''} app-tile mr-1`}
                                        onClick={() => selectApp(3)}>
                                        <div className={`${(subscriptionList?.some(ele => ele?.moduleId == 3) && licences?.includes('3')) === false ? 'bg-gray disable-cursor' : ''} 
                                        app-tile color-dark-primary`}>
                                            <div className={`${(subscriptionList?.some(ele => ele?.moduleId == 3) && licences?.includes('3')) === false ? 'bg-gray' : ''} app-img-div`}>
                                                <img className="app-logo-img" src={decarbon} width={65} height={65} />
                                            </div>
                                            <div className="w-13 d-flex justify-center">
                                                <h5 className='app-tile-font'>Decarbonization Insights</h5>
                                            </div>
                                        </div>
                                    </Card>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </Fragment>
    )
}

export default AppList;