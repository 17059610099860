import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Row, Col, Card, CardBody, Badge, Label, Button, Container } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { useNavigate, useParams } from 'react-router-dom';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import AgGridTable from '../AgGridTable';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { capitalize, defaultUserType, thousandSeparator } from '../../api/helper';
import { Edit, Trash2 } from 'react-feather';
import UpdateOwnershipData from './UpdateOwnershipData';
import { toast } from 'react-toastify';
import { Plus } from "react-feather";
import ButtonLoader from '../ButtonLoader';

const OwnershipHistory = () => {
    const orgId = useParams().id;
    const orgName = useParams()?.name;
    const [data, setData] = useState([]);
    const historyRef = useRef(null);
    const navigate = useNavigate();
    const [activeKey, setActiveKey] = useState(1);
    const [updateModalOpen, setModalOpen] = useState(false);
    const [updatableData, setUpdatableData] = useState();
    const [rowIndex, setRowIndex] = useState([]);
    const [loading, setLoading] = useState(false);

    const userType = useSelector(state => state.user.userType);

    const handleDelete = async (data, dataIndex) => {
        if (data?.status !== 'APPROVED') {
            if (data.id) {
                historyRef.current.api.showLoadingOverlay();
                const options = {
                    method: 'DELETE',
                }
                await fetchWrapper(`${API_URL}/ownership/${data.id}`, options)
                    .then((res) => {
                        console.log(res.json)
                    }, (err) => {
                        console.log(err)
                        if (err.status === 204) {
                            toast.success('Record deleted successfully!')
                            historyRef.current.api.hideOverlay();
                            onGridReady()
                        } else if (err.status === 500) {
                            historyRef.current.api.hideOverlay();
                            onGridReady()
                        }
                    })
            }
        }

    }

    const handleOverlap = (date, type, index) => {
        let result = data?.filter((ele) => ele?.index !== index)?.some((item) => {
            const effectiveDate = new Date(item?.effectiveDate);
            const endDate = new Date(item?.endDate);
            if ((date >= effectiveDate && date <= endDate) && (type === item?.controlType)) {
                return true;
            } else {
                return false;
            }
        })
        return result;
    }

    const handleEdit = (params) => {
        if (!params.endDate) {
            setUpdatableData(params);
            setModalOpen(true)
        }
    }

    const ActionButtons = (params) => {
        const options = [
            { value: 1, label: 'View' },
            { value: 2, label: 'Edit' },
            { value: 3, label: 'Delete' },
        ]
        return (
            <div className='d-flex justify-center'>
                <div onClick={() => handleEdit(params.data)} >
                    <Edit className={params.data.endDate ? 'disable-icon mt-10' : `edit-icon`} />
                </div>
                <div onClick={() => handleDelete(params.data, params.data.index)} >
                    <Trash2 className={params.data.status === 'APPROVED' ? 'disable-icon mt-10 ml-1' : `trash-icon ml-1`} />
                </div>
            </div>
        )
    }

    const columnData = [
        {
            headerName: 'Control Type',
            field: 'controlType',
            filter: false,
            valueGetter: (params) => {
                return capitalize(params.data.controlType)
            },
            flex: 0.8,
            sortable: false,
            suppressMovable: true,
            singleClickEdit: true,
            cellEditorPopup: false,
            // cellClass: 'text-input-field w-11-hf'
        },
        {
            headerName: 'Control Percentage (%)',
            field: 'controlTypePercentage',
            filter: false,
            cellEditorParams: {
                min: 0,
                max: 100,
                precision: 2
            },
            cellRenderer: (params) => {
                return <div className="text-right w-150px">{params?.data?.controlTypePercentage ?
                    thousandSeparator(params?.data?.controlTypePercentage.toFixed(2)) : ''}</div>;
            },
            flex: 1,
            sortable: true,
            suppressMovable: true,
            singleClickEdit: true,
            cellEditorPopup: false,
            // cellClass: 'text-input-field ml-10 w-11-hf'
        },
        {
            headerName: 'Effective Date',
            field: 'effectiveDate',
            flex: 1,
            editable: false,
            cellEditor: 'agDateCellEditor',
            // cellEditorParams: {
            //     min: moment(new Date()).format('YYYY-MM-DD')
            // },
            valueGetter: (params) => {
                if (params.data.effectiveDate) {
                    if (!handleOverlap(params.data.effectiveDate, params.data.controlType, params.data.index)) {
                        return moment(params.data?.effectiveDate).format('DD MMM, YYYY');
                    } else {
                        delete params.data.effectiveDate;
                        return
                    }

                }
            },
            // cellClass: 'text-input-field ml-10 w-11-hf'
        },
        {
            headerName: 'End Date',
            field: 'endDate',
            flex: 1,
            cellEditor: 'agDateCellEditor',
            editable: false,
            cellEditorParams: (params) => {
                return {
                    min: params.data.effectiveDate ? moment(params.data.effectiveDate).format('YYYY-MM-DD') : ''
                }
            },
            valueGetter: (params) => {
                if (params.data.endData && params.data.effectiveDate) {
                    return params.data.endDate;
                }
            },
            cellRenderer: (params) => {
                return (
                    (params.data.endDate) ? <div>{moment(params.data?.endDate).format('DD MMM, YYYY')}</div> : <div className='active-bg d-flex'>Active</div>
                )
            },
        },
        {
            headerName: 'Status',
            field: 'status',
            flex: 1,
            sortable: false,
            suppressMovable: true,
            cellRenderer: (params) => {
                if (params.data.status === 'APPROVED') {
                    return (
                        <div className='approve'>Approved</div>
                    )
                } else if (params.data.status === 'REJECTED') {
                    return (
                        <div className='reject'>Rejected</div>
                    )
                } else if (params.data.status === 'SUBMITTED_FOR_APPROVAL') {
                    return (
                        <div className='pending'>Pending</div>
                    )
                }
            },
        },
        {
            headerName: 'Actions',
            width: 140,
            cellRendererSelector: params => {
                return {
                    component: ActionButtons
                }
            },
            suppressMovable: true,
        }
    ]

    const getAllRecords = async () => {
        historyRef.current.api.showLoadingOverlay();
        await fetchWrapper(`${API_URL}/ownership/getChildOrgDetails/${orgId}`)
            .then((res) => {
                if (res?.json?.length > 0) {
                    setData(res.json?.map((item, index) => ({ ...item, index: index + 1 })))
                }
                historyRef.current.api.hideOverlay();
            }, (err) => {
                console.log(err)
                historyRef.current.api.hideOverlay();
            })
    }

    const onGridReady = useCallback(async (params) => {
        getAllRecords()
    })

    const handleBack = () => {
        navigate(`${process.env.PUBLIC_URL}/ownership-management`)
    }
    return (
        <Fragment>
            <Container fluid={true}>
                <Breadcrumbs mainTitle={`${orgName} - Ownership Control`} />
                <Row>
                    <Col lg={12}>
                        <Card className=''>
                            <CardBody className='ownershipHistory ev-station-consumption'>

                                <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                    <AgGridTable
                                        columnData={columnData}
                                        tableData={data}
                                        agRef={historyRef}
                                        onGridReady={onGridReady}
                                        pagination={false}
                                    />
                                    <div className='custom-footer-area'>
                                        <button className="mtop-1 add-row-button" onClick={() => setModalOpen(true)}>
                                            <Plus className="mr-10" color="#9da6ab" fontSize={13} height={20} width={20} strokeWidth={3} />
                                            Add</button>
                                    </div>

                                </div>
                                <div className='mtop-1'>
                                    <Button color='secondary' onClick={handleBack}>Back</Button>
                                    {/* {
                                        loading ? <ButtonLoader className={'ml-1'} loaderText={'Submitting'} color='primary' /> : <Button color='primary' className='ml-1' onClick={onSubmit}>Submit</Button>
                                    } */}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {
                updateModalOpen && <UpdateOwnershipData value={updateModalOpen} setOpenModal={setModalOpen}
                    data={updatableData} gridData={data} orgId={orgId} setUpdate={(e) => { e === true && getAllRecords() }} />
            }
        </Fragment >
    )
}

export default OwnershipHistory;
