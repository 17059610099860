
import React, { Fragment, useState } from 'react';
import { useContext } from 'react';
import { Maximize } from 'react-feather';
import { UL, LI, } from '../../../../AbstractElements';
import CustomizerContext from '../../../../Common/_helper/Customizer';
import LogoutClass from './Logout';
import MoonLight from './MoonLight';
import Notifications from './Notifiations';
import { Col } from 'reactstrap';
import { FaUserCircle } from 'react-icons/fa';
import defaultImg from '../../../../assets/images/document/DefaultImage.png';
import { toast } from 'react-toastify';
import maximize from '../../../../assets/images/layout/maximize.svg';
import faq from '../../../../assets/images/layout/faq.svg';
import Faq from '../../FaqPopup/Faq';

const Rightbar = () => {
    const { sidebarResponsive } = useContext(CustomizerContext);
    const [loading, setLoading] = useState(false);
    const [faqOpen, setFaqOpen] = useState(false)
    const allowedTypes = ['ASO_ADMIN']

    //full screen function
    function goFull() {
        if ((document.fullScreenElement && document.fullScreenElement !== null) ||
            (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    const uploadLogo = async (e) => {
        if (allowedTypes.includes(sessionStorage.getItem('userType'))) {
            let file = e.target.files[0];
            let result;
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                var img = new Image();
                img.src = e.target.result;
                img.onload = () => {
                    var height = img.naturalHeight;
                    var width = img.naturalWidth;
                    if (height > 48 && width > 48) {
                        console.log(false)
                        result = false;
                        toast.error('Image should be in 48X48 or 96X48 pixel')
                        return
                    } else {
                        console.log(true)
                        result = true;
                    }
                }
            }
            if (file?.size > 524288) {
                toast.error('The File Size should be lessthan 512 KB')
                return
            } else {
                setLoading(true)
                const authToken = sessionStorage.getItem('accessToken')
                let data = new FormData();
                data.append('logo', file);
                data.append('orgId', orgId)
                const options = {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${authToken}`
                    },
                    body: data
                }
                await fetchWrapper(`${API_URL}/organization/uploadLogo`, options)
                    .then((res) => {
                        console.log(res)
                        setLoading(false)
                        toast.success('Logo Uploaded Successfully!')
                    }, (err) => {
                        console.log(err)
                        setLoading(false)
                        toast.error('Something went wrong')
                    })
            }
            console.log(result)
        }

    }

    return (
        <Fragment>
           
            <Col className="nav-right col-4 pull-right right-header p-0">
                <UL attrUL={{ className: `simple-list d-flex flex-row nav-menus ${sidebarResponsive ? 'open' : ''}` }} >
                    <LI attrLI={{ className: 'maximize' }}>
                        <a className="text-dark" href="#javascript" onClick={() => setFaqOpen(!faqOpen)}>
                            <img src={faq} className='faq-icon-w' />
                        </a>
                    </LI>
                    <LI attrLI={{ className: 'maximize' }}>
                        <a className="text-dark" href="#javascript" onClick={goFull}>
                            <img src={maximize} className='minimize-icon' />
                        </a>
                    </LI>
                    <Notifications />
                    <LogoutClass />
                </UL>
            </Col>
            {
                (['ASO_ADMIN', 'ASO_USER']?.includes(sessionStorage.getItem('userType'))
                    && window.location.pathname !== '/faq-and-help') && <Faq setFaqOpen={setFaqOpen} faqOpen={faqOpen} />
            }
        </Fragment >
    );
};

export default Rightbar;