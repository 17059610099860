import React, { Fragment, useState, useEffect, useRef } from "react";
import { Card, CardBody, Col, Container, Row, Table, Button, Input } from 'reactstrap';
import { Breadcrumbs, H3 } from "../../../../AbstractElements";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";
import { currencyFormatter, formatter, thousandSeparator } from "../../../api/helper";
import { useSelector } from "react-redux";
import AgGridTable from "../../AgGridTable";
import { useCallback } from "react";

const WalletDetailsPage = () => {
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false);
    const [asoList, setAsoList] = useState([]);
    const [selectedOrgId, setOrgId] = useState();
    const userType = useSelector(state => state?.user?.userType);
    const detailsRef = useRef();
    const orgCurrency = useSelector(state => state?.user?.userDetails?.currency)

    const loadDetailsData = async (val) => {
        detailsRef?.current?.api?.showLoadingOverlay();
        const url = ['ASM_ADMIN', 'ASM_USER'].includes(userType) ? `/projectcredits/newwalletDetails/assetOwner/${val}` : `/projectcredits/newwalletDetails/assetOwner`;
        await fetchWrapper(`${API_URL}${url}`)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    detailsRef?.current?.api?.hideOverlay();
                    setData(res.json)
                }
            }, (err) => {
                detailsRef?.current?.api?.hideOverlay();
                console.log(err)
                if (err.status === 404) {
                    detailsRef?.current?.api?.showLoadingOverlay();
                }
            })
    }

    const loadAsoOrgList = async () => {
        await fetchWrapper(`${API_URL}/organization/assetmanager/mappedOrgs`)
            .then((res) => {
                const data = res.json;
                setAsoList(data?.mappedAssetOwners)
            }, (err) => { console.log(err) })
    }

    const loadData = (val) => {
        setOrgId(val);
        loadDetailsData(val);
    }

    const tableColumns = [
        {
            headerName: 'Project Name',
            field: 'projectName',
            filter: true,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            cellClass: 'cell-wrap'
        },
        {
            headerName: 'Unit Type',
            field: 'certifiedUnitType',
            filter: true,
            flex: 1.5,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Pending Credits (tCO2e)',
            field: 'uncertifiedCurrencyBalance',
            filter: false,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            valueGetter: (params) => {
                return thousandSeparator(Number(params.data.uncertifiedCurrencyBalance).toFixed(2))
            },
            cellClass: 'text-right pr-30'
        },
        {
            headerName: 'Certified Credits (tCO2e)',
            field: 'certifiedCurrencyBalance',
            filter: false,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            valueGetter: (params) => {
                return thousandSeparator(Number(params.data.certifiedCurrencyBalance).toFixed(2))
            },
            cellClass: 'text-right pr-30'
        },
        {
            headerName: 'Pending Sale (tCO2e)',
            field: 'pendingCurrencyBalance',
            filter: false,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            valueGetter: (params) => {
                return thousandSeparator(Number(params.data.pendingCurrencyBalance).toFixed(2))
            },
            cellClass: 'text-right pr-30'
        },
        {
            headerName: 'Total (tCO2e)',
            filter: false,
            flex: 1.5,
            sortable: true,
            suppressMovable: true,
            valueGetter: (params) => {
                return thousandSeparator(Number(params.data.total).toFixed(2))
            },
            cellClass: 'text-right pr-30'
        },
        {
            headerName: 'Unit Rate (USD)',
            field: 'midRate',
            filter: false,
            flex: 1.5,
            sortable: true,
            suppressMovable: true,
            valueGetter: (params) => {
                return currencyFormatter(params.data.midRate, 'USD')
            },
            cellClass: 'text-right pr-30'
        },
        {
            headerName: `Monetary Value (${orgCurrency})`,
            field: 'midRate',
            filter: false,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            valueGetter: (params) => {
                return currencyFormatter(params.data.monetaryValue, params.data.orgCurrency)
            },
            cellClass: 'text-right pr-30'
        },
    ]

    useEffect(() => {
        if (['ASM_ADMIN', 'ASM_USER'].includes(userType)) {
            loadAsoOrgList()
        } else {
            loadDetailsData()
        }

    }, [])

    const onGridReady = useCallback(async (params) => {
        loadDetailsData();
    })

    return (
        <Fragment>
            <Row>
                <Col lg={12}>
            <div className="d-flex ">
                <H3 attrH3={{className: 'credits-table'}}>Details</H3>
                {
                    (['ASM_ADMIN', 'ASM_USER'].includes(userType)) && <div className="d-flex aso-dropdown">
                        <select className="form-select"
                            onChange={(e) => loadData(e.target.value)}
                        >
                            <option value=''>Select Asset Owner Organization</option>
                            {
                                asoList?.length > 0 && asoList?.map((item) => {
                                    return (
                                        <option value={item?.assetOwnerId}>{item?.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                }
            </div>
            
                    <Card className="details_card mtop-1">
                        <CardBody className="data-tables wallet-det ptop-0">
                            <div className='wallet-details '>
                                <div className="details-table">
                                    <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                        <AgGridTable
                                            columnData={tableColumns}
                                            tableData={data}
                                            agRef={detailsRef}
                                            onGridReady={onGridReady}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default WalletDetailsPage;