import React, { Fragment, useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import { useParams } from 'react-router';
import Summary from './Summary';
import StationaryCombustion from './StationaryCombustion';
import MobileSources from './MobileSources';
import PurchasedGases from './PurchasedGases';
import RefrigerationData from './RefrigerationData';
import FireSuppression from './FireSuppression';
import Electricity from './Electricity';
import Steam from './Steam';
import BusinessTravel from './BusinessTravel';
import EmployeeCommuting from './EmployeeCommuting';
import UpstreamTransport from './UpstreamTransport';
import IndirectWaste from './IndirectWaste';
import EvidenceList from './EvidenceList';
import WaterConsumption from './WaterConsumption';
import PurchasedGoods from './PurchasedGoods';
import CapitalGoods from './CapitalGoods';

const GhgDataCapture = () => {
    const reqId = useParams()?.reqId;
    const reviewStatus = useParams()?.status;
    const [steps, setSteps] = useState(0);
    const [preData, setPreData] = useState({})
    const [emissionSetId, setEmissionId] = useState();
    const [type, setType] = useState();
    const [ghgStatus, setGhgStatus] = useState('NO_DATA');
    const [stationaryCombustionData, setStationaryCombustion] = useState([]);
    const [mobileSourcesData, setMobileSourcesData] = useState([]);
    const [rfgMaterialData, setRfgMaterial] = useState([]);
    const [rfgSimplifiedMaterialData, setRfgSimplified] = useState([]);
    const [rfgScreeningData, setRfgScreening] = useState([]);
    const [fsMaterialData, setFsMaterial] = useState([]);
    const [fsSimplifiedMaterialData, setFsSimplified] = useState([]);
    const [fsScreeningData, setFsScreening] = useState([]);
    const [purchasedGasesData, setPurchasedGases] = useState([]);
    const [electricity, setElectricity] = useState([]);
    const [steam, setSteam] = useState([]);
    const [businessTravelPersonalData, setTravelPersonalData] = useState([]);
    const [businessTravelPublicData, setTravelPublicData] = useState([]);
    const [businessTravelAirwaysData, setTravelAirwaysData] = useState([]);
    const [travelCommutePersonalData, setTravelCommutePersonalData] = useState([]);
    const [travelCommutePublicData, setTravelCommutePublicData] = useState([]);
    const [transportData, setTransportData] = useState([]);
    const [distributeData, setDistributeData] = useState([]);
    const [indirectWasteData, setIndirectWaste] = useState([]);
    const [directWasteData, setDirectWasteData] = useState([]);
    const [waterConsumptionData, setWaterConsumptionData] = useState([]);
    const [waterDischargeData, setWaterDischargeData] = useState([]);
    const [purchasedGoodsData, setPurchasedGoodsData] = useState([]);
    const [capitalGoodsData, setCapitalGoodsData] = useState([]);
    const [timeStamp, setTimestamp] = useState();
    const [locationDetails, setLocationDetails] = useState();
    const [requestId, setRequestId] = useState(reqId);
    const [locationEnergyType, setLocationEnergyType] = useState(true);
    const [marketEnergyType, setMarketEnergyType] = useState(false);
    const [mode, setMode] = useState('Edit');

    useEffect(() => {
        console.log(preData)
    }, [steps, preData, emissionSetId])

    useEffect(() => {

    }, [steps])
    return (
        <Fragment>
            <LayoutLoader />
            <Container fluid={true}>
                <Breadcrumbs mainTitle={'Data Capture'} />
                <Row>
                    <Col lg='12'>
                        {steps == 0 && <Summary setSteps={setSteps} setPreData={setPreData} preData={preData}
                            setEmissionId={setEmissionId} setType={setType} setGhgStatus={setGhgStatus}
                            stationaryCombustionData={stationaryCombustionData} setStationaryCombustion={setStationaryCombustion}
                            mobilesourcesData={mobileSourcesData} setMobileSourcesData={setMobileSourcesData}
                            rfgMaterialData={rfgMaterialData} setRfgMaterial={setRfgMaterial}
                            rfgSimplifiedMaterialData={rfgSimplifiedMaterialData} setRfgSimplified={setRfgSimplified}
                            rfgScreeningData={rfgScreeningData} setRfgScreening={setRfgScreening}
                            fsMaterialData={fsMaterialData} setFsMaterial={setFsMaterial}
                            fsSimplifiedMaterialData={fsSimplifiedMaterialData} setFsSimplified={setFsSimplified}
                            fsScreeningData={fsScreeningData} setFsScreening={setFsScreening}
                            purchasedGasesData={purchasedGasesData} setPurchasedGases={setPurchasedGases}
                            energyData={electricity} setElectricity={setElectricity}
                            steamEnergyData={steam} setSteam={setSteam}
                            businessTravelPersonalData={businessTravelPersonalData} setTravelPersonalData={setTravelPersonalData}
                            businessTravelPublicData={businessTravelPublicData} setTravelPublicData={setTravelPublicData}
                            businessTravelAirwaysData={businessTravelAirwaysData} setTravelAirwaysData={setTravelAirwaysData}
                            travelCommutePersonalData={travelCommutePersonalData} setTravelCommutePersonalData={setTravelCommutePersonalData}
                            travelCommutePublicData={travelCommutePublicData} setTravelCommutePublicData={setTravelCommutePublicData}
                            upstreamVehicleMilesData={transportData} setTransportData={setTransportData}
                            upstreamVehicleTonsData={distributeData} setDistributeData={setDistributeData}
                            indirectWasteData={indirectWasteData} setIndirectWaste={setIndirectWaste}
                            directWasteData={directWasteData} setDirectWasteData={setDirectWasteData}
                            waterConsumptionData={waterConsumptionData} setWaterConsumptionData={setWaterConsumptionData}
                            waterDischargeData={waterDischargeData} setWaterDischargeData={setWaterDischargeData}
                            purchasedGoodsData={purchasedGoodsData} setPurchasedGoodsData={setPurchasedGoodsData}
                            capitalGoodsData={capitalGoodsData} setCapitalGoodsData={setCapitalGoodsData}
                            timeStamp={timeStamp} setTimestamp={setTimestamp}
                            setLocationDetails={setLocationDetails}
                            requestId={requestId} setRequestId={setRequestId}
                            reqId={reqId} reviewStatus={reviewStatus}
                            locationEnergyType={locationEnergyType} setLocationEnergyType={setLocationEnergyType}
                            marketEnergyType={marketEnergyType} setMarketEnergyType={setMarketEnergyType}
                            mode={mode} setMode={setMode} ghgStatus={ghgStatus}
                        />}
                        {steps == 1 && <StationaryCombustion setSteps={setSteps} preData={preData} stationaryData={stationaryCombustionData}
                            emissionSetId={emissionSetId} setStationaryCombustion={setStationaryCombustion} timeStamp={timeStamp} setPreData={setPreData}
                            requestId={requestId} setRequestId={setRequestId} mode={mode} />}
                        {steps == 2 && <MobileSources setSteps={setSteps} preData={preData} emissionSetId={emissionSetId}
                            mobileData={mobileSourcesData} setMobileSourcesData={setMobileSourcesData} timeStamp={timeStamp}
                            requestId={requestId} setRequestId={setRequestId} mode={mode} />}
                        {steps == 3 && <RefrigerationData setSteps={setSteps} preData={preData} emissionSetId={emissionSetId}
                            ghgStatus={ghgStatus} setRfgMaterial={setRfgMaterial} setRfgSimplified={setRfgSimplified}
                            setRfgScreening={setRfgScreening} rfgMaterial={rfgMaterialData} rfgSimplified={rfgSimplifiedMaterialData}
                            rfgScreening={rfgScreeningData} timeStamp={timeStamp} requestId={requestId} setRequestId={setRequestId} mode={mode} />}
                        {steps == 4 && <FireSuppression setSteps={setSteps} preData={preData} emissionSetId={emissionSetId}
                            ghgStatus={ghgStatus} setFsMaterial={setFsMaterial} setFsSimplified={setFsSimplified} setFsScreening={setFsScreening}
                            fsMaterial={fsMaterialData} fsSimplified={fsSimplifiedMaterialData} fsScreening={fsScreeningData} timeStamp={timeStamp}
                            requestId={requestId} setRequestId={setRequestId} mode={mode} />}
                        {steps == 5 && <PurchasedGases setSteps={setSteps} preData={preData} emissionSetId={emissionSetId}
                            setPurchasedGases={setPurchasedGases} purchasedGases={purchasedGasesData} timeStamp={timeStamp}
                            requestId={requestId} setRequestId={setRequestId} mode={mode} />}
                        {steps == 6 && <Electricity setSteps={setSteps} preData={preData} emissionSetId={emissionSetId}
                            setElectricity={setElectricity} electricity={electricity} timeStamp={timeStamp}
                            locationDetails={locationDetails} requestId={requestId} setRequestId={setRequestId} mode={mode} />}
                        {steps == 7 && <Steam setSteps={setSteps} preData={preData} emissionSetId={emissionSetId}
                            locationEnergyType={locationEnergyType} setLocationEnergyType={setLocationEnergyType}
                            marketEnergyType={marketEnergyType} setMarketEnergyType={setMarketEnergyType}
                            setSteam={setSteam} steam={steam} timeStamp={timeStamp} requestId={requestId} setRequestId={setRequestId} mode={mode} />}
                        {steps == 8 && <BusinessTravel setSteps={setSteps} preData={preData} emissionSetId={emissionSetId}
                            setTravelPersonalData={setTravelPersonalData} setTravelPublicData={setTravelPublicData}
                            setTravelAirwaysData={setTravelAirwaysData} personalTravel={businessTravelPersonalData} publicTravel={businessTravelPublicData}
                            airwaysTravel={businessTravelAirwaysData} timeStamp={timeStamp} requestId={requestId} setRequestId={setRequestId} mode={mode} />}
                        {steps == 9 && <EmployeeCommuting setSteps={setSteps} preData={preData} emissionSetId={emissionSetId}
                            setTravelCommutePersonalData={setTravelCommutePersonalData}
                            setTravelCommutePublicData={setTravelCommutePublicData} personalCommuting={travelCommutePersonalData}
                            publicCommuting={travelCommutePublicData} timeStamp={timeStamp} requestId={requestId} setRequestId={setRequestId} mode={mode} />}
                        {steps == 10 && <UpstreamTransport setSteps={setSteps} preData={preData} emissionSetId={emissionSetId}
                            setTransportData={setTransportData} setDistributeData={setDistributeData} transportationData={transportData}
                            distributionData={distributeData} timeStamp={timeStamp} requestId={requestId} setRequestId={setRequestId} mode={mode} />}
                        {steps == 11 && <IndirectWaste setSteps={setSteps} preData={preData} directWasteData={directWasteData} setDirectWasteData={setDirectWasteData}
                            emissionSetId={emissionSetId} setIndirectWaste={setIndirectWaste} indirectWasteData={indirectWasteData} timeStamp={timeStamp}
                            requestId={requestId} setRequestId={setRequestId} mode={mode} />}
                        {steps == 12 && <WaterConsumption setSteps={setSteps} preData={preData} setWaterDischargeData={setWaterDischargeData} waterDischargeData={waterDischargeData}
                            emissionSetId={emissionSetId} setWaterConsumptionData={setWaterConsumptionData} waterConsumptionData={waterConsumptionData} timeStamp={timeStamp}
                            requestId={requestId} setRequestId={setRequestId} mode={mode} />}
                        {steps == 13 && <PurchasedGoods setSteps={setSteps} preData={preData}
                            emissionSetId={emissionSetId} setPurchasedGoodsData={setPurchasedGoodsData} purchasedGoodsData={purchasedGoodsData} timeStamp={timeStamp}
                            requestId={requestId} setRequestId={setRequestId} mode={mode} />}
                        {steps == 14 && <CapitalGoods setSteps={setSteps} preData={preData}
                            emissionSetId={emissionSetId} capitalGoodsData={capitalGoodsData} setCapitalGoodsData={setCapitalGoodsData} timeStamp={timeStamp}
                            requestId={requestId} setRequestId={setRequestId} mode={mode} />}
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default GhgDataCapture;