import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Badge, Modal, ModalBody, ModalHeader, Label, Form, FormGroup, ModalFooter, Button } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { Link } from 'react-router-dom';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { Eye, Edit, Trash2, X } from 'react-feather';
import { toast } from 'react-toastify';
import { defaultUserType } from "../../api/helper";
import AgGridTable from '../AgGridTable';
import { useForm } from "react-hook-form";
import { H3 } from '../../../AbstractElements';

const CustomerManagement = () => {
    const [customerData, setCustomerData] = useState([]);
    const customerRef = useRef(null);
    const [customerModalOpen, setCustomerModalOpen] = useState();

    const columnData = [
        {
            headerName: 'Company Name',
            field: 'companyName',
            filter: true,
            flex: 2.5,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap'
        },
        {
            headerName: 'Contact Person',
            filter: true,
            flex: 2.5,
            sortable: true,
            suppressMovable: true,
            valueGetter: (params) => {
                return `${params.data.first_name} ${params.data.last_name}`
            },
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            autoHeight: true,
            wrapText: true
        },
        {
            headerName: 'Email',
            field: 'email',
            filter: true,
            flex: 2.5,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            autoHeight: true,
            wrapText: true
        },
        {
            headerName: 'Edit',
            width: 60,
            cellRenderer: params => {
                return (
                    <Edit className={`edit-icon`} />
                )
            },
            suppressMovable: true,
        }
    ]

    return (
        <Fragment>
            <Container fluid>
                <div className="d-flex mtop-1">
                    <Breadcrumbs mainTitle="Customer Management" />
                    <div className="text-right  action_button mr-13">
                        <div className="link" onClick={() => setCustomerModalOpen(true)}>
                            <i className="icon-style icofont  icofont-plus-circle" ></i>
                            &nbsp;&nbsp;{"Add"}
                        </div>
                    </div>
                </div>
                <Card>
                    <CardBody>
                        <div className="groups-table">
                            <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                <AgGridTable
                                    columnData={columnData}
                                    tableData={customerData}
                                    agRef={customerRef}
                                />
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Container>
            {customerModalOpen && <CustomerContact value={customerModalOpen} setOpenModal={setCustomerModalOpen} />}
        </Fragment>
    )
}

export default CustomerManagement;

const CustomerContact = ({ value, setOpenModal, data }) => {
    const [open, setOpen] = useState(value);
    const [orgList, setOrgList] = useState([]);
    const [org, setOrg] = useState();
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({ defaultValues: data });

    const onClose = () => {
        setOpen(false);
        setOpenModal(false);
    }

    const getOrgList = async () => {
        await fetchWrapper(`${API_URL}/organization`)
            .then((res) => {
                setOrgList(res?.json)
            }, (err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getOrgList()
    }, [])

    const onSubmit = async (data) => {
        console.log(data)
        const options = {
            method: 'POST',
            body: data
        }
        await fetchWrapper(`${API_URL}/users/superuser`, options)
        .then((res) => {
            console.log(res?.json)
            if(res?.status === 200) {
                toast.success('Customer created successfully!')
                onClose()
            }
        }, (err) => {
            console.log(err)
            if(err?.status === 500) {
                toast.error('Something went wrong!')
            }
        })
    }

    return (
        <Fragment >
            <div className="side-modal">
                <Modal isOpen={open} size='xl' contentClassName="side-modal-content">
                    <ModalHeader style={{ display: 'flex' }}>
                        <H3 attrH3={{ className: 'modal-title-color' }}>Add Customer
                            <X className="x-button" onClick={onClose} />
                        </H3>
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Company Name</Label>
                                        <select className='form-select' 
                                            {...register('org_id', {required: true})}
                                        >
                                            <option>-Please select Organization-</option>
                                            {
                                                orgList?.map((item) => (
                                                    <option value={item?.id}>{item?.companyName}</option>
                                                ))
                                            }
                                        </select>
                                        {errors?.root?.type === 'required' && <span className='text-danger'>Please select Organization</span>}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg='6'>
                                    <div>
                                        <FormGroup>
                                            <Label for="exampleSelect">First Name </Label>
                                            <input
                                                className="form-control"
                                                id="exampleEmail"
                                                name="first_name"
                                                placeholder=""
                                                type="text"
                                                defaultValue={data?.first_name || ''}
                                                {...register('first_name', {required: true})}
                                            />
                                            <span className="text-danger">{errors.first_name && 'First Name is Required'}</span>
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div>
                                        <FormGroup >
                                            <Label for="exampleEmail">  Last Name </Label>
                                            <input
                                                className="form-control"
                                                name="last_name"
                                                placeholder=""
                                                type="text"
                                                defaultValue={data?.last_name}
                                                {...register('last_name', {required: true})}
                                            />
                                            <span className="text-danger">{errors.last_name && 'Last Name is Required'}</span>
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="6">
                                    <div>
                                        <FormGroup >
                                            <Label for="exampleEmail"> Email ID (Login ID)</Label>
                                            <input
                                                className="form-control"
                                                id="exampleEmail"
                                                name="email"
                                                placeholder=""
                                                type="email"
                                                defaultValue={data?.email || ''}
                                                {...register('email', { required: true, pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })}
                                            />
                                            <span className="text-danger">{errors?.email?.type === 'required' && 'email is Required'}</span>
                                            <span className="text-danger">{errors?.email?.type === 'pattern' && 'Invalid Email'}</span>
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div>
                                        <FormGroup>
                                            <Label for="exampleSelect">Phone </Label>
                                            <input
                                                className="form-control"
                                                id="exampleEmail"
                                                name="phoneNumber"
                                                placeholder=""
                                                type="text"
                                                defaultValue={data?.phone || ''}
                                                {...register('phoneNumber', { pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/ })}
                                            />
                                            <span className="text-danger">{errors.phoneNumber?.type === 'required' && 'Phone Number is Required'}</span>
                                            <span className="text-danger">{errors.phoneNumber?.type === 'pattern' && 'Invalid Phone Number'}</span>
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>
                            <div className="d-flex mtop-1">
                                <Button color='primary' >Submit</Button>
                                <Button color='secondary' className='ml-1'>Cancel</Button>
                            </div>
                        </Form>
                    </ModalBody>
                </Modal>
            </div>

        </Fragment>
    )
}

