import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs, H5 } from '../../AbstractElements';
import { Link } from 'react-router-dom';
import DataTableComponent from '../../Common/Component/DataTable/DataTableComponent';
import { createProject } from '../../Common/Constant';
import { useSelector } from 'react-redux';
import HeaderCard from '../../Common/Component/HeaderCard';
import fetchWrapper from '../../Common/api/fetchWrapper';
import moment from 'moment';
import { API_URL } from '../../Common/Config/Config';
import { Eye, Edit } from 'react-feather';
import { defaultStatus, capitalize, defaultFrequency, defaultStatusColorCodes } from '../../Common/api/helper';
import { Tooltip } from 'react-tooltip';

const AssessorProjects = (props) => {
  const [tableData, setTableData] = useState([]);
  const [showFilter, setShowFilter] = useState(false)
  const [loading, setLoading] = useState(false);
  const preValidatedStatus = ['ACCEPTED', 'SUBMITTED_TO_REGISTRY', 'APPROVED_BY_REGISTRY', 'ACTIVE', 
                              'SUSPENDED', 'RETIRED']

  const tableColumns = [
    {
      name: 'Project Name',
      selector: row => `${row.name}`,
      sortable: true,
      center: false,
      filterable: showFilter,
      ignoreRowClick: true,
      with: '50px',
    },
    {
      name: 'Methodology',
      selector: row => `${row.methodology}`,
      sortable: true,
      center: false,
      filterable: showFilter,
      with: '30px',
  
    },
    {
      name: 'Country',
      selector: row => `${row.country}`,
      sortable: true,
      autoWidth: false,
      filterable: showFilter,
      center: false,
    },
    {
      name: 'Project Subcategory Type',
      selector: row => `${row.type}`,
      sortable: true,
      center: false,
      filterable: showFilter,
    },
    {
      name: 'Assessment Type',
      selector: row => row.assessmenttype === 'One time' ? 'One Time Assessment' : 
                `${capitalize(row.assessmenttype)}-${capitalize(row.periodicassessmenttype)}-${
                  row.periodicassessmenttype === 'FREQUENCY' ? row.frequency && capitalize(defaultFrequency[row.frequency]) : row.volume
                }`,
      sortable: true,
      center: false,
      filterable: showFilter,
    },
    {
      name: 'Status',
      center: false,
      with: '20px',
      cell: (row) => (
        <div >
          <p style={{
            fontSize: '12px', border: '2px solid', backgroundColor: defaultStatusColorCodes[row.status], padding: '2px 17px',
            borderRadius: '15px', borderColor: defaultStatusColorCodes[row.status], fontWeight: 500, textAlign: 'center', color: '#fff'
          }}>
            {defaultStatus[row.status]}
          </p>
        </div>
      )
    },
    {
      name: "View",
      cell: (row) => (
        <Link to={`${process.env.PUBLIC_URL}/assessor/view-edit/view/${row.id}`} >
          <Eye className={`action-icon`} />
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Edit",
      cell: (row) => (
        <Link to={preValidatedStatus.includes(row.status) === true ? '#' :`${process.env.PUBLIC_URL}/assessor/edit-project/${row.id}` } 
        className={`${preValidatedStatus.includes(row.status) === true ? 'disable-icon' : ''}`}>
          <Edit className={`edit-icon${preValidatedStatus.includes(row.status) === true ? 'disable-icon' : ''}`} />
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const loadData = async () => {
    setLoading(true)
    const options = {
      method: 'GET',
    }
    await fetchWrapper(`${API_URL}/projects`, options)
      .then((res)=>{
        console.log(res)
        setLoading(false)
        setTableData(res.json)
      }).catch((err)=> {
        setLoading(false)
        console.log(err)
      });
  }

  useEffect(() => {
    loadData();
  }, [])
  

  return (
    <Fragment>
      <Breadcrumbs parent="Projects" title="Project Lists" mainTitle="Projects" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
          <Card className=" newtable">
              <CardBody className='data-tables'>
                <DataTableComponent tableData={tableData} columnsData={tableColumns} title={'Recent Projects'} loading={loading} setShowFilter={setShowFilter} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default AssessorProjects;