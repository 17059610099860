import React, { Fragment, useState, useEffect } from "react";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { Breadcrumbs } from "../../../AbstractElements";
import { defaultUserType } from "../../api/helper";
import EvStationForm from '../Forms/EvStationForm';
import { useParams } from "react-router";

const EditEvStation = () => {
    const [data, setData] = useState();
    const id = useParams().id;

    useEffect(() => {
        loadData()
    }, []);

    const loadData = async () => {
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/evstation/${id}`, options)
            .then((res) => {
                console.log(res)
                setData(res.json)
            })
            .catch((error) => {
                console.debug(error)
            })
    }
    
    return (
        <Fragment>
            <Breadcrumbs parent="Location" title="Edit" mainTitle={data?.projectName} />
            <EvStationForm action={'Edit'}
                navigateUrl={`${process.env.PUBLIC_URL}/ev-station-lists/${data?.projectId}/${data?.projectName}`}
                preData={data} projectId={data?.projectId} />
        </Fragment>
    )
}

export default EditEvStation;