import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Badge } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { Link } from 'react-router-dom';
import DataTableComponent from '../DataTable/DataTableComponent';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { Eye, Edit, Trash2, FileText } from 'react-feather';
import { currencyFormatter } from '../../api/helper';
import AddExchangeRate from './AddExchangeRate';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import ViewHistoryModal from './ViewHistoryModal';
import AgGridTable from '../AgGridTable';

const ExchangeRateList = () => {
    const [tableData, setTableData] = useState();
    const [showFilter, setShowFilter] = useState(false)
    const [open, setOpen] = useState(false);
    const [historyModalOpen, setHistoryModalOpen] = useState(false)
    const [loading, setLoading] = useState(false);
    const [exchangeRateId, setExchangeRateId] = useState();
    const [addStatus, setAddStatus] = useState(false);
    const exchangeRef = useRef(null);

    const loadExchangeRates = async () => {
        exchangeRef?.current?.api?.showLoadingOverlay();
        await fetchWrapper(`${API_URL}/exchangerates`)
            .then((res) => {
                setTableData(res?.json);
                exchangeRef?.current?.api?.hideOverlay();
            }, (err) => {
                console.log(err)
                exchangeRef?.current?.api?.hideOverlay();
            })
    }

    useEffect(() => {
        loadExchangeRates()
    }, [])

    useEffect(() => {
        if (addStatus) {
            loadExchangeRates()
        }
    }, [addStatus])

    const columnData = [
        {
            headerName: 'Base currency',
            field: 'sourceCurrency',
            filter: true,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Quote currency',
            field: 'targetCurrency',
            filter: true,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Exchange rate',
            flex: 1,
            filter: true,
            valueGetter: (params) => {
                return currencyFormatter(params.data.rate, params.data.targetCurrency)
            },
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Edit',
            width: 80,
            cellRenderer: params => {
                return <Link to={`${process.env.PUBLIC_URL}/admin/exchange-rates/edit/${params.data.id}`} >
                    <Edit className={`edit-icon `} />
                </Link>
            },
            suppressMovable: true,
        },
        {
            headerName: 'View History',
            width: 80,
            cellRenderer: params => {
                return <div onClick={() => handleViewHistory(params.data?.id)}>
                    <FileText className={`edit-icon`} />
                </div>
            },
            suppressMovable: true,
        }
    ]

    const handleClick = () => {
        setOpen(true)
    }

    const handleViewHistory = (id) => {
        setExchangeRateId(id)
        setHistoryModalOpen(true)
    }

    const onGridReady = useCallback(async(params) => {
        loadExchangeRates()
    })
    return (
        <Fragment>
            <LayoutLoader />
            {
                historyModalOpen && <ViewHistoryModal value={historyModalOpen} setOpenModal={setHistoryModalOpen}
                    dataId={exchangeRateId} />
            }
            <Container fluid={true}>
                <Row>
                    <Col sm="10">
                        <div className="d-flex mtop-1">
                            <Breadcrumbs parent="Exchange Rates" title="List" mainTitle="Exchange Rates" />
                            <div className="text-right  action_button">
                                <div className="link" onClick={handleClick}>
                                    <i className="icon-style icofont  icofont-plus-circle" ></i>
                                    &nbsp;&nbsp;{"Add New"}
                                </div>
                            </div>
                        </div>
                        <Card className="newtable">
                            <CardBody className='data-tables responsive-view exchange-rates ptop-0'>
                                <div className='exchange-table'>
                                    <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                        <AgGridTable
                                            columnData={columnData}
                                            tableData={tableData}
                                            agRef={exchangeRef}
                                            onGridReady={onGridReady}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {
                    open && <AddExchangeRate value={open} setOpenModal={setOpen} setAdd={setAddStatus} />
                }
            </Container>
        </Fragment>
    )
}

export default ExchangeRateList;