import React, { Fragment, useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Form, Row } from 'reactstrap';
import { Breadcrumbs, P } from '../../../AbstractElements'
import { Details, Methodology, Proponent, RequiredDocuments } from '../../../Common/Constant'
import Notes from './Notes';
import "../../../assets/css/style.css";
import DetailsPage from './DetailsPage';
import RequiredDocumentsComponent from './RequireDocuments';
import fetchWrapper from '../../../Common/api/fetchWrapper';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import { useParams } from 'react-router';

const EditProject = ({ preData }) => {
    const [steps, setSteps] = useState(1);
    const [formdata, setFormdata] = useState(preData);
    const progressWidth = ['15%', '50%', '81%'];
    const projectId = useParams()?.id;

    return (
        <Fragment>
            <LayoutLoader />
            <Breadcrumbs mainTitle={'Edit Project'} childParent="Project List" title={'View'} parent="Carbon Credits" subParent="Projects" />
            <Container fluid={true}>
                <Row>
                    <Col sm='12'>
                        <Card style={{ minHeight: '100vh' }}>
                            {/* <HeaderCard title={'Form Wizard With Icon'} /> */}
                            <CardBody>
                                <div className='project-init'>
                                    <Form className='f1' method='post'>
                                        <div className="f1-steps new-f1-step  row-eq-height">
                                            <div className="f1-progress progress-bar-align">
                                                <div className="f1-progress-line" data-now-value="49" style={{ width: progressWidth[steps - 1] }} data-number-of-steps={3} />
                                            </div>
                                            <div className={`f1-step new-f1-step activated ${steps >= 1 && "active"}`}>
                                                <div className="f1-step-icon" onClick={() => setSteps(1)}><i className="fa fa-info fa-2x" /></div>
                                                <P>{Details}</P>
                                            </div>
                                            <div className={`f1-step new-f1-step activated ${steps >= 2 && "active"}`}>
                                                <div className="f1-step-icon" onClick={() => setSteps(2)}><i className="fa fa-file fa-2x" /></div>
                                                <P>{RequiredDocuments}</P>
                                            </div>
                                            <div className={`f1-step new-f1-step activated ${steps >= 3 && "active"}`}>
                                                <div className="f1-step-icon" onClick={() => setSteps(3)}><i className="fa fa-edit fa-2x" /></div>
                                                <P>{'Notes'}</P>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                                {steps === 1 && <DetailsPage setSteps={setSteps} preData={preData} projectId={projectId} />}
                                {steps === 2 && <RequiredDocumentsComponent setSteps={setSteps} projectId={projectId} preData={preData} documentsData={preData?.documents} />}
                                {steps === 3 && <Notes setSteps={setSteps} projectId={projectId} preData={preData} />}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default EditProject

