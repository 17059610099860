import React, { Fragment, useState, useEffect } from "react";
import { CardBody, InputGroup, InputGroupText } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Button
} from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { toast } from "react-toastify";
import ButtonLoader from "../ButtonLoader";
import { convertTimeformat, defaultUserType, handleDate, CustomInput } from "../../api/helper";
import { currencySymbol } from "../../api/currencyData";
import Tab from 'react-bootstrap/Tab';
import Nav from "react-bootstrap/Nav";
import DatePicker from 'react-datepicker';
import moment from "moment";

const AdminForm = ({ type, title, action, navigateUrl, cancel, preData, setAdd }) => {
    const orgControl = {
        'Equity': 'EQUITY',
        'Operational': 'OPERATIONAL',
        'Financial': 'FINANCIAL'
    }
    const [loading, setLoading] = useState(false)
    const [stateList, setStateList] = useState([]);
    const [country, setCountry] = useState();
    const [data, setData] = useState(preData)
    const { register, handleSubmit, formState: { errors }, reset, setValue, control, getValues, resetField } = useForm({ defaultValues: data });
    const [organizationList, setOrganizationList] = useState([]);
    const [ghgEmission, setGhgEmission] = useState(false);
    const [state, setState] = useState(preData?.state);
    const [tenantData, setTenantData] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [currency, setCurrency] = useState();
    const [countryList, setCountryList] = useState([]);
    const [relationshipOwner, setRelationship] = useState();
    const [allowSuborgs, setAllow] = useState(true);
    const [ghgReportingFrequancy, setFrequancy] = useState(preData?.ghg_reporting_frequency || 'MONTHLY')
    const [activeKey, setActiveKey] = useState('1');
    const [reportType, setReportType] = useState('');
    const [incorporationYear, setIncorporation] = useState();
    const [parentOrg, setParentOrg] = useState(preData?.parentOrgId)
    const [controlType, setControlType] = useState(preData?.controlType);
    const [controlPercentage, setControlPercentage] = useState(orgControl[preData?.controlTypePercentage])
    const [effectiveDate, setEffectiveDate] = useState(preData?.effectiveDate ? new Date(preData.effectiveDate) : new Date());
    const [endDate, setEndDate] = useState(preData?.endDate ? new Date(preData?.endDate) : '');
    const [orgType, setOrgType] = useState('');
    const [parentOrgAffiliation, setParentOrgAffiliation] = useState(preData?.parentOrgAffiliation);
    const navigate = useNavigate();
    const userType = sessionStorage.getItem('userType');

    const getOrgList = async () => {
        await fetchWrapper(`${API_URL}/organization`)
            .then((res) => {
                console.log(res.json)
                if (preData?.id) {
                    setOrganizationList(res?.json?.filter(ele => ele?.id === preData?.id));
                } else {
                    setOrganizationList(res?.json)
                }
            }, (err) => console.log(err))
    }

    const getCountry = async () => {
        await fetchWrapper(`${API_URL}/country`)
            .then((res) => {
                setCountryList(res.json)
            }, (err) => console.log(err))
    }

    useEffect(() => {
        getCountry();
        getOrgList()
    }, [])

    const handleCountry = async (name) => {
        setCountry(name)
        const selectedCountry = countryList?.find(ele => ele.name === name);
        await fetchWrapper(`${API_URL}/state/country/${selectedCountry?.id}`)
            .then((res) => {
                if (res.status === 200) {
                    setStateList(res.json)
                }
            }, (err) => {
                console.log(err)
            })
    }

    const loadTenantData = async () => {
        await fetchWrapper(`${API_URL}/tenants`)
            .then((res) => {
                setTenantData(res.json);
            }, (err) => console.log(err))
    }

    const getCurrency = async () => {
        await fetchWrapper(`${API_URL}/currency`)
            .then((res) => {
                setCurrencyList(res.json)
            }, (err) => console.log(err))
    }



    const onSubmit = async (data) => {
        setLoading(true)
        let bodyData = { ...data };
        data.ghgEmission = data?.ghgEmission === 'true' ? true : false;
        data.subOrgsEnabled = data?.subOrgsEnabled === 'true' ? true : false;
        data.parentOrgAffiliation = data?.parentOrgAffiliation === 'true' ? true : false;
        data.yearOfIncorporation = moment(incorporationYear).format('YYYY')
        let url, message;
        let method = action === "Add" ? 'POST' : 'PUT';
        if (action === 'Add') {
            url = `${API_URL}/organization`
            message = 'Organization Created Successfully!'
        }
        if (action === 'Edit') {
            url = `${API_URL}/organization/${data.id}`
            message = 'Organization Updated Successfully!'
        }
        const options = {
            method: method,
            body: data
        }
        console.log(data)
        if (action === 'Edit') {
            delete data.email;
        }
        await fetchWrapper(url, options)
            .then((res) => {
                if (res.status === 200) {
                    toast.success(message)
                    setLoading(false)
                    action === 'Add' && cancel()
                    action === 'Edit' && navigate(navigateUrl)
                    if (action === 'Add') {
                        navigate(`${process.env.PUBLIC_URL}/admin/module-origanization`)
                    }
                    setAdd(true);
                }
            })
            .catch((err) => {
                setLoading(false)
                if (err.status == 409) {
                    toast.error('Organization Already Exist..')
                    console.log(err)
                } else if (err.status === 500) {
                    toast.error('Something went wrong')
                }
            })
    }

    useEffect(() => {
        setData(prev => ({ ...prev, ...preData }))
        loadTenantData();
        reset(preData)
        setOrgType(preData?.orgType)
        getCurrency();
        getCountry()
        if (preData) {
            handleCountry(preData?.country)
            setState(preData?.state);
            setGhgEmission(preData?.ghgEmission)
            setCurrency(preData?.currency);
            setValue('currency', preData?.currency);
            setValue('relationshipOwner', preData?.relationshipOwner)
            setRelationship(preData?.relationshipOwner)
            setIncorporation(new Date(Date.UTC(preData?.yearOfIncorporation, 0, 1, 12, 0, 0)))
            setAllow(preData?.subOrgsEnabled)
            setParentOrg(preData?.parentOrgId);
            setFrequancy(preData?.ghgReportingFrequancy);
            setReportType(preData?.reportType);
            setEffectiveDate(preData?.effectiveDate);
            setEndDate(preData?.endDate)
        }
    }, [preData]);

    useEffect(() => {
        if (preData) {
            reset(preData)
        }
    }, [preData, reset])

    return (
        <Fragment>
            <Row>
                <Col lg={action === 'Add' ? 12 : 10}>
                    {title && <H3 attrH3={{ className: 'mtop-2 ml-15' }}>{title}</H3>}
                    <Card className={`${action === 'Add' && 'mb-0'} ${action === 'Edit' && ''}`}>
                        <CardBody className={action === 'Add' && 'p-0 mb-0 bs-none'}>
                            <Form
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <Tab.Container activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                                    <div>
                                        <Nav variant="tabs" defaultActiveKey={1}>
                                            <Nav.Item>
                                                <Nav.Link eventKey={1}>Company</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey={3}>General Info</Nav.Link>
                                            </Nav.Item>
                                            {/* <Nav.Item>
                                                <Nav.Link eventKey={2}>Contact</Nav.Link>
                                            </Nav.Item> */}
                                        </Nav>
                                    </div>
                                    <Tab.Content className="mtop-1">
                                        <Tab.Pane eventKey={1}>
                                            <Row>
                                                <Col lg="6">
                                                    <div>
                                                        <FormGroup >
                                                            <Label for="exampleEmail">Company Name <span className='required'>*</span> </Label>
                                                            <input
                                                                className="form-control"
                                                                name="companyName"
                                                                placeholder=""
                                                                type="text"
                                                                required
                                                                defaultValue={data?.companyName || ''}
                                                                {...register('companyName', { required: true })}
                                                            />
                                                            <span className="text-danger">{errors.companyName && 'Company Name is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg='6'>
                                                    <div>
                                                        <FormGroup>
                                                            <Label for="exampleSelect">Website <span className='required'>*</span> </Label>
                                                            <input
                                                                className="form-control"
                                                                id="exampleEmail"
                                                                name="website"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.website || ''}
                                                                {...register('website', { required: true, pattern: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ })}
                                                            />
                                                            <span className="text-danger">{errors?.website?.type === 'required' && 'Website is Required'}</span>
                                                            <span className="text-danger">{errors?.website?.type === 'pattern' && 'Invalid Website URL'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg='12'>
                                                    <div>
                                                        <FormGroup>
                                                            <Label for="exampleSelect">Address <span className='required'>*</span></Label>
                                                            <input
                                                                className="form-control"
                                                                id="exampleEmail"
                                                                name="address"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.address || ''}
                                                                {...register('address', { required: true })}
                                                            />
                                                            <span className="text-danger">{errors.address && 'Address is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup >
                                                        <Label>City <span className='required'>*</span> </Label>
                                                        <input
                                                            className="form-control"
                                                            name="city"
                                                            placeholder=""
                                                            type="text"
                                                            required
                                                            defaultValue={data?.city || ''}
                                                            {...register('city', { required: true })}
                                                        />
                                                        <span className="text-danger">{errors.city && 'City is Required'}</span>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup >
                                                        <Label>Zipcode <span className='required'>*</span> </Label>
                                                        <input
                                                            className="form-control"
                                                            name="zipcode"
                                                            placeholder=""
                                                            type="text"
                                                            required
                                                            defaultValue={data?.zipcode}
                                                            {...register('zipCode', { required: true, maxLength: 8, minLength: 3 })}
                                                        />
                                                        <span className="text-danger">{errors.zipCode?.type === 'required' && 'Zipcode is Required'}</span>
                                                        <span className="text-danger">{errors.zipCode?.type === 'maxLength' && 'Zipcode should be less than 8 characters'}</span>
                                                        <span className="text-danger">{errors.zipCode?.type === 'minLength' && 'Zipcode should be more than 3 characters'}</span>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <div>
                                                        <FormGroup >
                                                            <Label for="exampleSelect">Country <span className='required'>*</span></Label>
                                                            <select className="form-select" name="country"
                                                                defaultValue={data?.country || ''}
                                                                {...register('country', { required: true })}
                                                                onChange={e => handleCountry(e.target.value)}
                                                            >
                                                                <option value=''>-Select Country-</option>
                                                                {countryList.map((item, i) => {
                                                                    return (
                                                                        <option value={item.name}>{item.name}</option>
                                                                    )
                                                                }
                                                                )}
                                                            </select>
                                                            <span className="text-danger">{errors.country && 'Country is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div>
                                                        <FormGroup >
                                                            <Label for="exampleSelect">State <span className='required'>*</span></Label>
                                                            <select className="form-select" name="state"
                                                                value={state}
                                                                {...register('state', { required: true })}
                                                                onChange={(e) => setState(e.target.value)}
                                                            >
                                                                <option>-Select State-</option>
                                                                {
                                                                    stateList && stateList.length > 0 && stateList.map(i => {
                                                                        return (<option value={i.name}>{i.name}</option>)
                                                                    })
                                                                }
                                                            </select>
                                                            <span className="text-danger">{errors.state && 'state is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <div>
                                                        <FormGroup >
                                                            <Label for="exampleSelect">Organization Type <span className='required'>*</span></Label>
                                                            <select className="form-select" name="orgType" defaultValue={data?.orgType || ''}
                                                                {...register('orgType', { required: true })}
                                                                onChange={(e) => setOrgType(e.target.value)}>
                                                                <option value=''>-Select Organization Type-</option>
                                                                <option value={'ASSET_OWNER'}>Asset Owner</option>
                                                                <option value={'ASSESSOR'}>Assessor</option>
                                                                <option value={'ASSET_MANAGER'}>Asset Manager</option>
                                                            </select>
                                                            <span className="text-danger">{errors.orgType && 'orgType is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                {
                                                    orgType === 'ASSET_OWNER' && <Col lg="6">
                                                        <div>
                                                            <FormGroup >
                                                                <Label>Powered By <span className='required'>*</span></Label>
                                                                <select className="form-select" name='relationshipOwner' value={relationshipOwner}
                                                                    {...register('relationshipOwner', { required: true })} onChange={(e) => setRelationship(e.target.value)}
                                                                >
                                                                    <option value={''}>-Select Powered By-</option>
                                                                    {
                                                                        tenantData && tenantData?.length > 0 && tenantData?.map(i => <option value={i.tenantIdUUID}>{i.tenantName}</option>)
                                                                    }
                                                                </select>
                                                            </FormGroup>
                                                        </div>
                                                    </Col>
                                                }
                                            </Row>

                                            {
                                                orgType === 'ASSET_OWNER' && <div>
                                                    <Row>
                                                        <Col sm='6'>
                                                            <FormGroup className="mb-6">
                                                                <label>Currency</label>
                                                                <select className="form-select" name='currency' defaultValue={data?.currency}
                                                                    {...register('currency')} onChange={(e) => setCurrency(e.target.value)}>
                                                                    <option value={''}>-Select Currency-</option>
                                                                    {
                                                                        currencyList?.length > 0 && currencyList?.map((item) => {
                                                                            return (
                                                                                <option value={item.currencyCode}>{item.description}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm='6'>
                                                            <FormGroup className="mb-6">
                                                                <label>Parent Organization</label>
                                                                <select className="form-select" name='parentOrgId' value={parentOrg}
                                                                    {...register('parentOrgId')} onChange={(e) => setParentOrg(e.target.value)}>
                                                                    <option value={''}>-Not Applicable-</option>
                                                                    {
                                                                        organizationList?.map((item) => (
                                                                            <option value={item?.id}>{item?.companyName}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        parentOrg && <Row>
                                                            <Col sm='6'>
                                                                <FormGroup className="mb-6">
                                                                    <label>Does it affiliate in the parent organization Business Responsibility initiatives</label>
                                                                    <select className="form-select" name='parentOrgAffiliation' value={parentOrgAffiliation}
                                                                        {...register('parentOrgAffiliation')} onChange={(e) => setParentOrgAffiliation(e.target.value)}>
                                                                        <option value={''}>-Not Applicable-</option>
                                                                        <option value={true}>Yes</option>
                                                                        <option value={false}>No</option>
                                                                    </select>
                                                                </FormGroup>
                                                            </Col>

                                                        </Row>
                                                    }
                                                    {
                                                        (parentOrg && action === 'Add') && <Row>
                                                            <Col lg='6'>
                                                                <FormGroup>
                                                                    <Label>Control Type <span className='required'>*</span></Label>
                                                                    <select className="form-select" name="controlType" value={orgControl[controlType]}
                                                                        {...register('controlType', { required: true })}
                                                                        onChange={e => setControlType(e.target.value)}
                                                                    >
                                                                        <option value=''>-Select Control Type-</option>
                                                                        <option value="EQUITY">Equity</option>
                                                                        <option value="OPERATIONAL">Operational</option>
                                                                        <option value="FINANCIAL">Financial</option>
                                                                    </select>
                                                                    <span className="text-danger">{errors?.controlType && 'Control Type is required'}</span>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg='6'>
                                                                <FormGroup >
                                                                    <Label>Control Percentage (%) <span className='required'>*</span></Label>
                                                                    <input
                                                                        className="form-control"
                                                                        name="controlTypePercentage"
                                                                        placeholder="%"
                                                                        type="text"
                                                                        value={controlPercentage}
                                                                        {...register('controlTypePercentage', { required: true, min: 0, max: 100 })}
                                                                        onChange={(e) => setControlPercentage(e.target.value)}
                                                                    />
                                                                    <span className="text-danger">{errors.controlTypePercentage?.type === 'required' && 'Control Percentage (%) is Required'}</span>
                                                                    <span className="text-danger">{errors.controlTypePercentage?.type === 'min' && 'The value should be greater than 0'}</span>
                                                                    <span className="text-danger">{errors.controlTypePercentage?.type === 'max' && 'The value should be less than 100'}</span>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    {
                                                        parentOrg && <Row>
                                                            <Col lg='6'>
                                                                <FormGroup className="mb-3 project-form">
                                                                    <label>Effective Date <span className="text-danger">*</span> </label>
                                                                    <Controller
                                                                        name='effectiveDate'
                                                                        control={control}
                                                                        rules={{
                                                                            required: true,
                                                                        }}
                                                                        onChange={(e) => setEffectiveDate(e)}
                                                                        value={handleDate(data?.effectiveDate)}
                                                                        render={({ field: { onChange, value } }) => {
                                                                            return (
                                                                                <DatePicker
                                                                                    className="datepicker-here form-control"
                                                                                    selected={value ? new Date(value) : new Date()}
                                                                                    onChange={e => {
                                                                                        onChange(e)
                                                                                    }}
                                                                                    showMonthDropdown
                                                                                    dateFormatCalendar="MMMM"
                                                                                    showYearDropdown
                                                                                    yearDropdownItemNumber={15}
                                                                                    dropdownMode="select"
                                                                                    dateFormat="dd MMM, yyyy"
                                                                                    customInput={<CustomInput />}
                                                                                    minDate={new Date()}
                                                                                />
                                                                            )
                                                                        }}
                                                                    />
                                                                    <span className='text-danger'>{errors.effectiveDate && errors.effectiveDate.type === 'required' && 'Effective Date is required'}</span>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg='6'>
                                                                <FormGroup className="mb-3 project-form">
                                                                    <label>End Date </label>
                                                                    <Controller
                                                                        name='endDate'
                                                                        control={control}
                                                                        onChange={(e) => setEndDate(e)}
                                                                        value={handleDate(data?.endDate)}
                                                                        render={({ field: { onChange, value } }) => {
                                                                            return (
                                                                                <DatePicker
                                                                                    className="datepicker-here form-control"
                                                                                    selected={value ? new Date(value) : ''}
                                                                                    onChange={e => {
                                                                                        onChange(e)
                                                                                    }}
                                                                                    showMonthDropdown
                                                                                    dateFormatCalendar="MMMM"
                                                                                    showYearDropdown
                                                                                    yearDropdownItemNumber={15}
                                                                                    dropdownMode="select"
                                                                                    dateFormat="dd MMM, yyyy"
                                                                                    customInput={<CustomInput />}
                                                                                    minDate={new Date()}
                                                                                />
                                                                            )
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    <Row>
                                                        {
                                                            preData && <Row>
                                                                <Col>
                                                                    <FormGroup className="mb-6">
                                                                        <label>GHG Reporting Frequency</label>
                                                                        <select className="form-select" name='ghgReportingFrequency'
                                                                            value={ghgReportingFrequancy} {...register('ghgReportingFrequency')}
                                                                            onChange={(e) => setFrequancy(e.target.value)}>
                                                                            <option value={''}>-Select Frequency-</option>
                                                                            <option value="MONTH">MONTH</option>
                                                                            <option value="QUARTER">QUARTER</option>
                                                                            <option value="YEAR">YEAR</option>
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col>
                                                                    <FormGroup className="mb-6">
                                                                        <label>Report Type</label>
                                                                        <select className="form-select" name='reportType'
                                                                            value={reportType} {...register('reportType')}
                                                                            onChange={(e) => setReportType(e.target.value)}>
                                                                            <option value={''}>-Select Report Type-</option>
                                                                            <option value="BRSR">BRSR</option>
                                                                            <option value="GRI">GRI</option>
                                                                            <option value="SASB">SASB</option>
                                                                            <option value="TCFD">TCFD</option>
                                                                            <option value="ESG">ESG</option>
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        }

                                                    </Row>
                                                </div>

                                            }
                                        </Tab.Pane>
                                        <Tab.Pane eventKey={3}>
                                            <Row>
                                                <Col lg='6'>
                                                    <div>
                                                        <FormGroup>
                                                            <Label>Corporate Identity Number</Label>
                                                            <input
                                                                className="form-control"
                                                                name="corporateIdentityNumber"
                                                                placeholder=""
                                                                type="text"
                                                                maxLength={21}
                                                                defaultValue={data?.corporateIdentityNumber || ''}
                                                                {...register('corporateIdentityNumber', { maxLength: 21 })}
                                                            />
                                                            <span className="text-danger">{errors.corporateIdentityNumber?.type === 'maxLength' && 'Corporate Identity Number Length should be 21'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg='6'>
                                                    <div className="project-form">
                                                        <FormGroup>
                                                            <Label>Year of Incorporation</Label>
                                                            <DatePicker
                                                                className="datepicker-here form-control"
                                                                selected={incorporationYear}
                                                                onChange={(e) => setIncorporation(e)}
                                                                showYearPicker
                                                                dateFormat="yyyy"
                                                                maxDate={new Date()}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg='12'>

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <div>
                                                        <FormGroup >
                                                            <Label for="exampleEmail">Company Email</Label>
                                                            <input
                                                                className="form-control"
                                                                name="companyEmail"
                                                                placeholder="info@company.com"
                                                                type="companyEmail"
                                                                defaultValue={data?.companyEmail || ''}
                                                                {...register('companyEmail', { pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })}
                                                            />
                                                            <span className="text-danger">{errors?.companyEmail?.type === 'pattern' && 'Invalid Email'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div>
                                                        <FormGroup >
                                                            <Label for="exampleEmail">Company Contact No</Label>
                                                            <input
                                                                className="form-control"
                                                                name="companyContactNumber"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.companyContactNumber || ''}
                                                                {...register('companyContactNumber')}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg='6'>
                                                    <div>
                                                        <FormGroup>
                                                            <Label>Reporting Financial Year</Label>
                                                            <input
                                                                className="form-control"
                                                                name="reportingFinancialYear"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.reportingFinancialYear || ''}
                                                                {...register('reportingFinancialYear')}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg='6'>
                                                    <div>
                                                        <FormGroup>
                                                            <Label>Name of the Stock Exchanges</Label>
                                                            <input
                                                                className="form-control"
                                                                name="stockExchangeName"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.stockExchangeName || ''}
                                                                {...register('stockExchangeName')}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg='6'>
                                                    <div>
                                                        <FormGroup>
                                                            <Label>Paid-up Capital</Label>
                                                            <InputGroup>
                                                                <InputGroupText>
                                                                    {currencySymbol[currency]}
                                                                </InputGroupText>
                                                                <input
                                                                    className="form-control"
                                                                    name="paidUpCapital"
                                                                    placeholder=""
                                                                    type="text"
                                                                    defaultValue={data?.paidUpCapital || ''}
                                                                    {...register('paidUpCapital')}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg='6'>
                                                    <div>
                                                        <FormGroup>
                                                            <Label>Assurance Provider Name</Label>
                                                            <input
                                                                className="form-control"
                                                                name="assuranceProviderName"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.assuranceProviderName || ''}
                                                                {...register('assuranceProviderName')}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg='6'>
                                                    <div>
                                                        <FormGroup>
                                                            <Label>Assurance Type</Label>
                                                            <input
                                                                className="form-control"
                                                                name="assuranceType"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.assuranceType || ''}
                                                                {...register('assuranceType')}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg='6'>
                                                    <div>
                                                        <FormGroup>
                                                            <Label for="exampleSelect">Corporate Address</Label>
                                                            <textarea
                                                                className="form-control"
                                                                name="corporateAddress"
                                                                rows={3}
                                                                cols={6}
                                                                defaultValue={data?.corporateAddress || ''}
                                                                {...register('corporateAddress')}
                                                            />
                                                            <span className="text-danger">{errors.corporateAddress && 'Corporate Address is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg='6'>
                                                    <div>
                                                        <FormGroup>
                                                            <Label>Name and contact details (telephone, email address).</Label>
                                                            <textarea
                                                                className="form-control"
                                                                name="brsrReportSpoc"
                                                                rows={3}
                                                                cols={6}
                                                                defaultValue={data?.brsrReportSpoc || ''}
                                                                {...register('brsrReportSpoc')}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col >
                                                    <div>
                                                        <FormGroup>
                                                            <Label>Reporting Boundary</Label>
                                                            <textarea
                                                                className="form-control"
                                                                name="reportingBoundary"
                                                                rows={3}
                                                                cols={6}
                                                                defaultValue={data?.reportingBoundary || ''}
                                                                {...register('reportingBoundary')}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                                <Row>
                                    <div className="d-flex " style={{ marginTop: "25px" }}>
                                        {
                                            loading ? <ButtonLoader loaderText={`${action === "Add" ? action : 'Updat'}ing`} color="primary" /> : <Button color="primary" >{action === "Add" ? action : 'Update'}</Button>

                                        }
                                        <Button color="secondary" className="ml-1" onClick={() => action === 'Add' ? cancel() : navigate(navigateUrl)}> Cancel</Button>
                                        {
                                            action === 'Edit' && data && data?.orgType === "ASSET_MANAGER" &&
                                            <div className=" ml-1 manage-asm-but">
                                                <Link to={`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/asm-management/${data?.id}`}>
                                                    <Button color="primary" >Manage Asset Manager</Button>
                                                </Link>
                                            </div>
                                        }
                                    </div>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default AdminForm;