import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { CustomFooter, thousandSeparator } from "../../../api/helper";
import { Button } from "reactstrap";
import moment from "moment";
import { ESimpactData, greivanceData, materialData } from "../../Settings/settingshelper";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";
import { toast } from "react-toastify";
import { Trash2 } from "react-feather";

const SustainableSafePractices = ({ setActive, financialYear, previousYear, previous2Years, mode }) => {
    const [ESImpact, setESImpact] = useState(ESimpactData);
    const [sustainableSourcing, setSustainableSourcing] = useState()
    const [sustainablePercentage, setSustainablePercentage] = useState();
    const [reusableProcess, setReusableProcess] = useState();
    const [eprApplicable, setEprApplicable] = useState();
    const [lcaData, setLcaData] = useState([...Array(2)].map((_, index) => ({ serialNo: index + 1 })));
    const [lcaRisksData, setLcaRisks] = useState([...Array(2)].map((_, index) => ({ serialNo: index + 1 })));
    const [reusableProductDetails, setReusableProduct] = useState([...Array(2)].map((_, index) => ({ serialNo: index + 1 })))
    const [productDetails, setProductDetails] = useState(materialData);
    const [productPercentage, setProductPercentage] = useState([...Array(2)].map((_, index) => ({ serialNo: index + 1 })));

    const getSustainableInvestmentData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/sustainableInvestmentData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                const tempArray = ESimpactData?.map(ele => {
                    const temp = res?.json?.find(item => item?.particulars === ele?.particulars);
                    return temp ? temp : ele;
                })
                setESImpact(tempArray)
            }, (err) => {
                console.log(err)
            })
    }

    const getAttributesData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/attributesKeyValueData/${financialYear}`)
            .then((res) => {
                const data = res.json?.filter(ele => ele.groupName === 'sustainableSafePractices');
                console.log(data?.filter(ele => ele.key === 'Q8'))
                setSustainableSourcing(data?.find(ele => ele.key === 'Q7')?.value);
                setSustainablePercentage(data?.find(ele => ele.key === 'Q8')?.value);
                setReusableProcess(data?.find(ele => ele.key === 'Q9')?.value);
                setEprApplicable(data?.find(ele => ele.key === 'Q10')?.value);
            }, (err) => console.log(err))
    }

    const getLifecycleAssessmentData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/lifecycleAssessmentData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                setLcaData(res.json?.filter(ele => ele?.nicCode))
            }, (err) => {
                console.log(err)
            })
    }

    const getRiskMitigationData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/riskMitigationData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                setLcaRisks(res.json)
            }, (err) => {
                console.log(err)
            })
    }

    const getRecycledMaterialData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/recycledMaterialData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                setReusableProduct(res.json?.filter(ele => ele?.inputMaterialName))
            }, (err) => {
                console.log(err)
            })
    }

    const getMaterialManagementData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/materialManagementData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                const tempArray = materialData?.map(ele => {
                    const temp = res?.json?.find(item => item?.particulars === ele?.particulars);
                    return temp ? temp : ele;
                })
                console.log(tempArray)
                setProductDetails(tempArray)
            }, (err) => {
                console.log(err)
            })
    }

    const getProductReclamationData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/productReclamationData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                setProductPercentage(res.json?.filter(ele => ele?.productCategory))
            }, (err) => {
                console.log(err)
                setProductPercentage([...Array(2)].map((_, index) => ({ serialNo: index + 1 })))
            })
    }


    useEffect(() => {
        if (financialYear) {
            getSustainableInvestmentData()
            getAttributesData()
            getLifecycleAssessmentData()
            getRiskMitigationData()
            getRecycledMaterialData()
            getMaterialManagementData()
            getProductReclamationData()
        }
    }, [financialYear])

    const handleClear = (params, type) => {
        let updatedRowData;
        if (type === 1) {
            updatedRowData = ESImpact?.map((row, index) => {
                return (index == params.node.rowIndex) ? { particulars: row?.particulars } : { ...row }
            })
            setESImpact(updatedRowData);
        } else if (type === 2) {
            updatedRowData = lcaData?.map((row, index) => {
                return (index == params.node.rowIndex) ? { serialNo: index + 1 } : { ...row }
            })
            setLcaData(updatedRowData)
        } else if (type === 3) {
            updatedRowData = lcaRisksData?.map((row, index) => {
                return (index == params.node.rowIndex) ? { serialNo: index + 1 } : { ...row }
            })
            setLcaRisks(updatedRowData)
        } else if (type === 4) {
            updatedRowData = reusableProductDetails?.map((row, index) => {
                return (index == params.node.rowIndex) ? { serialNo: index + 1 } : { ...row }
            })
            setReusableProduct(updatedRowData);
        } else if (type === 5) {
            updatedRowData = productDetails?.map((row, index) => {
                return (index == params.node.rowIndex) ? { particulars: row?.particulars } : { ...row }
            })
            setProductDetails(updatedRowData)
        } else if (type === 6) {
            updatedRowData = productPercentage?.map((row, index) => {
                return (index == params.node.rowIndex) ? { serialNo: index + 1 } : { ...row }
            })
            setProductPercentage(updatedRowData)
        }
    }

    const impactableColumns = [
        {
            headerName: '',
            field: 'particulars',
            editable: false,
            filter: false,
            flex: 1,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: financialYear ? `${financialYear} (%)` : 'FY _____ (%)',
            field: 'currentYearFinancial',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                max: 100,
                precision: 2
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.currentYearFinancial ?
                    thousandSeparator(params?.data?.currentYearFinancial.toFixed(2)) : ''}</div>;
            },
            flex: 1.5,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            sortable: true
        },
        {
            headerName: financialYear ? `${previousYear} (%)` : 'FY _____ (%)',
            field: 'previousYearFinancial',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                max: 100,
                precision: 2
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.previousYearFinancial ?
                    thousandSeparator(params?.data?.previousYearFinancial.toFixed(2)) : ''}</div>;
            },
            flex: 1.5,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            sortable: true
        },
        {
            headerName: 'Impact Details',
            field: 'socialEnvironmentalImpactsDetails',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        // {
        //     headerName: 'Delete',
        //     width: 70,
        //     cellRenderer: (params) => {
        //         if (params.data.hasOwnProperty('currentYearFinancial') ||
        //             params.data.hasOwnProperty('previousYearFinancial') ||
        //             params.data.hasOwnProperty('socialEnvironmentalImpactsDetails')
        //         )
        //             return (
        //                 <div>
        //                     <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 1)} />
        //                 </div>
        //             )
        //     }
        // }
    ];

    const lcaColumns = [
        {
            headerName: 'Industry Classification Code',
            field: 'nicCode',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            filter: false,
            cellEditor: 'agTextCellEditor',
            cellEditorParams: {
                maxLength: 6
            },
            flex: 1.75,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Product/service Name',
            field: 'productServiceName',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            filter: false,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            flex: 1.25,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Total Turnover %',
            field: 'percentageTurnOver',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                max: 100,
                precision: 2
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.percentageTurnOver ?
                    thousandSeparator(params?.data?.percentageTurnOver.toFixed(2)) : ''}</div>;
            },
            flex: 1.25,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            sortable: true
        },
        {
            headerName: 'LCA Boundary',
            field: 'lcaBoundary',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            filter: false,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            flex: 1.25,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'External agency Conducted?(Yes/No) ',
            field: 'externalAgencyInvolement',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Yes', 'No']
            },
            filter: false,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            flex: 1.75,
            sortable: false
        },
        {
            headerName: 'Public Results (Yes/No)',
            field: 'resultsPublicCommunication',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Yes', 'No']
            },
            filter: false,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            flex: 1.25,
            sortable: false

        },
        {
            headerName: 'Public Results Link',
            field: 'publicDomainLink',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            filter: false,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            cellRenderer: (params) => {
                if (params.data.publicDomainLink) {
                    return (
                        <a href={params.data.publicDomainLink} target="_blank">{params.data.publicDomainLink}</a>
                    )
                }
            },
            flex: 1.25,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        // {
        //     headerName: 'Delete',
        //     width: 70,
        //     cellRenderer: (params) => {
        //         if (params.data.hasOwnProperty('nicCode') ||
        //             params.data.hasOwnProperty('productServiceName') ||
        //             params.data.hasOwnProperty('percentageTurnOver') ||
        //             params.data.hasOwnProperty('lcaBoundary') ||
        //             params.data.hasOwnProperty('externalAgencyInvolement') ||
        //             params.data.hasOwnProperty('resultsPublicCommunication') ||
        //             params.data.hasOwnProperty('publicDomainLink')
        //         )
        //             return (
        //                 <div>
        //                     <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 2)} />
        //                 </div>
        //             )
        //     }
        // }
    ];

    const lcaRiskColumns = [
        {
            headerName: 'Name of product/service',
            field: 'productServiceName',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            filter: false,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Description of risk/concern',
            field: 'riskDescription',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            filter: false,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Action taken',
            field: 'actionTaken',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            filter: false,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        // {
        //     headerName: 'Delete',
        //     width: 70,
        //     cellRenderer: (params) => {
        //         if (params.data.hasOwnProperty('productServiceName') ||
        //             params.data.hasOwnProperty('riskDescription') ||
        //             params.data.hasOwnProperty('actionTaken')
        //         )
        //             return (
        //                 <div>
        //                     <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 3)} />
        //                 </div>
        //             )
        //     }
        // }
    ];

    const reusablePercentageColumns = [
        {
            headerName: 'Input Material',
            field: 'inputMaterialName',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            filter: false,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Recycled or re-used input material to total material ',
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: financialYear ? `${financialYear} (%)` : 'FY _____ (%)',
                    field: 'recycledMaterialPercentageCurrentYear',
                    editable: mode === 'Edit' ? true : false,
                    cellClass: mode === 'Edit' ? '' : 'disable-cell',
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        max: 100
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: financialYear ? `${previousYear} (%)` : 'FY _____ (%)',
                    field: 'recycledMaterialPercentagePreviousYear',
                    editable: mode === 'Edit' ? true : false,
                    cellClass: mode === 'Edit' ? '' : 'disable-cell',
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        max: 100
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        // {
        //     headerName: 'Delete',
        //     width: 70,
        //     cellRenderer: (params) => {
        //         if (params.data.hasOwnProperty('inputMaterialName') ||
        //             params.data.hasOwnProperty('recycledMaterialPercentageCurrentYear') ||
        //             params.data.hasOwnProperty('recycledMaterialPercentagePreviousYear')
        //         )
        //             return (
        //                 <div>
        //                     <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 4)} />
        //                 </div>
        //             )
        //     }
        // }
    ];
    const materialColumns = [
        {
            headerName: '',
            field: 'particulars',
            editable: false,
            filter: false,
            flex: 2,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: financialYear ? `${financialYear}` : 'FY _____',
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: 'Re-used',
                    field: 'reusedCurrentYear',
                    editable: mode === 'Edit' ? true : false,
                    cellClass: mode === 'Edit' ? '' : 'disable-cell',
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Recycled',
                    field: 'recycledCurrentYear',
                    editable: mode === 'Edit' ? true : false,
                    cellClass: mode === 'Edit' ? '' : 'disable-cell',
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Disposed',
                    field: 'safelyDisposedCurrentYear',
                    editable: mode === 'Edit' ? true : false,
                    cellClass: mode === 'Edit' ? '' : 'disable-cell',
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        {
            headerName: financialYear ? `${previousYear}` : 'FY _____',
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: 'Re-used',
                    field: 'reusedPreviousYear',
                    editable: mode === 'Edit' ? true : false,
                    cellClass: mode === 'Edit' ? '' : 'disable-cell',
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Recycled',
                    field: 'recycledPreviousYear',
                    editable: mode === 'Edit' ? true : false,
                    cellClass: mode === 'Edit' ? '' : 'disable-cell',
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Disposed',
                    field: 'safelyDisposedPreviousYear',
                    editable: mode === 'Edit' ? true : false,
                    cellClass: mode === 'Edit' ? '' : 'disable-cell',
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        // {
        //     headerName: 'Delete',
        //     width: 70,
        //     cellRenderer: (params) => {
        //         if (params.data.hasOwnProperty('reusedCurrentYear') ||
        //             params.data.hasOwnProperty('recycledCurrentYear') ||
        //             params.data.hasOwnProperty('safelyDisposedCurrentYear') ||
        //             params.data.hasOwnProperty('reusedPreviousYear') ||
        //             params.data.hasOwnProperty('recycledPreviousYear') ||
        //             params.data.hasOwnProperty('safelyDisposedPreviousYear')
        //         )
        //             return (
        //                 <div>
        //                     <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 5)} />
        //                 </div>
        //             )
        //     }
        // }
    ]

    const productPercentageColumns = [
        {
            headerName: 'Product Category',
            field: 'productCategory',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            filter: false,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Reclaimed Products Percentage (%)',
            field: 'reclaimedPercentage',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                max: 100,
                precision: 2
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.reclaimedPercentage ?
                    thousandSeparator(params?.data?.reclaimedPercentage.toFixed(2)) : ''}</div>;
            },
            flex: 1.5,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            sortable: true
        },
        // {
        //     headerName: 'Delete',
        //     width: 70,
        //     cellRenderer: (params) => {
        //         if (params.data.hasOwnProperty('productCategory') ||
        //             params.data.hasOwnProperty('reclaimedPercentage')
        //         )
        //             return (
        //                 <div>
        //                     <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 6)} />
        //                 </div>
        //             )
        //     }
        // }

    ]

    const gridOptions = {
        groupHeaderHeight: 35, // Set the height of the group header row
        headerHeight: 70,
    }

    const onSubmit = async () => {
        const bodyData = {
            financialYear: financialYear,
            sustainableInvestmentData: ESImpact
                ?.filter(ele => Object.keys(ele)?.length > 1)
                ?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            attributesKeyValueData: [
                {
                    serialNo: 2,
                    groupName: 'sustainableSafePractices',
                    key: 'Q7',
                    value: sustainableSourcing
                },
                {
                    serialNo: 2,
                    groupName: 'sustainableSafePractices',
                    key: 'Q8',
                    value: sustainablePercentage
                },
                {
                    serialNo: 2,
                    groupName: 'sustainableSafePractices',
                    key: 'Q9',
                    value: reusableProcess
                },
                {
                    serialNo: 2,
                    groupName: 'sustainableSafePractices',
                    key: 'Q10',
                    value: eprApplicable
                },
            ],
            lifecycleAssessmentData: lcaData
                ?.filter(ele => Object.keys(ele)?.length > 1)
                ?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            riskMitigationData: lcaRisksData
                ?.filter(ele => Object.keys(ele)?.length > 1)
                ?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            recycledMaterialData: reusableProductDetails
                ?.filter(ele => Object.keys(ele)?.length > 1)
                ?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            materialManagementData: productDetails
                ?.filter(ele => Object.keys(ele)?.length > 1)
                ?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            productReclamationData: productPercentage
                ?.filter(ele => Object.keys(ele)?.length > 1)
                ?.map((item, index) => ({ ...item, serialNo: index + 1 }))
        }
        const options = {
            method: 'POST',
            body: bodyData
        }
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
                if (err.status === 200) {
                    setActive(2)
                } else if (err.status === 500) {
                    toast.error('Something went wrong')
                }
            })
    }
    return (
        <Fragment>
            <h3>Sustainable Safe Practices</h3>
            <h4 className="mtop-1">Essential Indicators</h4>
            <div className="mtop-1 company-profile">
                <h5>1. Percentage of R&D and Capex in Environmental/Social Impact Technologies to Total Investments</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={ESImpact}
                        columnData={impactableColumns}
                    />
                </div>
            </div>
            <div className="mtop-1">
                <h5>2. Does the entity have procedures in place for sustainable sourcing? (Yes/No).
                    If yes, what percentage of inputs were sourced sustainably?</h5>
                <div className="d-flex">
                    <div className="col-6">
                        <select className="form-select" value={sustainableSourcing} disabled={mode === 'Edit' ? false : true}
                            onChange={(e) => setSustainableSourcing(e.target.value)}>
                            <option value={''}>-Please select-</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                    <div className="col-6">
                        {
                            sustainableSourcing === 'Yes' && <input className="form-control ml-1" value={sustainablePercentage} disabled={mode === 'Edit' ? false : true}
                                onBlur={(e) => (e.target.value > 100 || e.target.value < 0) ? setSustainablePercentage(0) : setSustainablePercentage(e.target.value)}
                                type="number" onChange={(e) => setSustainablePercentage(e.target.value)} />
                        }
                    </div>
                </div>
            </div>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    3. Describe the processes in place to safely reclaim your products for reusing, recycling and
                    disposing at the end of life, for (a) Plastics (including packaging) (b) E-waste (c) Hazardous waste and (d) other waste.
                </h5>
                <textarea className="form-control ml-1" value={reusableProcess} rows={3} disabled={mode === 'Edit' ? false : true}
                    onChange={(e) => setReusableProcess(e.target.value)} />
            </div>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">4. Whether Extended Producer Responsibility (EPR) is applicable to the entity’s activities (Yes / No).
                    If yes, whether the waste collection plan is in line with the Extended Producer Responsibility (EPR)
                    plan submitted to Pollution Control Boards? If not, provide steps taken to address the same.</h5>
                <textarea className="form-control ml-1" value={eprApplicable} rows={3} disabled={mode === 'Edit' ? false : true}
                    onChange={(e) => setEprApplicable(e.target.value)} />
            </div>
            <h4 className="mtop-1">Leadership Indicators</h4>
            <div className="mtop-1 company-profile">
                <h5>1. Details of Life Cycle Assessments (LCA) Conducted for Products/Services</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={lcaData}
                        columnData={lcaColumns}
                    />
                    <CustomFooter handleAdd={() => setLcaData([...lcaData, { serialNo: lcaData?.length + 1 }])} />
                </div>
            </div>
            <div className="mtop-1 company-profile">
                <h5>2. Summary of Significant Social/Environmental Risks Identified in LCA or Other Assessments, and Mitigation Actions Taken</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    {console.log(lcaRisksData)}
                    <AgGridDatasheet
                        tableData={lcaRisksData}
                        columnData={lcaRiskColumns}
                    />
                    <CustomFooter handleAdd={() => setLcaRisks([...lcaRisksData, { serialNo: lcaRisksData?.length + 1 }])} />
                </div>
            </div>
            <div className="company-profile">
                <div className="mtop-1 ag-group-header">
                    <h5>3. Percentage of Recycled/Reused Input Material to Total Material Used in Production/Services</h5>
                    <div className="recylce">
                        <div style={{ width: '100%' }} className="ag-datasheet">
                            <AgGridDatasheet
                                tableData={reusableProductDetails}
                                columnData={reusablePercentageColumns}
                                gridOptions={gridOptions}
                            />
                            <CustomFooter handleAdd={() => setReusableProduct([...reusableProductDetails, { serialNo: reusableProductDetails?.length + 1 }])} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="company-profile">
                <div className="mtop-1 ag-group-header">
                    <h5>4. Amount of Products and Packaging Reclaimed at End of Life: Reused, Recycled, and Safely Disposed (in Metric Tonnes)</h5>
                    <div className="ngrbc-review">
                        <div style={{ width: '100%' }} className="ag-datasheet">
                            <AgGridDatasheet
                                tableData={productDetails}
                                columnData={materialColumns}
                                gridOptions={gridOptions}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mtop-1 company-profile">
                <h5>5. Percentage of Reclaimed Products and Packaging Materials for Each Product Category</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={productPercentage}
                        columnData={productPercentageColumns}
                    />
                    <CustomFooter handleAdd={() => setProductPercentage([...productPercentage, { serialNo: productPercentage?.length + 1 }])} />
                </div>
            </div>
            <div className="d-flex mtop-1" >
                <Button color="primary" disabled={(!financialYear || (mode !== 'Edit')) ? true : false} className="ml-1" onClick={onSubmit}>Next</Button>
            </div>
        </Fragment>
    )
}

export default SustainableSafePractices;