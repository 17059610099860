import React, { Fragment, useEffect, useState } from "react";
import { CardBody, Container, Row, Col, Card, Label, Button } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import FontPicker from 'font-picker-react';
import { ColorPicker, useColor } from 'react-color-palette';
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";
import { hexToColorObject } from "../../../api/helper";

const ReportSettings = () => {
    const [fontFamily, setfontFamily] = useState('Trebuchet MS');
    const [fontSize, setfontSize] = useState(12);
    const [textColor, setTextColor] = useColor('');
    const [titleColor, settitleColor] = useColor('');
    const [titleColorPalateOpen, setTitlePalateOpen] = useState(false);
    const [textColorPalateOpen, setTextPalateOpen] = useState(false);
    const fontSizeArray = [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72];
    const [orgData, setOrgData] = useState({});

    const getOrgAttributes = async () => {
        await fetchWrapper(`${API_URL}/orgattributes`)
            .then((res) => {
                console.log(res.json)
                setOrgData(res.json)
                setfontFamily(res.json?.fontFamily ? res.json?.fontFamily : 'Trebuchet MS');
                setfontSize(res.json?.fontSize);
                setTextColor(hexToColorObject(res.json?.fontColor));
                settitleColor(hexToColorObject(res.json?.headerColor))
            }, (err) => {
                console.log(err.json)
            })
    }

    const handleFontFamily = (font) => {
        setfontFamily(font.family);
    }

    useEffect(() => {
        getOrgAttributes()
    }, [])

    const handleSave = async () => {
        console.log(titleColor?.hex.split('#'))
        const bodyData = {
            ...orgData,
            fontFamily: fontFamily,
            fontSize: fontSize,
            headerColor: titleColor?.hex.split('#')[1],
            fontColor: textColor?.hex.split('#')[1]
        }
        const options = {
            method: 'POST',
            body: bodyData
        }
        await fetchWrapper(`${API_URL}/orgattributes`, options)
            .then((res) => {
                console.log(res)
                if (res.status === 201) {
                    toast.success('Report settings saved successfully!')
                }
            }, (err) => {
                if (err.status === 500) {
                    toast.error('Something went wrong')
                }
            })
    }

    return (
        <Fragment>
            <Container fluid >
                <Row>
                    <Col lg='10'>
                        <Breadcrumbs mainTitle={'Report Settings'} />
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg='6'>
                                        <div>
                                            <Label>Font Family</Label>
                                            <FontPicker
                                                apiKey="AIzaSyAw_tVJSbGcx0QtQeLJMoeVUF3rZ4t7Ex0"
                                                activeFontFamily={fontFamily}
                                                onChange={(font) => handleFontFamily(font)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg='6'>
                                        <div>
                                            <Label>Font Size</Label>
                                            <select className="form-select font-size-dropdown" value={fontSize} onChange={(e) => setfontSize(e.target.value)}>
                                                {
                                                    fontSizeArray?.map((size) => (<option value={size}>{size}</option>))
                                                }
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mtop-1">
                                    <Col lg='6'>
                                        <Label>Title Color</Label>
                                        <input type="text" className="form-control" style={{ display: 'inline-block', width: '25%', marginLeft: '1rem' }}
                                            value={titleColor?.hex} onClick={() => setTitlePalateOpen(true)} />
                                        {
                                            titleColorPalateOpen && <ColorPicker
                                                // onChangeComplete={() => setTitlePalateOpen(false)}
                                                color={titleColor} onChange={settitleColor}
                                            />
                                        }
                                    </Col>
                                    <Col lg='6'>
                                        <Label>Text Color</Label>
                                        <input type="text" className="form-control"
                                            style={{ display: 'inline-block', width: '25%', marginLeft: '1rem' }}
                                            value={textColor?.hex} onClick={() => setTextPalateOpen(true)} />
                                        {
                                            textColorPalateOpen && <ColorPicker
                                                // onChangeComplete={() => setTextPalateOpen(false)}
                                                color={textColor} onChange={setTextColor}
                                            />
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Button color="primary" onClick={handleSave}>Submit</Button>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default ReportSettings;