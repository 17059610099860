import { List } from "reactstrap"

export const ghgTopics = [
    {
        query: 'What is the GHG platform?',
        ans: 'The GHG platform is a comprehensive tool designed to help businesses and organizations track, manage, and reduce their greenhouse gas emissions.'
    },
    {
        query: 'Who can use the GHG platform?',
        ans: 'The platform is suitable for companies, non-profits, government agencies, and any organization interested in monitoring and reducing their carbon footprint.'
    },
    {
        query: 'How do I sign up for the GHG platform?',
        ans: 'You can sign up by visiting our website and clicking on the "Sign Up" button. Follow the prompts to create an account.'
    }
]

export const ghgGeneral = [
    {
        query: 'What is a carbon footprint?',
        ans: 'A carbon footprint is the total amount of GHGs emitted directly or indirectly by an individual, organization, event, or product.'
    }

]


export const Net0TracePlatform = [
    {
        query: 'What is the Net0Trace platform?',
        ans: 'The Net0Trace platform is a comprehensive solution designed to help organizations measure, track, and manage their greenhouse gas (GHG) emissions. It supports businesses in achieving their sustainability goals by providing tools for emissions tracking, reporting, and reduction planning. The platform aligns with international standards for carbon accounting and net-zero commitments.GHG emissions are gases released into the atmosphere that trap heat and contribute to global warming. Common GHGs include carbon dioxide (CO2), methane (CH4), and nitrous oxide (N2O).'
    },
    {
        query: 'Who can use the Net0Trace platform?',
        ans: 'The Net0Trace platform can be used by organizations of all sizes, including corporations, small businesses, government entities, and non-profits. It is particularly useful for companies looking to monitor their carbon footprint, comply with sustainability regulations, and work toward achieving net-zero emissions.'
    },
    {
        query: 'Why is tracking emissions with Net0Trace important?',
        ans: 'Tracking emissions with Net0Trace is important because it helps organizations:',
        list: [
            'Understand their carbon footprint and identify key emission sources.',
            'Meet regulatory requirements and global sustainability standards.',
            'Demonstrate accountability and transparency to stakeholders.',
            'Make data-driven decisions to achieve net zero goals.',
            'Enhance their brand reputation by showcasing environmental responsibility.'

        ],
        isListObjectPaired: false
    },
    {
        query: 'What are the key features of the Net0Trace platform?',
        ans: 'The key features of the Net0Trace platform include:',
        list: [
            'Emissions Tracking- Monitors Scope 1, Scope 2, and Scope 3 emissions across all operational activities.',
            'Customizable Dashboards- Visualizes emissions data with user-friendly charts and insights tailored to organizational needs.',
            'Compliance Support- Aligns with global standards and frameworks such as GHG Protocol, Science-Based Targets initiative (SBTi), and Carbon Disclosure Project (CDP).',
            'Reduction Planning- Provides actionable recommendations and strategies to lower emissions.',
            'Action Plan Creation- Allows users to design and implement their own customized action plans to address specific emission sources.',
            'Target Setting- Facilitates the setting of ambitious yet achievable sustainability targets based on accurate emissions data and organizational goals.',
        ],
        isListObjectPaired: true
    },
]

export const groups = [
    {
        query: 'What does "Group" mean in Net0Trace?',
        ans: 'A "Group" in Net0Trace refers to a collection or category that organizes data or entities, such as business units, locations, or specific operational areas, under a single identifier for streamlined emissions tracking and management.'
    },
    {
        query: 'What are the benefits of creating a group?',
        ans: 'Creating a group allows for better organization and management of data. It helps segregate emissions by specific units, locations, or operations, making it easier to track, analyse, and report on targeted areas within an organization.'
    },
    {
        query: 'How do I create a group?',
        ans: "To create a new group:",
        list:[
            'Click on the "Add Group" button.',
            'Enter the group name, group code (if applicable), and a brief description of the group.',
            'Save the details to complete the group creation process.'
        ]
    },
    {
        query: 'How can I edit a group name?',
        ans: "To edit a group name:",
        list: [
            'Click on the "Edit" icon next to the group.',
            'Make the necessary changes to the group name or other details.',
            'Save the changes to update the group information.'
        ]
    },
    {
        query: 'How can I delete a group?',
        ans: 'To delete a group:',
        list: [
            'Click on the "Delete" icon next to the group.',
            'Confirm the deletion when prompted.',
            'Verify that the group has been successfully removed from the list.'
        ]
    },
    {
        query: 'Is there a limit to the number of groups I can create?',
        ans: 'No, there is no limit to the number of groups you can create on the Net0Trace platform. You can add as many groups as necessary to meet your organizational needs.'
    },
    {
        query:'Can I create a group with the same name more than once?',
        ans:'No, duplicate group names are not allowed. Each group name must be unique to avoid confusion and ensure accurate data tracking.'
    }

]

export const locations = [
    {
        query: 'What does "Location" mean in Net0Trace?',
        ans: 'A "Location" in Net0Trace represents a specific geographical or operational site within an organization where emissions data is tracked. This could include offices, factories, warehouses, or any other physical site.'
    },
    {
        query: 'What are the benefits of creating a location?',
        ans: "Creating a location helps organize and segregate emissions data by specific sites. This enables organizations to:",
        list: [
            'Analyse emissions data more accurately.',
            'Identify high-emission areas or locations.',
            'Implement targeted reduction strategies for specific sites.'
        ],
        isListObjectPaired: false
    },
    {
        query: 'How do I create a location?',
        ans: 'To create a location:',
        list: [
            'Navigate to the "Locations" section of the platform.',
            'Click on "Add Location".',
            'Enter the required information, including location name, type, and address details.',
            'Save the details by clicking "Add."'
        ],
        isListObjectPaired: false
    },
    {
        query: 'What information do I need to provide when creating a location?',
        ans: "When creating a location, you need to provide the following information:",
        list: [
            'Group Name- Select from the dropdown list.',
            'Location Type- Choose from the respective dropdown options.',
            'Location Name- Enter a unique name for the location.',
            'Address Details- Provide the address, city, zip code, and state (selectable from dropdowns).',
            'Country- Select the country from the dropdown menu.'
        ],
        isListObjectPaired: true,
    },
    {
        query: 'Can I edit a location after it has been created?',
        ans: "Yes, you can edit a location. To do so:",
        list: [
            'Click on the "Edit" icon next to the location.',
            'Make the necessary changes.',
            'Save the updates to apply the changes.'
        ],
        isListObjectPaired: false
    },
    {
        query: ' How do I delete a location?',
        ans: 'To delete a location:',
        list: [
            'Go to the "Locations" section.',
            'Select the location you want to delete.',
            'Click on the "Delete" button.',
            'Confirm the deletion when prompted.'
        ],
        isListObjectPaired: false
    },
    {
        query: 'How do locations help in analysing emissions data?',
        ans: 'Locations provide insights into emissions at specific sites, enabling you to:',
        list: [
            'Identify areas with the highest emissions.',
            'Pinpoint specific sources of emissions within a location.',
            'Implement targeted strategies for emissions reduction.',
            'Compare emissions across multiple locations for better decision-making.'
        ],
        isListObjectPaired: false
    }

]

export const dataCapture = [
    {
        query: 'What is data capture in a GHG platform?',
        ans: 'Data capture in a GHG platform refers to the process of collecting and entering data related to greenhouse gas emissions from various activities within an organization.'
    },
    {
        query: 'Why is data capture important?',
        ans: 'Accurate data capture is essential for tracking the amount of greenhouse gases emitted, analysing trends, complying with environmental regulations, and implementing strategies to reduce emissions.'
    },
    {
        query: 'What details are covered in the Data Capture section?',
        ans: "The Data Capture section includes the location, period, year, and employee count. Additionally, it provides a summary table of the organization's emissions, which covers Scope 1, 2, and 3 categories."
    },
    {
        query: 'How should I enter the basic details on the Data Capture main page?',
        ans: 'Select the location from the dropdown, select the year from the dropdown for which you are entering the data, and enter the employee count for the selected location.'
    },
    {
        query: "Why can't I select the period from the dropdown, and why is it frozen?",
        ans: 'The period is frozen and automatically populated based on the based on the GHG Reporting Frequency provided in the customer onboarding form. '
    },
]

export const carbonCredits = [
    {
        query: 'What is a carbon credit?',
        ans: 'A carbon credit is a permit that allows a company or organization to emit a certain amount of carbon dioxide or other greenhouse gases. One carbon credit equals one metric ton of CO2 or the equivalent amount of a different greenhouse gas.'
    },
    {
        query: 'What is the Green Carbon Wallet?',
        ans: 'The Green Carbon Wallet is a digital platform provided by AIROI where users can manage their carbon credits. It allows users to buy and sell  carbon credits, providing a secure and transparent way to track their carbon offsetting activities.'
    },
    {
        query: 'What types of carbon offset projects does our platform support?',
        ans: 'Our Platform supports a variety of verified carbon offset projects, including reforestation, renewable energy, energy efficiency, sustainable agriculture, Plastic waste and so on. Each project is vetted to ensure it meets rigorous environmental standards.'
    },
    {
        query: 'How are the carbon credits verified?',
        ans: 'All carbon credits available on the Green Carbon Wallet platform are verified by national and international recognized standards such as Verified Carbon Standard (VCS) , Gold Standard, Plastic Credit Exchange (PCX), Universal Carbon Registry (UCR) and so on. This ensures that the emission reductions are real, measurable, and permanent.'
    },
    {
        query: 'Can I trade carbon credits on the Green Carbon Wallet platform?',
        ans: 'Yes, the platform includes a marketplace where users can buy and sell carbon credits. This enables flexibility in managing carbon portfolios and supports a dynamic market for carbon offsets.'
    }
]

export const projects = [
    {
        query: 'How do I create a new project?',
        ans: "This process initiates a new project. To create a new project, fill in all mandatory fields required in the form. It's crucial to provide accurate details as these will be displayed on the project list section of the homepage. Once all details are entered, click submit."
    },
    {
        query: 'What can I see on the project list page?',
        ans: 'You can view the list of projects that your organization has created. This page displays columns such as project name, status, UN SDGs, project type, country, and actions.'
    },
    {
        query: 'What does the status column indicate?',
        ans: 'The status column shows the current state of your created projects. Hovering over the icon reveals whether a project is active, submitted, or in another state.'
    },
    {
        query: 'What does "project type" mean?',
        ans: 'Project type refers to the category under which the project was created. The same type name is displayed on this page. '
    },
    {
        query: 'What do UN SDGs mean?',
        ans: 'UN SDGs stand for United Nations Sustainable Development Goals. These are a set of 17 global goals adopted by all UN Member States in 2015 as part of the 2030 Agenda for Sustainable Development. The SDGs are designed to address various global challenges, including poverty, inequality, climate change, environmental degradation, peace, and justice. Each goal has specific targets to be achieved by 2030, aiming to create a more sustainable and equitable world for all. Below are the 17 goals along with their descriptions: ',
        list: [
            'No Poverty - End poverty in all its forms everywhere. ',
            'Zero Hunger - End hunger, achieve food security and improved nutrition, and promote sustainable agriculture. ',
            'Good Health and Well-being - Ensure healthy lives and promote well-being for all at all ages. ',
            'Quality Education - Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all.',
            'Gender Equality - Achieve gender equality and empower all women and girls.',
            'Clean Water and Sanitation - Ensure availability and sustainable management of water and sanitation for all. ',
            'Affordable and Clean Energy - Ensure access to affordable, reliable, sustainable, and modern energy for all.',
            'Decent Work and Economic Growth - Promote sustained, inclusive, and sustainable economic growth, full and productive employment, and decent work for all. ',
            'Industry, Innovation, and Infrastructure - Build resilient infrastructure, promote inclusive and sustainable industrialization, and foster innovation. ',
            'Reduced Inequalities - Reduce inequality within and among countries',
            'Sustainable Cities and Communities - Make cities and human settlements inclusive, safe, resilient, and sustainable. ',
            'Responsible Consumption and Production - Ensure sustainable consumption and production patterns.',
            'Climate Action - Take urgent action to combat climate change and its impacts. ',
            'Life Below Water - Conserve and sustainably use the oceans, seas, and marine resources for sustainable development.',
            'Life On Land - Protect, restore, and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, halt and reverse land degradation, and halt biodiversity loss.',
            'Peace, Justice, and Strong Institutions - Promote peaceful and inclusive societies for sustainable development, provide access to justice for all, and build effective, accountable, and inclusive institutions at all levels. ',
            'Partnerships for the Goals - Strengthen the means of implementation and revitalize the Global Partnership for Sustainable Development. ',
        ]
    },
    {
        query: 'What does the "actions" column signify? ',
        ans: 'The actions column allows you to view the project status and edit the details of the project you created. By clicking the eye icon, you can view the profile, details, status, notes, documents, and baseline calculations specific to that project type. Clicking the pen icon allows you to edit the project details. Once the project reaches active status, you can no longer edit the project details. If the project is still in draft mode, you can delete the project before submitting.'
    }
];

export const existingProject = [
    {
        query: 'What is the purpose of the existing project feature?',
        ans: 'This feature is used to onboard existing projects onto our platform.'
    },
    {
        query: 'What details need to be filled out?',
        ans: "It's necessary to complete all mandatory fields in the form. After all sections are filled out, the user can click submit."
    }
]

export const wallet = [
    {
        query: 'What does this page tell me about?',
        ans: 'This page provides an overview of the project name, pending credit value, certified credit value, and pending sale settlement value. It helps you gain a detailed understanding of the credits associated with your projects. If you click the trading button, it will take you to the trading section where you can explore this area in depth.'
    }
];

export const trading = [
    {
        query: 'How can I trade with my credits?',
        ans: 'You can initiate trading by clicking the trading button, which opens a new window displaying the monetary value of your credits. If you wish to sell , you can proceed by clicking the appropriate button and following the subsequent steps. Fill in all the details and click submit.'
    },
    {
        query: 'What does the Orders section deal with?',
        ans: 'This section provides a view of the projects you are planning to sell. It includes order ID, order date, project name, quantity, monetary value, status, and an option to view more details.'
    },
    {
        query: 'How can I view the project status?',
        ans: 'Click on the eye icon to be taken to the next page where you can see basic details and view the transaction. Only the system admin has the authority to sell orders. If the status is "Filled," it means that your order is sold. If the status is "Open," it means that the order is processing. If the status is "New," it means that you are ready to sell the credits.'
    }
];

export const general = [
    {
        query: 'What is the Green Carbon Wallet and What Do We Provide to You?',
        ans: 'At AIROI, we have developed a ‘Green-Carbon’ Wallet, which serves as both a marketplace for buyers to purchase carbon or plastic credits to offset their emission levels, and a platform for sellers to trade their carbon or plastic credits. This Green-Carbon Wallet helps you measure your carbon footprint, calculate the number of credits or offsets you have, and connect with potential buyers to sell or offset your credits.'
    },
    {
        query: 'What can I do with the carbon credits section in this platform?',
        ans: 'In the carbon credits section, you can: ',
        list: [
            'View Project Details - Access detailed information about your carbon credit projects.',
            'Track UN SDG Goals - Identify and monitor the UN Sustainable Development Goals applicable to your projects.',
            'Monitor Project Status - Follow a step-by-step status update for each project to stay informed of their progress.',
            'Manage Carbon Credits - Check the number of carbon credits you own and their current status.'
        ],
        isListObjectPaired: true
    },
    {
        query: 'What are the features of our platform?',
        ans: 'Our platform offers a comprehensive suite of features designed to help you manage and reduce your carbon footprint: ',
        list: [
            'GHG Emission Tracking - Monitor and visualize your greenhouse gas emissions through interactive dashboards.',
            'Emission Reduction Planning - Set and manage your action plans to achieve net zero goals.',
            'Carbon Credit Management - View, track, and manage your carbon credits, including project details and status updates.',
            'Scope Applicability - Customize your data entry by selecting relevant categories to streamline your workflow.',
            ' Air Quality Index (AQI) Monitoring - Access real-time air quality data to ensure compliance with standards and identify improvement areas.',
            'UN SDG Integration - Align your projects with relevant UN Sustainable Development Goals and track their progress.'
        ],
        isListObjectPaired:true
    },
    {
        query: ' What are the benefits of the Net0Trace?',
        ans: 'The Net0Trace section of our platform provides several key benefits:',
        list: [
            'Comprehensive Tracking- Monitor your greenhouse gas emissions across various scopes and categories.',
            'Visualization Tools- Use interactive dashboards to visualize your emission data and trends.',
            'Reduction Planning- Set action plans for emission reduction and track your progress towards net zero goals.',
            'Scope Customization- Select applicable categories to streamline data entry and focus on relevant information.',
            'Set Group and Location- Organize and manage your emissions data by different groups and locations, allowing for more precise tracking and analysis.',
            'Comparing Your Actual vs Target Data- Easily compare your current emissions data against your target goals to identify areas for improvement and measure your progress towards achieving those goals.'
        ],
        isListObjectPaired: true
    },
    {
        query: 'What is Net0Trace?',
        ans: 'Net0Trace is a system or platform designed to help organizations measure, track, and manage their greenhouse gas (GHG) emissions to achieve net-zero emissions. It enables transparency in emissions reporting and supports sustainability goals by providing insights and analytics for effective carbon reduction strategies.',

    },
    {
        query: 'Why is Net0Trace important?',
        ans: 'Net0Trace is important because it helps organizations understand their carbon footprint, set reduction targets, and comply with environmental regulations. It ensures accountability and enables organizations to make informed decisions to reduce emissions. By tracking progress toward net-zero goals, it contributes to mitigating climate change and enhances a company’s reputation in sustainability.',
    },
    {
        query: 'How is Net0Trace measured?',
        ans: 'Net0Trace measures emissions by collecting data from various sources within an organization, such as energy consumption, transportation, and waste management. The data is then converted into carbon dioxide equivalent (CO₂e) emissions using internationally recognized standards and emission factors. The platform provides detailed reports and visual dashboards to analyse emission trends.',
    },
    {
        query: 'How can I reduce my emissions using Net0Trace?',
        ans: 'You can reduce your emissions using Net0Trace by identifying key sources of emissions within your operations, setting realistic reduction targets, and implementing strategies such as:',
        list: [
            'Transitioning to renewable energy sources.',
            'Improving energy efficiency in buildings and equipment.',
            'Encouraging sustainable transportation options like carpooling or EVs.',
            'Reducing waste and increasing recycling.',
            'Collaborating with suppliers to lower upstream emissions.'
        ],
        isListObjectPaired: false
    },
    {
        query: 'What is the benefit of using the AQI section in our platform?',
        ans: 'The AQI section provides real-time air quality data, helping you monitor and manage air quality levels. This feature can be particularly useful for identifying areas where improvements are needed and for ensuring compliance with air quality standards. It helps you take proactive measures to improve air quality and ensure a healthier environment. '
    },
    {
        query: 'What is the benefit of using the decarbonization analysis section in our platform?',
        ans: 'The decarbonization analysis section allows you to conduct in-depth analyses to identify opportunities for reducing carbon emissions. It provides insights and recommendations for implementing effective decarbonization strategies. This helps you to not only meet regulatory requirements but also to enhance your sustainability initiatives and move closer to achieving your net zero goals.'
    }
]


const objtemp = {
    query: '',
    ans: ''
}