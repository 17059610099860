import React, { useState, Fragment, useEffect, useCallback, useRef } from "react";
import { Container, Row, Col, Card, CardBody, Badge, Label, Button, FormGroup, Spinner } from 'reactstrap';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { defaultUserType, thousandSeparator } from '../../api/helper';
import { useNavigate } from "react-router-dom";
import AgGridTable from "../AgGridTable";
import { Breadcrumbs } from '../../../AbstractElements';
import ButtonLoader from "../ButtonLoader";
import { toast } from 'react-toastify';
import { exportToPDF } from "../ExportToPdf/pdfHelper";
import LayoutLoader from "../LayoutLoader/LayoutLoader";


const ActionPlan = () => {
    const [tableData, setTableData] = useState([]);
    const actionRef = useRef(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isUpdatePlan, setUpdatePlan] = useState(false)
    // pdfMake.vfs = pdfFonts.pdfMake.vfs;


    const getActionPlan = async () => {
        setLoading(true)
        actionRef?.current?.api?.showLoadingOverlay();
        await fetchWrapper(`${API_URL}/actionPlan`)
            .then((res) => {
                actionRef?.current?.api?.hideOverlay();
                setTableData(res.json)
                setLoading(false)
            })
            .catch((err) => {
                actionRef?.current.api.hideOverlay();
                if (err.status === 404) {
                    actionRef?.current?.api?.showNoRowsOverlay();
                }
                setLoading(false)
                console.log(err)
            });
    }

    const columnData = [
        {
            headerName: '#',
            field: 'id',
            filter: false,
            width: 30,
            sortable: true,
            suppressMovable: true,
            valueGetter: (params) => {
                return params.node?.rowIndex + 1;
            }
        },
        {
            headerName: 'Action Item',
            field: 'category',
            filter: true,
            flex: 1.7,
            suppressMovable: true,
            wrapText: true,
            cellClass: 'category-field',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Scope',
            field: 'scope',
            filter: false,
            flex: 0.7,
            sortable: true,
            suppressMovable: true,
        },
        {
            headerName: 'Base Year',
            field: 'baseYear',
            filter: false,
            flex: 0.9,
            sortable: true,
            suppressMovable: true,
        },
        {
            headerName: 'Target Year',
            field: 'targetYear',
            filter: false,
            flex: 0.8,
            suppressMovable: true,
            sortable: true,
        },
        {
            headerName: 'Year',
            field: 'year',
            filter: false,
            flex: 0.6,
            suppressMovable: true,
            sortable: true,
        },
        {
            headerName: 'Base Value (tCO2e)',
            field: 'value',
            filter: false,
            flex: 1,
            valueGetter: (params) => {
                return thousandSeparator(params.data.value)
            },
            suppressMovable: true,
            sortable: true,
            cellClass: 'text-right pr-30'
        },
        {
            headerName: 'Revised Value (tCO2e)',
            field: 'revisedValue',
            filter: false,
            flex: 1.2,
            suppressMovable: true,
            sortable: true,
            valueGetter: (params) => {
                return params.data.updatedValue ? thousandSeparator(Number(params.data.updatedValue)?.toFixed(2)) : '--'
            },
            cellClass: 'text-right pr-30'
        },
        {
            headerName: 'Reduction (tCO2e)',
            field: 'reduction',
            filter: false,
            flex: 1,
            suppressMovable: true,
            sortable: true,
            valueGetter: (params) => {
                let differ = params.data.value - (params.data.updatedValue ? params.data.updatedValue : 0)
                return differ < 0 ? thousandSeparator(Number(Math.abs(differ)?.toFixed(2))) : thousandSeparator(Number(differ)?.toFixed(2))
            },
            cellClass: 'text-right pr-30'
        },
        {
            headerName: 'Reduction Percentage',
            field: 'reductionPercentage',
            filter: false,
            flex: 1,
            suppressMovable: true,
            sortable: true,
            valueGetter: (params) => {
                let differ = params.data.value - (params.data.updatedValue ? params.data.updatedValue : 0)
                let val = differ < 0 ? Math.abs(Number(differ)?.toFixed(2)) : Number(differ)?.toFixed(2)
                return params.data.value === 0 ? '--' : `${((val / params.data.value) * 100).toFixed(2)} %`
            },
        },
        {
            headerName: 'Plan',
            field: 'plan',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            singleClickEdit: true,
            cellEditorPopup: false,
            flex: 2.8,
            cellEditorParams: {
                maxLength: 200,
                row: 3,
                col: 50
            },
            wrapText: true,
            cellClass: 'text-input-field',
            suppressMovable: true
        }
    ];

    // const onGridReady = useCallback(() => {
    //     getActionPlan()
    // })

    const getGridData = () => {
        if (actionRef.current) {
            const gridApi = actionRef.current.api;
            const data = gridApi.getModel().rowsToDisplay.map((rowNode) => {
                return {
                    'id': rowNode.data.id,
                    'plan': rowNode.data.plan
                }
            });
            return data?.filter(ele => ele?.plan);
        }
        return [];
    }

    const onSubmit = async () => {
        const bodyData = {
            actionplan: getGridData()
        }
        let options = {
            method: 'POST',
            body: bodyData
        }
        await fetchWrapper(`${API_URL}/actionPlan`, options)
            .then((res) => {
                console.log(res.json);
                toast.success('Action Plan saved successfully!')
                getActionPlan()
            }, (err) => {
                console.log(err)
                if (err?.status === 200) {
                    toast.success('Action Plan saved successfully!')
                    // getActionPlan()
                }
            })
    }

    useEffect(() => {
        getActionPlan()
    }, [])

    const exportPdf = () => {
        const gridApi = actionRef.current.api;
        exportToPDF(gridApi);
    }

    const onCellValueChanged = (event) => {
        setUpdatePlan(true)
        // console.log(event)
    }

    return (
        <Fragment>
            {/* <LayoutLoader value={loading} /> */}
            <Row>
                <Col lg='12'>
                    <Container fluid={true}>
                        <div className="d-flex mtop-1">
                            <Breadcrumbs mainTitle="Action Plan for GHG Target Reduction" />
                            <Button className="minus-button" color="primary" onClick={exportPdf}>Download</Button>
                        </div>
                        {/* <div><p className="mb-0">[Note: Base Value, Revised Value and Reduction are in tCO2e ]</p></div> */}
                        <Card className="project_data">
                            <CardBody className='data-tables responsive-view ptop-0'>
                                <div className='action-plan-data'>
                                    <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                        <AgGridTable
                                            columnData={columnData}
                                            tableData={tableData}
                                            agRef={actionRef}
                                            // onGridReady={onGridReady}
                                            rowHeight={90}
                                            onCellValueChanged={onCellValueChanged}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        <div className='d-flex mtop-2'>
                            {
                                loading ? <ButtonLoader color={'primary'} loaderText={'Submitting'} /> :
                                    <Button color='primary'
                                        className={`${isUpdatePlan ? '' : 'disabled-button'}`}
                                        onClick={onSubmit}>Submit</Button>
                            }
                        </div>
                    </Container>
                </Col>
            </Row>
        </Fragment>
    )
}

export default ActionPlan;