import React, { Fragment, useState, useEffect, useRef } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { H3 } from "../../../../AbstractElements";
import { X } from "react-feather";
import { API_URL } from "../../../Config/Config";
import fetchWrapper from "../../../api/fetchWrapper";

const ViewReport = ({ value, setOpenModal, data }) => {
    const [open, setOpen] = useState(value);
    const onClose = () => {
        setOpen(false);
        setOpenModal(false)
    }
    let container = useRef(null)
    const options = {
        // breakPages: true,
        renderHeaders: true, //enables headers rendering
        renderFooters: true, //enables footers rendering
        renderFootnotes: true, //enables footnotes rendering
        renderEndnotes: true,
        ignoreHeadersAndFooters: false,
        inWrapper: true,
        ignoreWidth: true,
        // ignoreHeight: true,
        ignoreFonts: false,
        breakPages: false,         // Handle page breaks as actual breaks
        trimXmlDeclaration: true,  // Removes XML declaration (if any) in output
        experimental: true
    }

    const onError = (e) => {
        console.error('Error in file viewer:', e);
    };
    //require('../../../../assets/files/report.docx')

    const cleanUpHtml = (html) => {
        // Remove multiple line breaks
        return html.replace(/(<br>\s*){2,}/g, '<br>');
    };

    const initDocx = async () => {
        const requestBody = {
            reportType: data?.reportType,
            financialYear: data?.financialYear
        }
        const options = {
            method: 'POST',
            body: requestBody
        }
        await fetchWrapper(`${API_URL}/api/docx/getFileInBytes`, options)
            .then(response => response.blob())
            .then(blob => {
                // docx.renderAsync(
                //     blob,
                //     container.current
                // )
                //     .then(x => console.log("docx: finished"));
            })
            .catch(error => console.error('Error fetching the file:', error));
    }

    useEffect(() => {
        initDocx()
    }, [])

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='xl'>
                <ModalHeader style={{ display: 'flex' }}>
                    <H3 attrH3={{ className: 'modal-title-color' }}>View
                        <X className="x-button" onClick={onClose} />
                    </H3>
                </ModalHeader>
                <ModalBody>
                    <div ref={container}></div>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default ViewReport; 