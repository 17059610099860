import React, { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import { Row, Col, Card, CardBody, Badge, Label, Button, FormGroup, Spinner } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { defaultUserType, thousandSeparator } from '../../api/helper';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import ButtonLoader from '../ButtonLoader';
import { FiPaperclip } from "react-icons/fi";

let totalUnits = 0;

const DailyHydroMonitoring = ({ projectId, frequency, year, month, userType, projectName, type, vendor, 
    setRowData, setEvidenceUploadOpen }) => {
    const gridRef = useRef(null)
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const [totalCollection, setTotal] = useState(0);
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState({
        id: 'total',
        period: 'Total (tCO2e)',
        unitsGenerated: totalUnits
    });
    const [loading, setLoading] = useState(false);
    
    
    const handleEvidenceModal = (data) => {
        setRowData(data);
        setEvidenceUploadOpen(true);
    }

    const columnData = [
        {
            headerName: 'Period',
            field: 'period',
            valueGetter: (params) => {
                if (params.node?.rowPinned !== 'bottom') {
                    return frequency === 'DAILY' ? moment(params.data.date).format('DD MMM, YYYY') : params.data.id ? params.data.period.split(',')[0] : params.data.period
                } else {
                    return 'Total (Metric tons)'
                }
            },
            flex: 1.5,
            suppressMovable: true
        },
        {
            headerName: 'Units Generated per day(MWh)',
            field: 'unitsGenerated',
            editable: (params) => {
                return (!params.data.id)
            },
            cellEditor: 'agNumberCellEditor',
            singleClickEdit: true,
            cellEditorPopup: false,
            cellEditorParams: {
                min: 0,
                precision: 2,
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.unitsGenerated ?
                    thousandSeparator(params?.data?.unitsGenerated.toFixed(2)) : ''}</div>;
            },
            cellClass: (params) => {
                return params.data.id ? 'disable-field text-input-field' : 'text-input-field'
            },
            flex: 2,
            suppressMovable: true
        },
        {
            headerName: 'Operating hours per day',
            field: 'operatingHours',
            editable: (params) => {
                return (!params.data.id)
            },
            cellEditor: 'agNumberCellEditor',
            singleClickEdit: true,
            cellEditorPopup: false,
            cellEditorParams: {
                min: 0,
                precision: 2,
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.operatingHours ?
                    params?.data?.operatingHours.toFixed(2) : ''}</div>;
            },
            cellClass: (params) => {
                return params.data.id ? 'disable-field text-input-field' : 'text-input-field'
            },
            flex: 2,
            suppressMovable: true
        },
        {
            headerName: 'Evidence',
            flex: 2.5,
            cellRenderer: (params) => {
                return <div className='ml-1-5' >
                    <FiPaperclip onClick={() => handleEvidenceModal(params.data)} />
                    <div></div>
                </div>
            }
        }
    ]

    const getCollectionList = async (monthValue) => {
        gridRef?.current?.api?.showLoadingOverlay();
        let total = 0;
        let apiUrl = (frequency === 'DAILY' && !vendor) ?
            `hydromonitoring/project/${projectId}/${frequency}/${parseInt(moment(year).format('YYYY'))}/${moment(monthValue).format('MMMM')}`
            : (frequency === 'MONTHLY' && !vendor) ? `hydromonitoring/project/${projectId}/${frequency}/${parseInt(moment(year).format('YYYY'))}`
                : (frequency === 'DAILY' && vendor) ? `hydromonitoring/project/${projectId}/vendor/${vendor}/${frequency}/${parseInt(moment(year).format('YYYY'))}/${moment(monthValue).format('MMMM')}`
                    : (frequency === 'MONTHLY' && vendor) ? `hydromonitoring/project/${projectId}/vendor/${vendor}/${frequency}/${parseInt(moment(year).format('YYYY'))}` : '';
        await fetchWrapper(`${API_URL}/${apiUrl}`)
            .then((res) => {
                if (res.status === 200) {
                    gridRef?.current?.api?.hideOverlay();
                    const data = res.json;
                    const dateArray = loadDateArray(new Date(year).getFullYear(), moment(monthValue).format('M'));
                    const formatArray = dateArray?.map((item) => {
                        let filterItems, findItem;
                        if (frequency === 'DAILY') {
                            filterItems = data.filter((ele) => moment(ele.date).format('DD MMM, YYYY') === item.date);
                            if (filterItems?.length > 1) {
                                let unitsGenerated = 0;
                                findItem = filterItems[0];
                                filterItems?.map((item) => {
                                    unitsGenerated = unitsGenerated + item?.unitsGenerated;
                                })
                                findItem.unitsGenerated = unitsGenerated;
                            } else {
                                findItem = filterItems[0];
                            }
                            
                        } else if (frequency === 'MONTHLY') {
                            filterItems = data.filter((ele) => ele.period === item.period);
                            if (filterItems?.length > 1) {
                                let unitsGenerated = 0;
                                findItem = filterItems[0];
                                filterItems?.map((item) => {
                                    unitsGenerated = unitsGenerated + item?.unitsGenerated;
                                })
                                findItem.unitsGenerated = unitsGenerated;
                            } else {
                                findItem = filterItems[0];
                            }
                        }
                        return { ...item, ...findItem }
                    })
                    formatArray?.filter(ele => ele?.unitsGenerated)?.map((i) => {
                        total = total + Number(i.unitsGenerated)
                    })
                    setTotal(total)
                    totalUnits = total;
                    setPinnedBottomRowData({
                        id: 'total',
                        period: "Total (tCO2e)",
                        unitsGenerated: total
                    });
                    setTableData(formatArray)
                }
            }, (err) => {
                console.log(err)
                gridRef?.current?.api?.hideOverlay();
                if (err.status === 404) {
                    const dateArray = loadDateArray(new Date(year).getFullYear(), moment(monthValue).format('M'));
                    setTableData(dateArray)
                }


            })
    }

    const getGridData = () => {
        if (gridRef.current) {
            const gridApi = gridRef.current.api;
            const data = gridApi.getModel().rowsToDisplay
                .filter(rowNode => (rowNode.data.unitsGenerated && rowNode.data.operatingHours && !rowNode.data.id))
                .map((rowNode) => {
                        return {
                            'date': /^\d{4}-\d{2}-\d{2}$/.test(rowNode.data.date) ? rowNode.data.date : moment(rowNode.data.date, 'DD MMM, YYYY').format('YYYY-MM-DD'),
                            'unitsGenerated': rowNode.data.unitsGenerated ? rowNode.data.unitsGenerated : 0,
                            'operatingHours': rowNode.data.operatingHours ? rowNode.data.operatingHours : 0
                        }
                });
            return data;
        }
        return [];
    }


    const loadDateArray = (year, month) => {
        if (frequency === 'DAILY') {
            let endDate;
            const startDate = new Date(year, month - 1, 1);
            if (month === moment(new Date()).format('M')) {
                endDate = new Date(year, month - 1, moment(new Date()).format('DD'));
            } else {
                endDate = new Date(year, month, 0)
            }
            let dateArray = [];
            let currentDate = startDate;
            while (currentDate <= endDate) {
                dateArray.push({ "date": moment(new Date(currentDate)).format('DD MMM, YYYY') });
                currentDate.setDate(currentDate.getDate() + 1);
            }
            return dateArray;
        } else if (frequency === 'MONTHLY') {
            let array = moment.months();
            const monthsArray = array.map((item) => {
                return {
                    period: item
                }
            })
            return [...monthsArray];
        }

    }

    useEffect(() => {
        getCollectionList(month)
    }, [month])

    useEffect(() => {
        getCollectionList(month)
    }, [vendor, year])

    const onSubmit = async () => {
        setLoading(true)
        let collectionList = getGridData();
        let bodyData;
        if (frequency === 'DAILY') {
            bodyData = {
                projectId: projectId,
                frequency: frequency,
                vendorId: vendor ? vendor : null,
                period: moment(month).format('MMMM'),
                year: Number(moment(year).format('YYYY')),
                category: type?.toUpperCase(),
                monitoringData: [...collectionList]
            }
        } else if (frequency === 'MONTHLY') {
            bodyData = {
                projectId: projectId,
                frequency: frequency,
                vendorId: vendor ? vendor : null,
                year: Number(moment(year).format('YYYY')),
                category: type?.toUpperCase(),
                monitoringData: [...collectionList]
            }
        }
        const options = {
            method: "POST",
            body: bodyData
        }
        
        await fetchWrapper(`${API_URL}/hydromonitoring`, options)
            .then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    toast.success('Monitoring data submitted successfully!')
                    totalUnits = 0;
                    setTotal(0);
                    getCollectionList();
                } else {
                    toast.error('Something went wrong')
                }
            }, async(err) => {
                console.log(err)
                setLoading(false)
                if (err.status === 200) {
                    toast.success('Monitoring data submitted successfully!')
                    totalUnits = 0;
                    setTotal(0);
                    getCollectionList();
                } else {
                    toast.error('Something went wrong')
                }
            })
    }

    const onCellValueChanged = (event) => {
        let total = totalUnits;
        const newValue = event.newValue; // The new value of the edited cell
        const columnId = event.column.getId(); // The column ID of the edited cell
        if (columnId === 'unitsGenerated') {
            total = total + newValue;
            totalUnits = total;
            setTotal(total);
        }
        setPinnedBottomRowData({
            id: 'total',
            period: "Total",
            unitsGenerated: total,
        });
    };

    const handleHistory = () => {
        navigate(`${process.env.PUBLIC_URL}/monitoring-history/${projectId}/${projectName}/${type}`)
    }

    const onGridReady = useCallback(async (params) => {
        getCollectionList(month);
    })

    const handleBack = () => {
        navigate(`${process.env.PUBLIC_URL}/projects`)
    }

    return (
        <Fragment>
            <Row>
                <Col lg='12'>
                    <div className='waste-collection'>
                        <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                            <AgGridReact
                                columnDefs={columnData}
                                rowData={tableData}
                                ref={gridRef}
                                rowModelType='clientSide'
                                domLayout='autoHeight'
                                animateRows={true}
                                getRowStyle={() => {
                                    return {
                                        background: '#fafafa'
                                    }
                                }}
                                overlayLoadingTemplate={
                                    `<div class="custom-loading-overlay">
                                                    <i class="fa fa-spinner fa-spin"></i> <span class='f-15'>Please wait while your data are loading...</span>
                                                </div>`
                                }
                                overlayNoRowsTemplate={
                                    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">This is a custom \'no rows\' overlay</span>'
                                }
                                pagination={frequency === 'DAILY' ? true : false}
                                paginationPageSize={10}
                                onCellValueChanged={onCellValueChanged}
                                paginationPageSizeSelector={false}
                                pinnedBottomRowData={[pinnedBottomRowData]}
                                enterNavigatesVerticallyAfterEdit={true}
                                stopEditingWhenCellsLoseFocus={true}
                                onGridReady={onGridReady}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <div className='d-flex mtop-1'>
                {
                    loading ? <ButtonLoader color={'primary'} loaderText={'Submitting'} /> : <Button color='primary' onClick={onSubmit}>Submit</Button>
                }
                <Button color='secondary' className='ml-1' onClick={handleBack}>Back</Button>
                <Button color='primary' className='trans-button' onClick={handleHistory}>History View/Update</Button>
            </div>
        </Fragment>
    )
}

export default DailyHydroMonitoring;