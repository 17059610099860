import React, { Fragment, useEffect, useState } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import { Nav, Tab, Row, Col, Tabs, Accordion, Card, FormGroup } from "react-bootstrap";
import { CardBody, Container } from "reactstrap";
import SubOrganizationList from "../SubOrganization/SubOrganizationList";
import ParentOrgDetails from "./ParentOrgDetails";
import { useSelector } from "react-redux";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";

const OwnershipMgmt = () => {
    const userData = useSelector(state => state?.user?.userDetails)
    const [activeKey, setActiveKey] = useState(userData?.parentOrgId ? 1 : 2);
    const [parentOrgDetails, setParentOrgDetails] = useState([]);

    const checkParentOrg = async() => {
        await fetchWrapper(`${API_URL}/ownership/getChildRecordsForAction`)
        .then((res) => {
            console.log(res?.json)
            if(res?.json?.length > 0) {
                setActiveKey(1)
                setParentOrgDetails(res?.json)
            }
        }, (err) => {
            if(err?.status === 404) {
                setActiveKey(2)
            }
        })
    }

    useEffect(() => {
        checkParentOrg()
    }, [])

    return (
        <Fragment>
            <Container fluid>
                <Breadcrumbs mainTitle={'Ownership Mgmt'} />
                <Card>
                    <CardBody>
                        <Tab.Container activeKey={activeKey} defaultActiveKey={'1'} onSelect={(key) => setActiveKey(key)}>
                            {
                                parentOrgDetails?.length > 0 && <Nav variant="underlines">
                                    <Nav.Item className="mr-5px">
                                        <Nav.Link eventKey={'1'} className={activeKey == 1 ? 'application-active' : ''}>Parent</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="mr-5px">
                                        <Nav.Link eventKey={'2'} className={activeKey == 2 ? 'application-active' : ''}>Child</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            }
                            <Tab.Content>
                                <Tab.Pane eventKey={'1'}>
                                    <ParentOrgDetails />
                                </Tab.Pane>
                                <Tab.Pane eventKey={'2'}>
                                    <SubOrganizationList />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    )
}

export default OwnershipMgmt;