import React, { Fragment, useState } from "react";
import { CardBody, CardFooter, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { X } from "react-feather";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useSelector } from 'react-redux';
import ButtonLoader from "../ButtonLoader";

const EditMonitoringModal = ({ data, value, setOpenModal, setUpdate, type }) => {
    const [open, setOpen] = useState(value);
    const [loading, setLoading] = useState(false);
    const [newQuantity, setNewQuantity] = useState(data?.quantity);
    const [rigidQuantity, setRigidQuantity] = useState(data?.rigidQuantity);
    const [compositeQuantity, setCompositeQuantity] = useState(data?.compositeQuantity);
    const [flexibleQuantity, setFlexibleQuantity] = useState(data?.flexibleQuantity);
    const [unitsQuantity, setUnitQuantity] = useState(data?.unitsGenerated);
    const [operatingHours, setOperatingHours] = useState(data?.operatingHours);
    const navigate = useNavigate();
    const projectData = useSelector(state => state?.projectDetails?.detailsData);

    const onClose = () => {
        setOpen(false);
        setOpenModal(false);
    }

    const onSubmit = async () => {
        setLoading(true)
        let bodyData;
        if (type === 'plastic') {
            bodyData = {
                rigidQuantity: parseFloat(rigidQuantity),
                compositeQuantity: parseFloat(compositeQuantity),
                flexibleQuantity: parseFloat(flexibleQuantity),
                totalQuantity: parseFloat(rigidQuantity) + parseFloat(compositeQuantity) + parseFloat(flexibleQuantity)
            }
        } else if(type === 'hydro'){
            bodyData = {
                ...data,
                unitsGenerated: unitsQuantity,
                operatingHours: operatingHours
            }
        } else {
            bodyData = {
                quantity: newQuantity,
            }
        }

        const options = {
            method: 'PUT',
            body: bodyData
        }
        let targetUrl = type === 'plastic' ? `vendormonitoring/${type}` : type === 'hydro' ? 'hydromonitoring' : 'projectmonitoring' ;
        await fetchWrapper(`${API_URL}/${targetUrl}/${data.id}`, options)
            .then((res) => {
                setLoading(false);
                if (res.status === 200) {
                    setUpdate(true)
                    toast.success('Consumption Updated Successfully!')
                    onClose()
                } else {
                    toast.error('Something went wrong')
                }
            }, (err) => {
                setLoading(false);
                if (err.status === 200) {
                    setUpdate(true)
                    toast.success('Consumption Updated Successfully!')
                    onClose()
                } else {
                    toast.error('Something went wrong')
                }
            })
    }

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size="lg">
                <ModalHeader>
                    <H3 attrH3={{ className: 'modal-title-color' }}>Edit Monitoring Data</H3>
                    <X className="x-button" onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg={6}>
                            <div style={{ marginBottom: "35px" }}>
                                <FormGroup>
                                    <label>Data Collection Frequency</label>
                                    <input className={`form-control`} type="text"
                                        name='dataCollectionFrequency' value={data?.frequency} disabled />
                                </FormGroup>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div style={{ marginBottom: "35px" }}>
                                <FormGroup>
                                    <label>Period</label>
                                    <input className={`form-control`} type="text"
                                        name='period' value={data?.period} disabled />
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>

                    {
                        type === 'solar' && <Row>
                            <Col lg={6}>
                                <div style={{ marginBottom: "35px" }}>
                                    <FormGroup>
                                        <label>Value</label>
                                        <input className={`form-control`} type="number"
                                            name='unitType' value={newQuantity} onChange={(e) => setNewQuantity(e.target.value)} />
                                    </FormGroup>
                                </div>
                            </Col>
                        </Row>
                    }
                    {
                        type === 'plastic' && <Row>
                            <Col lg={6}>
                                <div style={{ marginBottom: "35px" }}>
                                    <FormGroup>
                                        <label>Rigid</label>
                                        <input className={`form-control`} type="number"
                                            name='unitType' value={parseFloat(rigidQuantity)} onChange={(e) => setRigidQuantity(e.target.value)} />
                                    </FormGroup>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div style={{ marginBottom: "35px" }}>
                                    <FormGroup>
                                        <label>Flexible</label>
                                        <input className={`form-control`} type="number"
                                            name='unitType' value={parseFloat(flexibleQuantity)} onChange={(e) => setFlexibleQuantity(e.target.value)} />
                                    </FormGroup>
                                </div>
                            </Col>
                        </Row>
                    }
                    {
                        type === 'plastic' && <Row>
                            <Col lg={6}>
                                <div style={{ marginBottom: "35px" }}>
                                    <FormGroup>
                                        <label>Composite</label>
                                        <input className={`form-control`} type="number"
                                            name='unitType' value={parseFloat(compositeQuantity)} onChange={(e) => setCompositeQuantity(e.target.value)} />
                                    </FormGroup>
                                </div>
                            </Col>
                        </Row>
                    }
                    {
                        type === 'hydro' && <Row>
                            <Col lg={6}>
                                <div style={{ marginBottom: "35px" }}>
                                    <FormGroup>
                                        <label>Units Genarated(MWh)</label>
                                        <input className={`form-control`} type="number"
                                            name='unitType' value={unitsQuantity} onChange={(e) => setUnitQuantity(e.target.value)} />
                                    </FormGroup>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div style={{ marginBottom: "35px" }}>
                                    <FormGroup>
                                        <label>Value</label>
                                        <input className={`form-control`} type="number"
                                            name='unitType' value={operatingHours} onChange={(e) => setOperatingHours(e.target.value)} />
                                    </FormGroup>
                                </div>
                            </Col>
                        </Row>
                    }
                    <div className="d-flex">
                        {
                            loading ? <ButtonLoader loaderText={'Updating'} color={'primary'} /> : <Button color="primary" onClick={onSubmit} >Update</Button>
                        }

                        <Button color="secondary" className="ml-1" onClick={onClose}>Cancel</Button>
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default EditMonitoringModal;