import React, { Fragment, useState, useEffect } from "react";
import { CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Button
} from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { toast } from "react-toastify";
import MapComponent from "../MarkerMap/MapComponent";
import ButtonLoader from "../ButtonLoader";

const EvStationForm = ({ title, action, navigateUrl, cancel, preData, projectId, setAdd }) => {
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [countryList, setCountryList] = useState();
    const [stateList, setStateList] = useState();
    const { register, handleSubmit, formState: { errors }, reset, getValues } = useForm({ defaultValues: preData });
    const navigate = useNavigate();
    const [countryId, setCountryId] = useState(preData?.countryId);
    const [stateId, setStateId] = useState(preData?.stateId);
    const [monitoringLevel, setMonitoringLevel] = useState();
    const [zipcode, setZipcode] = useState();
    const [address, setAddress] = useState();
    const [vendorId, setVendorId] = useState();
    const [vendorList, setVendorList] = useState([]);
    const [markerPosition, setMarkerPosition] = useState({
        lat: 37.7749,
        lng: -122.4194,
    });

    const mapStyles = {
        width: '100%',
        height: '400px'
    }

    
    useEffect(() => {
        console.log(markerPosition)
    }, [markerPosition])

    const loadCountry = async () => {
        await fetchWrapper(`${API_URL}/country`)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    setCountryList(res.json)
                }
            }, (err) => {
                console.log(err)
            })
    }
    const getAllVendors = async () => {
        await fetchWrapper(`${API_URL}/vendor/project/${projectId}`)
            .then((res) => {
                console.log(res.json)
                setVendorList(res.json);
            }, (err) => {
                console.log(err)
            })
    }

    const handleAddress = () => {
        const stateData = stateList?.filter(ele => ele?.id == getValues('stateId'))
        const countryData = countryList?.find(ele => ele?.id == getValues('countryId'));
        setAddress(`${getValues('addressLine1')},${getValues('addressLine2')},${getValues('city')},
        ${stateData?.name},${countryData?.name}`)
    }

    const handleCountry = async (id) => {
        setCountryId(id)
        handleAddress()
        await fetchWrapper(`${API_URL}/state/country/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    setStateList(res.json)
                }
            }, (err) => {
                console.log(err)
            })
    }

    const loadProjectData = async () => {
        await fetchWrapper(`${API_URL}/projects/${projectId}`)
            .then((res) => {
                const data = res.json;
                setMonitoringLevel(data?.monitoringLevel);
            }, (err) => console.log(err))
    }

    useEffect(() => {
        loadCountry()
        console.log(projectId)
        getAllVendors()
    }, [projectId])
    

    const onSubmit = async (tempData) => {
        setLoading(true)
        tempData.projectId = projectId;
        tempData.geoCoordinates = `${markerPosition.lat},${markerPosition.lng}`;
        tempData.geoLocation = `https://www.google.com/maps?q=${markerPosition.lat},${markerPosition.lng}`
        let url, message;
        let method = action === "Add" ? 'POST' : 'PUT';
        if (action === 'Add') {
            url = `${API_URL}/evstation`
            message = 'EV charger Created Successfully!'
        }
        if (action === 'Edit') {
            url = `${API_URL}/evstation/${tempData.id}`
            message = 'EV charger Updated Successfully!'
        }
        const options = {
            method: method,
            body: tempData
        }

        await fetchWrapper(url, options)
            .then((res) => {
                if (res.ok) {
                    toast.success(message)
                    setLoading(false)
                    setAdd(true)
                    action === 'Add' && cancel()
                    action === 'Edit' && navigate(navigateUrl)
                }
            })
            .catch((err) => {
                setLoading(false)
                if (err.status == 409) {
                    toast.error('Location Already Exist..')
                } else if (err.status === 500) {
                    toast.error('Something went wrong')
                }
            })
    }

    const handleGoeCoordinates = (val) => {
        let geoData = val.split(',');
        setMarkerPosition({
            lat: Number(geoData[0]),
            lng: Number(geoData[1])
        })
    }

    const handleState = (val) => {
        setStateId(val)
        console.log(countryId, val)
        let countryData = countryList?.filter(ele => ele?.id == countryId);
        let stateData = stateList?.filter(ele => ele?.id == val);
        console.log(countryData, stateData);
        handleAddress()
    }

    useEffect(() => {
        setData(prev => ({ ...prev, ...preData }))
        handleCountry(preData?.countryId)
        setStateId(preData?.stateId)
        loadProjectData();
        setVendorId(preData?.vendorId)
        if (preData?.geocoordinates) {
            handleGoeCoordinates(preData?.geocoordinates)
        }
        reset(preData)
    }, [preData])

    return (
        <Fragment>
            <Row>
                <Col lg='12'>
                    {title && <H3 attrH3={{ className: 'mtop-2' }}>{title}</H3>}
                    <Card className={`${action === 'Add' && 'mb-0'} ${action === 'Edit' && ''}`}>
                        <CardBody className={action === 'Add' && 'p-0 bs-none'}>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup >
                                            <Label>Station ID </Label>
                                            <input
                                                className="form-control"
                                                name="stationId"
                                                placeholder=""
                                                type="text"
                                                defaultValue={preData?.stationId}
                                                {...register('stationId')}
                                            />
                                            <span className="text-danger">{errors.stationId?.type === 'required' && 'Station ID is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup >
                                            <Label>Station Name <span className='required'>*</span></Label>
                                            <input
                                                className="form-control"
                                                name="name"
                                                placeholder=""
                                                type="text"
                                                defaultValue={preData?.name}
                                                {...register('name', { required: true })}
                                            />
                                            <span className="text-danger">{errors?.name && 'Station Name is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={'6'}>
                                        <FormGroup>
                                            <Label>Vendor</Label>
                                            <select className='form-select' name='vendor' value={vendorId}
                                                onChange={(e) => setVendorId(e.target.value)}
                                                {...register('vendorId')}>
                                                <option value={''}>-Select vendor-</option>
                                                {
                                                    vendorList?.filter(ele => ele?.status === 'ACTIVE')?.map((item) => (
                                                        <option value={item?.id}>{item?.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup >
                                            <Label >Charging Unit Capacity (KW) <span className='required'>*</span></Label>
                                            <input
                                                className="form-control"
                                                name="chargingUnitCapacity"
                                                placeholder=""
                                                type="text"
                                                defaultValue={preData?.chargingUnitCapacity}
                                                {...register('chargingUnitCapacity', { required: true })}
                                            />
                                            <span className="text-danger">{errors?.chargingUnitCapacity && 'Charging Unit Capacity is Required'}</span>
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row>

                                    <Col lg="6">
                                        <FormGroup >
                                            <Label >Address Line 1 <span className="text-danger">*</span></Label>
                                            <input
                                                className="form-control"
                                                name="addressLine1"
                                                placeholder=""
                                                type="text"
                                                defaultValue={preData?.addressLine1}
                                                {...register('addressLine1', { required: true })}
                                                onChange={handleAddress}
                                            />
                                            <span className="text-danger">{errors.addressLine1 && 'Address is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup >
                                            <Label >Address Line 2</Label>
                                            <input
                                                className="form-control"
                                                name="addressLine2"
                                                placeholder=""
                                                type="text"
                                                defaultValue={preData?.addressLine2}
                                                {...register('addressLine2')}
                                                onChange={handleAddress}
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup >
                                            <Label>City <span className='required'>*</span> </Label>
                                            <input
                                                className="form-control"
                                                name="city"
                                                placeholder=""
                                                type="text"
                                                required
                                                defaultValue={preData?.city}
                                                {...register('city', { required: true })}
                                                onChange={handleAddress}
                                            />
                                            <span className="text-danger">{errors.city && 'City is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup >
                                            <Label>Zipcode <span className='required'>*</span> </Label>
                                            <input
                                                className="form-control"
                                                name="zipCode"
                                                placeholder=""
                                                type="text"
                                                required
                                                defaultValue={preData?.zipCode}
                                                {...register('zipCode', { required: true, maxLength: 8, minLength: 5 })}
                                                onChange={(e) => setZipcode(e.target.value)}
                                            />
                                            <span className="text-danger">{errors.zipCode?.type === 'required' && 'zipCode is Required'}</span>
                                            <span className="text-danger">{errors.zipCode?.type === 'maxLength' && 'zipCode should be less than 8 characters'}</span>
                                            <span className="text-danger">{errors.zipCode?.type === 'minLength' && 'zipCode should be more than 3 characters'}</span>
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>Country <span className='required'>*</span></Label>
                                            <select className="form-select" name="country" value={countryId}
                                                {...register('countryId', { required: true })}
                                                onChange={(e) => handleCountry(e.target.value)}
                                            >
                                                <option value="">-Select Country-</option>
                                                {
                                                    countryList?.length > 0 && countryList.map((item) => {
                                                        return (
                                                            <option value={item.id}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span className="text-danger">{errors.country && 'Country is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>State <span className='required'>*</span></Label>
                                            <select className="form-select" name="state"
                                                value={stateId}
                                                {...register('stateId', { required: true })}
                                                onChange={(e) => handleState(e.target.value)}
                                            >
                                                <option value="">-Select State-</option>
                                                {
                                                    stateList?.length > 0 && stateList?.map((i) => {
                                                        return <option value={i.id}>{i.name}</option>
                                                    })
                                                }
                                            </select>
                                            <span className="text-danger">{errors.state && 'State is Required'}</span>
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <MapComponent position={markerPosition} setMarker={setMarkerPosition} zipcode={address} styles={mapStyles} />
                                <div className="d-flex " style={{ marginTop: "25px" }}>
                                    {
                                        loading ? <ButtonLoader color="primary" loaderText={'Submitting'} /> : <Button color="primary" type="submit">{action === "Add" ? action : 'Update'}</Button>
                                    }
                                    <Button color="secondary" className="ml-1" onClick={() => action === 'Add' ? cancel() : navigate(navigateUrl)}> Cancel</Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default EvStationForm;