import React, { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { toast } from 'react-toastify';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import { useSelector } from 'react-redux';
import AgGridTable from '../AgGridTable';
import { capitalize, thousandSeparator } from '../../api/helper';
import moment from 'moment';

const ParentHistory = () => {
    const [open, setOpen] = useState(false);
    const [showFilter, setShowFilter] = useState(false)
    const [orgList, setOrgList] = useState([])
    const [loading, setLoading] = useState(false);
    const [addStatus, setAddStatus] = useState(false);
    const userType = useSelector(state => state?.user?.userType);
    const navigate = useNavigate();

    const orgRef = useRef(null);

    const defaultOrgType = {
        "ASSET_OWNER": 'Asset Owner',
        "ASSESSOR": 'Assessor',
        "ASSET_MANAGER": 'Asset Manager'
    }

    const loadData = async () => {
        orgRef?.current?.api?.showLoadingOverlay();
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/ownership/history/getAllRecords`, options)
            .then(res => {
                console.log(res)
                orgRef?.current?.api?.hideOverlay();
                setOrgList(res.json)
            })
            .catch((err) => {
                orgRef?.current?.api?.hideOverlay();
                console.log(err)
            })
    }

    const handleStatus = async (id, status) => {
        let updateStatus = status === true ? false : true;
        const options = {
            method: 'PUT'
        }
        await fetchWrapper(`${API_URL}/organization/status/${id}/${updateStatus}`, options)
            .then((res) => {
                if (res.status === 200) {
                    if (res?.json?.isactive === false) {
                        toast.error('User has been disabled!')
                    } else {
                        toast.success('User has been enabled!')
                    }
                    loadData()
                }
            })
            .catch(err => console.log(err))
    }

    const columnData = [
        {
            headerName: 'Company Name',
            field: 'companyName',
            filter: true,
            flex: 1.5,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap'
        },
        {
            headerName: 'Country',
            field: 'country',
            flex: 1,
            sortable: false,
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap'
        },
        {
            headerName: 'Control Type',
            field: 'controlType',
            filter: false,
            flex: 0.7,
            sortable: false,
            suppressMovable: true,
            valueGetter: (params) => {
                return capitalize(params.data.controlType);
            }
        },
        {
            headerName: 'Control Percentage (%) (%)',
            field: 'controlTypePercentage',
            filter: false,
            flex: 1,
            sortable: false,
            suppressMovable: true,
            cellRenderer: (params) => {
                return <div className="text-right w-150px">{params?.data?.controlTypePercentage ?
                    thousandSeparator(params?.data?.controlTypePercentage.toFixed(2)) : ''}</div>;
            },
        },
        {
            headerName: 'Effective Date',
            field: 'effectiveDate',
            flex: 0.7,
            valueGetter: (params) => {
                return moment(params.data?.effectiveDate).format('DD MMM, YYYY');
            },
        },
        {
            headerName: 'End Date',
            field: 'endDate',
            flex: 0.7,
            cellRenderer: (params) => {
                return (
                    (params.data.endDate) ? <div>{moment(params.data?.endDate).format('DD MMM, YYYY')}</div> : (params.data.id && !params.data.endDate) ? <div className='active-bg d-flex'>Active</div> : ''
                )
            }
        },
        {
            headerName: 'Updated On',
            field: 'lastModifiedTS',
            filter: false,
            flex: 1,
            sortable: false,
            suppressMovable: true,
            valueGetter: (params) => {
                return moment(params.data?.lastModifiedTS).format('DD MMM, YYYY hh:MM A');
            },
        },
        {
            headerName: 'Updated By',
            field: 'userName',
            filter: false,
            flex: 1,
            sortable: false,
            suppressMovable: true,
        },

    ];

    const onGridReady = useCallback(async (params) => {
        loadData()
    })

    useEffect(() => {
        if (addStatus === true) {
            loadData()
        }
    }, [addStatus])

    const handleBack = () => {
        navigate(`${process.env.PUBLIC_URL}/ownership-management`)
    }
    return (
        <Fragment>
            <LayoutLoader />
            <Container fluid={true}>
                <Breadcrumbs mainTitle={`History`} />
                <Row>
                    <Col sm="12">
                        <Card className=" newtable">
                            <CardBody className='data-tables responsive-view ptop-0'>
                                <div className='exchange-table'>
                                    <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                        <AgGridTable
                                            columnData={columnData}
                                            tableData={orgList}
                                            agRef={orgRef}
                                            onGridReady={onGridReady}
                                        />
                                    </div>
                                </div>
                                <div className='mtop-1'>
                                    <Button color='secondary' onClick={handleBack}>Back</Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default ParentHistory;