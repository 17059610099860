import React, { Fragment, useEffect, useState, useRef, useMemo } from "react";
import AgGridDatasheet from "./AgGridDatasheet";
import { H3 } from "../../../AbstractElements";
import { defaultUserType, thousandSeparator } from '../../api/helper';
import { Plus, Trash2, XCircle } from "react-feather";
import { toast } from "react-toastify";
import { DataExtractModal, uuidGenerator } from "./ghgHelper";
import { useSelector } from "react-redux";
import moment from "moment";
import { alertFunction, notifyFunction, storeData, deleteCacheData, getCacheData } from "./ghgHelper";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { PiFileArrowDownDuotone } from "react-icons/pi";
import { LuFileCog } from "react-icons/lu";

let error = false;
const Electricity = ({ setSteps, preData, setElectricity, electricity, timeStamp, locationDetails, requestId, setRequestId, mode }) => {
    const [data, setData] = useState([{
        id: 1, isInsert: false, sourceDescription: locationDetails?.locationType,
        sourceArea: Number(locationDetails?.area), isUpdate: false, isDelete: false, isSubmit: false
    }]);
    const [noOfRow, setNoOfRow] = useState(1);
    const [loading, setLoading] = useState(false);
    const [rowIndex, setRowIndex] = useState([]);
    const electricityRef = useRef();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const userProfile = useSelector(state => state?.user?.userDetails);
    const [cacheData, setCacheData] = useState();
    const [sourceTypeList, setSourceTypeList] = useState([]);
    const [sourceTypeData, setSourceTypeData] = useState();
    const [extractedData, setExtractedData] = useState();
    const [open, setOpen] = useState(false);
    const [confirmationStatus, setConfirmationStatus] = useState(false);
    const [indexNode, setNodeIndex] = useState();
    const [dataExtracted, setDataExtract] = useState(false);

    const keyObject = {
        customerId: userProfile?.org,
        userId: userProfile?.id,
        ...preData
    }

    const populateTable = (currentData) => {
        let count = currentData?.length < 10 ? (10 - currentData?.length) : 0;
        let tempData = [...currentData];
        setData(tempData)
    }

    const loadData = (savedData, cTimestamp) => {
        if (electricity?.length > 0 && (savedData?.length === 0 || !savedData)) {
            populateTable(electricity)
        } else if (electricity?.length === 0 && savedData?.length > 0) {
            populateTable(savedData)
        } else if (electricity?.length > 0 && savedData?.length > 0) {
            if (moment(cTimestamp).isAfter(moment(timeStamp))) { //cache is latest
                alertFunction(savedData, electricity, populateTable)
            } else { //saved is latest
                notifyFunction(electricity, populateTable)
            }
        }
    }

    const getSourceData = async () => {
        await fetchWrapper(`${API_URL}/emissionunits/Electricity/${preData?.countryCode}`)
            .then((res) => {
                console.log(res.json)
                setSourceTypeData(res.json)
                setSourceTypeList(res?.json?.map(ele => ele?.source));
            }, (err) => console.log(err))
    }

    const getCache = async () => {
        let cacheData = await getCacheData(keyObject);
        setCacheData(cacheData)
        loadData(cacheData?.energyData, cacheData?.modifiedTs)
    }

    useEffect(() => {
        getSourceData()
        getCache()
    }, [])

    const handleClear = async (params) => {
        const updatedRowData = data?.flatMap((row, index) => {
            if (row.id === params.data.id) {
                if (row.isSubmit === true) {
                    return [{
                        ...row,
                        sourceDescription: locationDetails?.locationType,
                        sourceArea: Number(locationDetails?.area),
                        isDelete: true,
                        isInsert: false,
                        isUpdate: false,
                        isSubmit: true
                    }, {
                        id: index + 1,
                        sourceDescription: locationDetails?.locationType,
                        sourceArea: Number(locationDetails?.area),
                        isInsert: false,
                        isUpdate: false,
                        isDelete: false,
                        isSubmit: false
                    }];
                } else if (row.isSubmit === false) {
                    return {
                        id: index + 1,
                        sourceDescription: locationDetails?.locationType,
                        sourceArea: Number(locationDetails?.area),
                        isInsert: false,
                        isUpdate: false,
                        isDelete: false,
                        isSubmit: false
                    };
                }
            }
            return row;
        });
        console.log(updatedRowData)
        const tempData = {
            ...cacheData,
            requestId: requestId,
            energyData: updatedRowData,
            modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
        }
        await deleteCacheData(keyObject, tempData)
        setData(updatedRowData);
    };

    const handleUpload = async (e, params, nodeIndex) => {
        setLoading(true)
        let dataId;
        if (typeof params?.id === 'string') {
            dataId = params.id;
        } else {
            let id = uuidGenerator();
            dataId = id;
            data[nodeIndex].id = id;
        }
        let tempData = new FormData();
        tempData.append('file', e.target.files[0]);
        const options = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem('accessToken')}`
            },
            body: tempData
        }
        await fetchWrapper(`${API_URL}/document/uploadEvidences?category=Electricity&uploadPeriod=${preData?.uploadPeriod}&categoryDetailId=${dataId}&year=${preData?.year}&month=${preData?.month}&quarter=${preData?.quarter}&requestId=${requestId ? requestId : ''}&locationId=${preData?.locationId}`, options)
            .then((res) => {
                setLoading(false)
                console.log(res?.json)
                let tempData = [...data];
                tempData[nodeIndex].documentId = res?.json?.id;
                tempData[nodeIndex].uploadedFileName = res?.json?.uploadedFileName;
                setRequestId(res?.json?.requestId)
                tempData[nodeIndex].isInsert = true;
                tempData[nodeIndex].isUpdate = false;
                console.log(tempData);
                setData(tempData)
                createCache(data?.filter(ele => ele?.sourceId))
                toast.success('Evidence uploaded successfully!')
            })
            .catch((err) => {
                console.log(err)
                toast.error('Evidence upload failed!')
                setLoading(false)
            })
    }

    const populateExtractedData = (tmpExtracted, nodeIndex) => {
        let tempData = [...data];
        tempData[nodeIndex].electricityPurchased = Number(tmpExtracted?.find(ele => ele?.uploadFileName === 'Electricity')?.fieldValue);
        setData(tempData);
        setDataExtract(true);
        setExtractedData();
        createCache(data?.filter(ele => ele?.sourceId))
    }

    const handleExtract = async (params, nodeIndex) => {
        setLoading(true)
        console.log(requestId)
        await fetchWrapper(`${API_URL}/document/extract/reqId/${requestId}/docId/${params?.documentId}/rowId/${params.id}`)
            .then((res) => {
                setLoading(false)
                console.log(res.json)
                setDataExtract(true);
                let orderedFields = ['Invoice_Date', 'Company_Name', 'Account_Number', 'Electricity'];
                setExtractedData(orderedFields.map(field => res.json.find(item => item.fieldName === field)))
                setNodeIndex(nodeIndex)
                setOpen(true)
            }, (err) => {
                console.log(err)
                setLoading(false)
            })
    }

    const downloadEvidence = async (paramsData) => {
        await fetchWrapper(`${API_URL}/document/downloadEvidence/${paramsData?.documentId}`)
            .then((err) => {
                console.log(err.json)
                if (err.status === 200) {
                    const link = document.createElement('a');
                    link.href = err?.json?.presignedUrl;
                    link.download = paramsData.uploadFileName;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
                if (err.status === 500) {
                    toast.error('Something went wrong!')
                }
            })
            .catch((err) => {
                console.log(err, 'asdasd')
            })
    }

    useEffect(() => {

    }, [data, confirmationStatus])

    const columnData = [
        {
            headerName: 'Source ID',
            field: 'sourceId',
            valueGetter: (params) => {
                if (params.data.hasOwnProperty('sourceDescription')
                    || params.data.hasOwnProperty('sourceArea')
                    || params.data.hasOwnProperty('electricityPurchased')
                    || params.data.hasOwnProperty('co2Factor')
                    || params.data.hasOwnProperty('ch4Factor')
                    || params.data.hasOwnProperty('n2oFactor')) {
                    params.data.sourceId = `EC-${params.node.rowIndex + 1}`;
                    return params.data.sourceId;
                }
            },
            filter: false,
            flex: 1,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Source Description',
            field: 'sourceDescription',
            editable: false,
            filter: false,
            flex: 2.5,
            autoHeight: true,
            wrapText: true,
            sortable: false,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Source Area (sq ft)',
            field: 'sourceArea',
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 4,
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.sourceArea ? thousandSeparator(params?.data?.sourceArea?.toFixed(4)) : ''}</div>;
            },
            filter: false,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            flex: 1.5
        },
        {
            headerName: 'Electricity Source',
            field: 'electricitySource',
            flex: 2,
            cellRenderer: (params) => {
                const handleDropdown = (value) => {
                    params.data.electricitySource = value;
                }
                return (
                    <div className="w-9">
                        <select className="form-select br-0" value={params.data.electricitySource}
                            onChange={(e) => handleDropdown(e.target.value)}>
                            <option value={''}>- Select -</option>
                            {
                                sourceTypeList?.map((e) => (<option value={e}>{e}</option>))
                            }
                        </select>
                    </div>
                )

            },
            cellClass: 'justify-center pad-electricity',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            sortable: false,
            suppressNavigable: true,
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'Electricity Purchased (kWh)',
            field: 'electricityPurchased',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 4,
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.electricityPurchased ? thousandSeparator(Number(params?.data?.electricityPurchased)?.toFixed(4)) : ''}</div>;
            },
            flex: 2,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
        },
        {
            headerName: 'Market Based',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: 'CO\u2082 Emission Factors (kg/kWh)',
                    field: 'co2Factor',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        precision: 4,
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.co2Factor ? thousandSeparator(params?.data?.co2Factor?.toFixed(4)) : ''}</div>;
                    },
                    flex: 2,
                    editable: mode === 'Edit' ? true : false,
                    cellClass: mode === 'Edit' ? '' : 'disable-cell',
                    headerClass: 'group-header-class',
                },
                {
                    headerName: 'CH\u2084 Emission Factors (g/kWh)',
                    field: 'ch4Factor',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        precision: 4,
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.ch4Factor ? thousandSeparator(params?.data?.ch4Factor?.toFixed(4)) : ''}</div>;
                    },
                    flex: 2,
                    editable: mode === 'Edit' ? true : false,
                    cellClass: mode === 'Edit' ? '' : 'disable-cell',
                    headerClass: 'group-header-class',
                },
                {
                    headerName: 'N\u2082O Emission Factors (g/kWh)',
                    field: 'n2oFactor',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        precision: 4,
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.n2oFactor ? thousandSeparator(params?.data?.n2oFactor?.toFixed(4)) : ''}</div>;
                    },
                    flex: 2,
                    editable: mode === 'Edit' ? true : false,
                    cellClass: mode === 'Edit' ? '' : 'disable-cell',
                    headerClass: 'group-header-class',
                },
            ]
        },
        {
            headerName: 'Evidence',
            flex: 1.5,
            tooltipValueGetter: (params) => {
                if (params.data.uploadedFileName) {
                    return params.data.uploadedFileName;
                }
            },
            cellRenderer: (params) => {
                if (mode === 'Edit') {
                    if (!params.data.uploadedFileName) {
                        return (
                            <div>
                                <label className="upload-file-icon">
                                    <input
                                        type="file" hidden accept=".jpeg, .jpg, .png, .pdf"
                                        onChange={(event) => handleUpload(event, params?.data, params?.node?.rowIndex)}
                                    />
                                    <AiOutlineCloudUpload />
                                </label>
                            </div>
                        );
                    } else {
                        return (
                            <div className="d-flex">
                                <div onClick={() => handleExtract(params.data, params?.node?.rowIndex)}>
                                    <LuFileCog className="file-extract" />
                                </div>
                                <div onClick={() => downloadEvidence(params.data)}>
                                    <PiFileArrowDownDuotone className="file-download ml-1" />
                                </div>
                            </div>

                        )
                    }
                }
            },
            suppressMovable: true,
            cellClass: 'tick-center'
        },
        {
            headerName: 'Clear',
            width: 70,
            cellRenderer: (params) => {
                return (
                    (params.data.sourceId && mode === 'Edit') ?
                        <div>
                            <Trash2 onClick={() => handleClear(params)}
                                className={'trash-icon mtop-0'} />
                        </div> : <></>
                )
            },
            suppressMovable: true,
            cellClass: 'icon-center'
        },
    ]

    const addingRows = (val) => {
        setNoOfRow(val)
    }

    const addRows = () => {
        const rowData = [];
        for (let i = 1; i <= noOfRow; i++) {
            rowData.push({ id: data?.length + i, isInsert: false, isUpdate: false, isDelete: false, isSubmit: false })
        }
        setData([...data, ...rowData]);
    }

    const gridOptions = {
        groupHeaderHeight: 35, // Set the height of the group header row
        headerHeight: 70,
    };

    const getGridData = () => {
        if (electricityRef.current) {
            const tableState = data
                .filter((ele) => ele?.electricitySource || ele?.co2Factor || ele?.ch4Factor || ele?.n2oFactor)
                .map((rowNode, index) => {
                    if (rowNode.electricitySource && rowNode.electricityPurchased) {
                        let data = {
                            id: rowNode.id,
                            sourceId: rowNode.sourceId,
                            sourceDescription: rowNode.sourceDescription,
                            sourceArea: Number(rowNode.sourceArea),
                            electricitySource: rowNode.electricitySource,
                            resourceType: rowNode.resourceType,
                            electricityPurchased: parseFloat(rowNode.electricityPurchased),
                            co2Factor: rowNode.co2Factor ? parseFloat(rowNode.co2Factor) : null,
                            ch4Factor: rowNode.ch4Factor ? parseFloat(rowNode.ch4Factor) : null,
                            n2oFactor: rowNode.n2oFactor ? parseFloat(rowNode.n2oFactor) : null,
                            documentId: rowNode.documentId ? rowNode.documentId : null,
                            isInsert: rowNode.isInsert,
                            isUpdate: rowNode.isUpdate,
                            isDelete: rowNode.isDelete,
                            isSubmit: rowNode.isSubmit
                        }
                        return data;
                    } else {
                        rowIndex.push(rowNode?.sourceId.split('-')[1]);
                        return {}
                    }
                });
            if (tableState?.some(ele => Object.keys(ele)?.length === 0)) {
                error = true;
            } else {
                error = false
            }
            return tableState.filter(ele => Object.keys(ele)?.length > 0);
        }
        return [];
    }

    const onSubmit = async () => {
        const gridData = getGridData();
        if (error === true) {
            toast.error(`Please fill missing values for row ${[...new Set(rowIndex)].join(',')}`)
            setRowIndex([])
            error = false;
            return
        } else {
            setElectricity(gridData)
            setSteps(0)
        }
    }


    const createCache = async (data) => {
        const tempData = {
            ...cacheData,
            requestId: requestId,
            energyData: data?.filter(ele => ele?.sourceId),
            modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
        }
        await storeData(keyObject, tempData)
    }

    const onCellValueChanged = (event) => {
        if (event.value) {
            if (event.data.isSubmit === true && (event.data.isInsert === false || event.data.isUpdate === true)) {
                event.data.isUpdate = true;
                event.data.isInsert = false;
            } else if (event.data.isSubmit === false && event.data.isInsert === false && typeof event.data.id !== 'string') {
                event.data.id = uuidGenerator();
                event.data.isInsert = true;
                event.data.isUpdate = false;
            } else if (event.data.isSubmit === false && event.data.isInsert === false) {
                event.data.isInsert = true;
                event.data.isUpdate = false;
            }
        }
        createCache(data?.filter(ele => ele?.sourceId))
    }
    return (
        <Fragment>
            <div className="d-flex">
                <H3 attrH3={{ className: 'f-s-15' }}>Total Amount of Electricity Purchased</H3>
                <button className="manage-asm-but border-1px-solid"
                    onClick={() => onSubmit()}>Back to Summary</button>
            </div>
            {loading ? <div className="loading-overlay"><i class="fa-solid fa-spinner fa-spin mr-1"></i> Loading....</div> : <></>}
            <div className="mtop-1 ag-group-header" style={{ opacity: loading ? '0.2' : '1' }}>
                <div className="electricity-table">
                    <div style={{ width: '100%' }} className="ag-datasheet">
                        <AgGridDatasheet
                            tableData={data?.filter(ele => ele?.isDelete === false)}
                            columnData={columnData}
                            gridOptions={gridOptions}
                            agRef={electricityRef}
                            onCellValueChanged={onCellValueChanged}
                        />

                    </div>
                </div>

            </div>
            {open && <DataExtractModal value={open} setOpenModal={setOpen} data={extractedData}
                setConfirmation={setConfirmationStatus} populateData={populateExtractedData} nodeIndex={indexNode} />}
        </Fragment>
    )
}

export default Electricity;