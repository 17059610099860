import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Row, Col, Card, CardBody, Badge, Label, Button, Container } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { useNavigate, useParams } from 'react-router-dom';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import AgGridTable from '../AgGridTable';
import moment from 'moment';
import { defaultUserType, thousandSeparator, capitalize } from '../../api/helper';
import { toast } from 'react-toastify';

const ApproveOwnership = () => {
    const orgId = useParams().id;
    const orgName = useParams()?.name;
    const [data, setData] = useState();
    const historyRef = useRef(null);
    const navigate = useNavigate();

    const columnData = [
        {
            headerName: 'Control Type',
            field: 'controlType',
            filter: false,
            editable: false,
            flex: 1,
            sortable: false,
            suppressMovable: true,
            singleClickEdit: true,
            cellEditorPopup: false,
            valueGetter: (params) => {
                return capitalize(params.data.controlType);
            }
        },
        {
            headerName: 'Control Percentage (%)',
            field: 'controlTypePercentage',
            filter: false,
            cellRenderer: (params) => {
                return <div className="text-right w-150px">{params?.data?.controlTypePercentage ?
                    thousandSeparator(params?.data?.controlTypePercentage.toFixed(2)) : ''}</div>;
            },
            flex: 1,
            editable: false,
            sortable: true,
            suppressMovable: true,
            singleClickEdit: true,
            cellEditorPopup: false,
        },
        {
            headerName: 'Effective Date',
            field: 'effectiveDate',
            flex: 1,
            valueGetter: (params) => {
                return moment(params.data?.effectiveDate).format('DD MMM, YYYY');
            },
        },
        {
            headerName: 'End Date',
            field: 'endDate',
            flex: 1,
            cellRenderer: (params) => {
                return (
                    (params.data.endDate && params.data.effectiveDate) ? <div>{moment(params.data?.endDate).format('DD MMM, YYYY')}</div> : (params.data.id && !params.data.endDate) ? <div className='active-bg d-flex'>Active</div> : ''
                )
            }
        },
        {
            headerName: 'Status',
            field: 'status',
            cellRenderer: (params) => {
                if (params.data.status === 'APPROVED') {
                    return (
                        <div className='approve'>Approved</div>
                    )
                } else if (params.data.status === 'REJECTED') {
                    return (
                        <div className='reject'>Rejected</div>
                    )
                }
            },
            editable: (params) => {
                return params.data.status === 'SUBMITTED_FOR_APPROVAL' ? true : false
            },
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Approve', 'Reject']
            },
            flex: 1,
            sortable: false,
            cellClass: (params) => params.data.status === 'SUBMITTED_FOR_APPROVAL' ? 'text-input-field' : ''
        }
    ]

    const getAllRequest = async () => {
        historyRef.current.api.showLoadingOverlay();
        await fetchWrapper(`${API_URL}/ownership/getChildRecordsForAction/history/${orgId}`)
            .then((res) => {
                setData(res?.json)
                historyRef.current.api.hideOverlay();
            }, (err) => {
                console.log(err)
                historyRef.current.api.hideOverlay();
            })
    }

    const onGridReady = useCallback(async (params) => {
        getAllRequest();
    })

    const handleApprove = async(data, value) => {
        const options = {
            method: 'POST',
            body: {
                id: data?.id,
                status: value === 'Approve' ? 'APPROVED' : 'REJECTED'
            }
        }
        await fetchWrapper(`${API_URL}/ownership/setActionUpdate`, options)
        .then((res) => {
            if(res.status === 200) {
                if(value === 'Approve') {
                    toast.success('Ownership request approved!');
                } else if(value === 'Reject') {
                    toast.error('Ownership request Rejected!')
                }
                getAllRequest()
            }
        }, (err) => {
            console.log(err)
            if(err.status === 200) {
                if(value === 'Approve') {
                    toast.success('Ownership request approved!');
                } else if(value === 'Reject') {
                    toast.error('Ownership request Rejected!')
                }
                getAllRequest()
            } else if(err.status === 500) {
                toast.error('Something went wrong!')
            }
        })
    }

    const onCellChange = async (event) => {
        if(event) {
            if(event?.colDef?.field === 'status') {
                console.log(event.data)
                await handleApprove(event.data, event.value)
            }
        }
    }

    const handleBack = () => {
        navigate(`${process.env.PUBLIC_URL}/ownership-management`)
    }


    return (
        <Fragment>
            <Container fluid>
                <Breadcrumbs mainTitle={`${orgName} - Ownership Records`} />
                <Row>
                    <Col lg={12}>
                        <Card className=''>
                            <CardBody className='ownershipHistory ev-station-consumption'>
                                <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                    <AgGridTable
                                        columnData={columnData}
                                        tableData={data}
                                        agRef={historyRef}
                                        onGridReady={onGridReady}
                                        pagination={false}
                                        onCellValueChanged={onCellChange}
                                    />
                                </div>
                                <div className='mtop-1'>
                                    <Button color='secondary' onClick={handleBack}>Back</Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default ApproveOwnership;