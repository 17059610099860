import React, { useState } from "react";
import { Fragment } from "react";
import { H4, H3 } from '../../../AbstractElements'
import { Card, CardBody, Row, Col, Table } from "reactstrap";
import { baselineEmission, projectEmission } from "./CalculationHelper";
import EmissionModal from "./EmissionModal";

const AgriCalculation = () => {
    const [cardShow, setCardShow] = useState(false);
    const [cardData, setCardData] = useState();

    return (
        <Fragment>
            <Row>
                <Col lg='12'>
                    <Card>
                        <CardBody>
                            <div>
                                <div>
                                    <H3 attrH3={{ className: 'f-w-600' }}>ER<sub>NET,t</sub>(Carbon credits) = ER<sub>t</sub> - LK<sub>ER,t</sub></H3>
                                </div>
                                <div className="mtop-1">
                                    <H4 attrH4={{ className: 'f-w-600' }}>Description</H4>
                                    <ul>
                                        <li>
                                            <p>ER<sub>t</sub> = Estimated net GHG emission reductions in year t (tCO2e)</p>
                                        </li>
                                        <li>
                                            <p>LK<sub>ER,t</sub> = Leakage allocated to GHG emission reductions in year t (tCO2e)</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="mtop-1">
                                <H3 attrH3={{ className: 'f-s-17 f-w-600 ' }}>Leakage allocated to emissions reductions (LK<sub>ER,t</sub>) is calculated as:</H3>
                                <div>
                                    <H3 attrH3={{ className: 'f-s-17 f-w-600 ' }}>LK<sub>ER,t</sub> = (LE<sub>OA,t</sub> + LE<sub>BR,t</sub>) * (ER<sub>t</sub> / ER<sub>t</sub> + CR<sub>t</sub>)</H3>
                                </div>
                                <div className="mtop-1">
                                    <H4 attrH4={{ className: 'f-w-600' }}>Description</H4>
                                    <ul>
                                        <li>
                                            <p>LE<sub>BR,t</sub> = Leakage emissions from the diversion of manure or crop residues from baseline 
                                            energy applications in year t (tCO2e)</p>
                                        </li>
                                        <li>
                                            <p>CR<sub>t</sub> = Estimated carbon dioxide removals in year t (tCO2e)</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {/* <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <p>Example</p>
                            <Table bordered striped>
                                <thead>
                                    <tr>
                                        <th>Year</th>
                                        <th>Plant Load Factor</th>
                                        <th>Emission Factor</th>
                                        <th >
                                            <div className="d-flex cur-pointer">
                                                Baseline Emission 
                                            </div>
                                        </th>
                                        <th>
                                            <div className="d-flex cur-pointer">
                                                Project Emission 
                                            </div>
                                        </th>
                                        <th>Carbon Credits</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>2024</td>
                                        <td className="text-right">0.20</td>
                                        <td className="text-right">0.93</td>
                                        <td className="text-right">6,370.80</td>
                                        <td className="text-right">0.00</td>
                                        <td className="text-right">6,370.80</td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td className="text-right">0.20</td>
                                        <td className="text-right">0.93</td>
                                        <td className="text-right">6,370.80</td>
                                        <td className="text-right">0.00</td>
                                        <td className="text-right">6,370.80</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row> */}
            {
                cardShow && <EmissionModal value={cardShow} setOpenModal={setCardShow} data={cardData} />
            }
        </Fragment>
    )
}

export default AgriCalculation;