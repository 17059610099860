import React, { Fragment, useState, useEffect } from "react";
import GroupForm from "../Forms/GroupForm";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { defaultUserType } from "../../api/helper";
import { useParams } from "react-router";

const EditGroup = () => {
    const [ data, setData ] = useState();
    const groupId = useParams()?.id;
    useEffect(() => {
        loadData(groupId)
    }, []);

    const loadData = async (id) => {
        console.log(id)
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/group/${id}`, options)
        .then((res)=> {
            console.log(res)
            setData(res.json)
        })
        .catch((error) => {
            console.debug(error)
        })
    }

    const userType = sessionStorage.getItem('userType')

    return (
        <Fragment>
            <GroupForm action={'Edit'} title={'Edit Group'} 
            navigateUrl={`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/group-list`} preData={data} />
        </Fragment>
    )
}

export default EditGroup;