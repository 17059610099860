import React, { Fragment, useState } from 'react';
import { Bell } from 'react-feather';
import { LI, P, UL, H3 } from '../../../../AbstractElements';
import { CheckAllNotification } from '../../../../Common/Constant';
import { Link } from 'react-router-dom';
import { FaRegCircleDot } from "react-icons/fa6";

const tempNotificationData = [
    {
        sender: 'AIROI',
        message: 'AIROI Accepted your Project.',
        receivedTime: '09:58 PM'
    },
    {
        sender: 'AIROI',
        message: 'AIROI Validated your Project.',
        receivedTime: '09:58 PM'
    },
    {
        sender: 'Demo Company',
        message: 'Demo Company requested to add as a partner. If you want click below link.',
        receivedTime: '09:58 PM'
    },
    {
        sender: 'Demo Company',
        message: 'Demo Company requested to change the Equity Percentage.',
        receivedTime: '09:58 PM'
    },
    {
        sender: 'VERRA',
        message: 'VERRA Accepted our Project.',
        receivedTime: '09:58 PM'
    },
    {
        sender: 'VERRA',
        message: 'VERRA assigned a Assessor User to your Project.',
        receivedTime: '09:58 PM'
    },
    {
        sender: 'VERRA',
        message: 'Assessor Accepted to your Project',
        receivedTime: '09:58 PM'
    },
    {
        sender: 'VERRA',
        message: 'Assessor Submitted your project to the registry.',
        receivedTime: '09:58 PM'
    },
    {
        sender: 'VERRA',
        message: 'Registry Approved your project.',
        receivedTime: '09:58 PM'
    }, {
        sender: 'AIROI',
        message: 'AIROI Activated the Project.',
        receivedTime: '09:58 PM'
    },
]

const Notifications = () => {
    const [notifyCount, setNotifyCount] = useState();
    const [notificationData, setNotificationData] = useState([...tempNotificationData]);

    return (
        <Fragment>
            <LI attrLI={{ className: 'onhover-dropdown' }} >
                <div className="notification-box">
                    <Bell color='#fff' className={notifyCount > 0 && 'bell-anime'} />
                    <span className="badge rounded-pill badge-primary">{notifyCount}</span>
                </div>
                {
                    notificationData?.length > 0 && <UL attrUL={{ className: 'simple-list notification-dropdown onhover-show-div' }} >
                        <LI>
                            {/* <Bell /> */}
                            <H3 attrH3={{ className: 'mb-0' }}>Notifications</H3>
                        </LI>
                        {
                            notificationData?.filter((ele, index) => index < 4)?.map((items, index) => {
                                return (
                                    <LI key={index}>
                                        <div>
                                            <div className='d-flex'>
                                                <FaRegCircleDot />
                                                <div className='d-flex position-unset'>
                                                    <div className='f-w-600 ml-1'>{items?.sender}</div>
                                                    <span className="rc-time">{items.receivedTime}</span>
                                                </div>
                                            </div>
                                            <div className='notify-msg notify-eclipse'>
                                                {items.message}
                                            </div>

                                        </div>
                                    </LI>
                                )
                            })
                        }
                        {
                            notifyCount > 5 && <LI>
                                <a>View All</a>
                            </LI>
                        }

                        {/* <LI>
                        <Link to={`${process.env.PUBLIC_URL}/app/email/readmail`} className="btn btn-primary">{CheckAllNotification}</Link>
                    </LI> */}
                    </UL>
                }

            </LI>
        </Fragment>
    );
};
export default Notifications;