import React, { Fragment, useEffect, useRef, useState } from "react";
import AgGridDatasheet from "./AgGridDatasheet";
import { H3 } from "../../../AbstractElements";
import { thousandSeparator } from '../../api/helper';
import { Plus } from "react-feather";
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { toast } from "react-toastify";
import { Trash2, XCircle } from "react-feather";
import { alertFunction, deleteCacheData, getCacheData, notifyFunction, storeData, uuidGenerator } from './ghgHelper';
import { useSelector } from "react-redux";
import moment from "moment";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { PiFileArrowDownDuotone } from "react-icons/pi";

let error = false;
const StationaryCombustion = ({ setSteps, preData, setStationaryCombustion, stationaryData, timeStamp, requestId, setRequestId, mode }) => {
    const [data, setData] = useState();
    const [combustedData, setCombustedData] = useState([]);
    const [fuelCombusted, setFuelCombusted] = useState([]);
    const [noOfRow, setNoOfRow] = useState(1);
    const [loading, setLoading] = useState(false);
    const [rowIndex, setRowIndex] = useState([]);
    const scRef = useRef();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const userProfile = useSelector(state => state?.user?.userDetails);
    const [cacheData, setCacheData] = useState()
    const keyObject = {
        customerId: userProfile?.org,
        userId: userProfile?.id,
        ...preData
    }
    const populateTable = (currentData) => {
        let count = currentData?.length < 10 ? (10 - currentData?.length) : 0;
        let tempData = [...currentData, ...[...Array(count)].map((_, index) => ({
            id: index + 1, isInsert: false,
            isUpdate: false, isDelete: false, isSubmit: false
        }))];
        setData(tempData)
    }

    const loadData = (savedData, cTimestamp) => {
        if (stationaryData?.length > 0 && (savedData?.length === 0 || !savedData)) {
            populateTable(stationaryData)
        } else if (stationaryData?.length === 0 && savedData?.length > 0) {
            populateTable(savedData)
        } else if (stationaryData?.length > 0 && savedData?.length > 0) {
            if (moment(cTimestamp).isAfter(moment(timeStamp))) { //cache is latest
                alertFunction(savedData, stationaryData, populateTable)
            } else { //saved is latest
                notifyFunction(stationaryData, populateTable)
            }
        }
    }

    const getCache = async () => {
        let cacheData = await getCacheData(keyObject);
        setCacheData(cacheData)
        loadData(cacheData?.stationaryCombustionData, cacheData?.modifiedTs)
    }

    const getFuelCombustedData = async () => {
        await fetchWrapper(`${API_URL}/emissionunits/StationaryCombustion/${preData?.countryCode}`)
            .then((res) => {
                const data = res?.json?.map(ele => ele.sourceDescription)
                setFuelCombusted(data)
                setCombustedData(res.json);
            }, (err) => console.log(err))
    }

    useEffect(() => {
        getCache()
        getFuelCombustedData()
        const obj = [...Array(10)].map((_, index) => ({ id: index + 1, isInsert: false, isUpdate: false, isDelete: false, isSubmit: false }));
        setData(obj);
        // if (stationaryData?.length > 0) {
        //     loadData()
        // }
    }, [])

    const handleClear = async (params) => {
        const updatedRowData = data.map((row, index) => {
            return ((row.id === params.data.id) && row.isSubmit === true) ? { ...row, isDelete: true, isInsert: false, isUpdate: false }
                : ((row.id === params.data.id) && row.isSubmit === false) ?
                    { id: index + 1, fuelState: undefined, isInsert: false, isUpdate: false, isDelete: false, isSubmit: false }
                    : row
        });
        const tempData = {
            ...cacheData,
            stationaryCombustionData: updatedRowData?.filter(ele => ele?.sourceId),
            requestId: requestId,
            modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
        }
        await deleteCacheData(keyObject, tempData)
        setData(updatedRowData);
    };

    const handleUpload = async (e, params, nodeIndex) => {
        console.log(e.target.files[0])
        let dataId;
        if (typeof params?.id === 'string') {
            dataId = params.id;
        } else {
            let id = uuidGenerator();
            dataId = id;
            data[nodeIndex].id = id;
        }
        let tempData = new FormData();
        tempData.append('file', e.target.files[0]);
        const options = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem('accessToken')}`
            },
            body: tempData
        }
        await fetchWrapper(`${API_URL}/document/uploadEvidences?category=StationaryCombustion&uploadPeriod=${preData?.uploadPeriod}&categoryDetailId=${dataId}&year=${preData?.year}&month=${preData?.month}&quarter=${preData?.quarter}&requestId=${requestId ? requestId : ''}&locationId=${preData?.locationId}`, options)
            .then((res) => {
                console.log(res.json)
                setLoading(false)
                let tempData = [...data];
                tempData[nodeIndex].documentId = res?.json?.id;
                tempData[nodeIndex].uploadedFileName = res?.json?.uploadedFileName;
                setRequestId(res?.json?.requestId)
                tempData[nodeIndex].isInsert = true;
                tempData[nodeIndex].isUpdate = false;
                setData(tempData)
                createCache(data?.filter(ele => ele?.sourceId))
                toast.success('Evidence uploaded successfully!')
            })
            .catch((err) => {
                console.log(err)
                toast.error('Evidence upload failed!')
                setLoading(false)
            })
    }

    const downloadEvidence = async (paramsData) => {
        await fetchWrapper(`${API_URL}/document/downloadEvidence/${paramsData?.documentId}`)
            .then((err) => {
                console.log(err.json)
                if (err.status === 200) {
                    const link = document.createElement('a');
                    link.href = err?.json?.presignedUrl;
                    link.download = paramsData.uploadedFileName;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
                if (err.status === 500) {
                    toast.error('Something went wrong!')
                }
            })
            .catch((err) => {
                console.log(err, 'asdasd')
            })
    }

    const columnData = [
        {
            headerName: 'Source ID',
            field: 'sourceId',
            editable: false,
            valueGetter: (params) => {
                if (params.data.hasOwnProperty('sourceDescription')
                    || params.data.hasOwnProperty('sourceArea')
                    || params.data.hasOwnProperty('fuelCombusted')
                    || params.data.hasOwnProperty('quantityCombusted')
                    || params.data.hasOwnProperty('units')
                ) {
                    params.data.sourceId = `SC-${params.node.rowIndex + 1}`
                    return params.data.sourceId;
                }
            },
            filter: false,
            flex: 1,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Source Description',
            field: 'sourceDescription',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Source Area  (sqft)',
            field: 'sourceArea',
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 4,
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.sourceArea ? thousandSeparator(params?.data?.sourceArea?.toFixed(4)) : ''}</div>;
            },
            filter: false,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            flex: 1.25
        },
        {
            headerName: 'Fuel Combusted',
            field: 'fuelCombusted',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: fuelCombusted
            },
            valueGetter: (params) => {
                if (params.data.fuelCombusted) {
                    let getCombusted = combustedData?.find((item) => item?.sourceDescription === params.data?.fuelCombusted);
                    params.data.resourceType = getCombusted?.resourceType;
                    return params.data.fuelCombusted
                }
            },
            filter: false,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            flex: 2.5,
            sortable: false
        },
        {
            headerName: 'Fuel State',
            field: 'fuelState',
            filter: false,
            editable: false,
            valueGetter: (params) => {
                if (params.data.fuelCombusted) {
                    let getCombusted = combustedData?.find((item) => item?.sourceDescription === params.data?.fuelCombusted);
                    params.data.fuelState = getCombusted?.itemType;
                    return params.data?.fuelState;
                }
            },
            flex: 1,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            sortable: false
        },
        {
            headerName: 'Qty Combusted',
            field: 'quantityCombusted',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 4
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.quantityCombusted ?
                    thousandSeparator(params?.data?.quantityCombusted.toFixed(4)) : ''}</div>;
            },
            flex: 2,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            sortable: true
        },
        {
            headerName: 'Unit',
            field: 'units',
            filter: false,
            editable: (params) => {
                if (params.data?.fuelCombusted) {
                    let getCombusted = combustedData?.find((item) => item?.sourceDescription === params.data?.fuelCombusted);
                    let unitData = getCombusted?.uiUnit?.split(',');
                    if (unitData?.length > 1) {
                        return true
                    }
                } return false;
            },
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.fuelCombusted) {
                    let getCombusted = combustedData?.find((item) => item?.sourceDescription === params.data?.fuelCombusted);
                    let unitData = getCombusted?.uiUnit?.split(',');
                    if (unitData?.length > 1) {
                        return {
                            values: unitData, // your dropdown options
                        };
                    }
                }
            },
            valueGetter: (params) => {
                if (params.data?.fuelCombusted) {
                    let getCombusted = combustedData?.find((item) => item?.sourceDescription === params.data?.fuelCombusted);
                    let unitData = getCombusted?.uiUnit?.split(',');
                    if (unitData?.length === 1) {
                        params.data.units = unitData[0];
                        return unitData[0];
                    }
                }
                if (params.data?.units) {
                    return params.data?.units
                }
            },
            flex: 2,
            sortable: false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
        },
        {
            headerName: 'Evidence',
            flex: 1.5,
            autoHeight: true,
            wrapText: true,
            tooltipValueGetter: (params) => {
                if (params.data.uploadedFileName) {
                    return params.data.uploadedFileName;
                }
            },
            cellRenderer: (params) => {
                if (mode === 'Edit') {
                    if (!params.data.uploadedFileName) {
                        return (
                            <div>
                                <label className="upload-file-icon">
                                    <input
                                        type="file" hidden accept=".jpeg, .jpg, .png, .pdf"
                                        onChange={(event) => handleUpload(event, params?.data, params?.node?.rowIndex)}
                                    />
                                    <AiOutlineCloudUpload />
                                </label>
                            </div>
                        );
                    } else {
                        return (
                            <div onClick={() => downloadEvidence(params.data)}>
                                <PiFileArrowDownDuotone className="file-download" />
                            </div>
                        )
                    }
                }
            },
            suppressMovable: true,
            cellClass: 'tick-center'
        },
        {
            headerName: 'Clear',
            width: 70,
            cellRenderer: (params) => {
                return (
                    (params.data.sourceId && mode === 'Edit') ?
                        <div>
                            <Trash2 onClick={() => handleClear(params)}
                                className={'trash-icon mtop-0'} />
                        </div> : <></>
                )
            },
            suppressMovable: true,
            cellClass: 'icon-center'
        },
    ]

    const addingRows = (val) => {
        setNoOfRow(val)
    }

    const addRows = () => {
        const rowData = [];
        for (let i = 1; i <= noOfRow; i++) {
            rowData.push({ id: data?.length + i, isInsert: false, isUpdate: false, isDelete: false, isSubmit: false })
        }
        setData([...data, ...rowData]);
    }

    const getGridData = () => {
        if (scRef.current) {
            const tableState = data
                .filter((rowNode) => rowNode.sourceId)
                .map((rowNode, index) => {
                    if (rowNode.sourceArea && rowNode.fuelCombusted && rowNode.resourceType
                        && rowNode.quantityCombusted && rowNode.sourceDescription) {
                        let data = {
                            id: rowNode.id,
                            sourceId: rowNode.sourceId,
                            sourceDescription: rowNode.sourceDescription,
                            sourceArea: Number(rowNode.sourceArea),
                            fuelCombusted: rowNode.fuelCombusted,
                            quantityCombusted: parseFloat(rowNode.quantityCombusted),
                            fuelState: rowNode.fuelState,
                            resourceType: rowNode.resourceType,
                            documentId: rowNode.documentId ? rowNode.documentId : null,
                            uploadedFileName: rowNode.uploadedFileName ? rowNode.uploadedFileName : null,
                            units: rowNode.units,
                            isInsert: rowNode.isInsert,
                            isUpdate: rowNode.isUpdate,
                            isDelete: rowNode.isDelete,
                            isSubmit: rowNode.isSubmit
                        }
                        return data;
                    } else {
                        rowIndex.push(rowNode?.sourceId.split('-')[1]);
                        return {}
                    }
                });
            if (tableState?.some(ele => Object.keys(ele)?.length === 0)) {
                error = true;
            } else {
                error = false
            }
            return tableState.filter(ele => Object.keys(ele)?.length > 0);
        }
        return [];
    }

    const createCache = async (data) => {
        console.log(requestId)
        const tempData = {
            ...cacheData,
            stationaryCombustionData: data?.filter(ele => ele?.sourceId),
            requestId: requestId,
            modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
        }
        await storeData(keyObject, tempData)
    }

    const onCellValueChanged = (event) => {
        if (event.value) {
            if (event.data.isSubmit === true && (event.data.isInsert === false || event.data.isUpdate === true)) {
                event.data.isUpdate = true;
                event.data.isInsert = false;
            } else if (event.data.isSubmit === false && event.data.isInsert === false && typeof event.data.id !== 'string') {
                event.data.id = uuidGenerator();
                event.data.isInsert = true;
                event.data.isUpdate = false;
            } else if (event.data.isSubmit === false && event.data.isInsert === false) {
                event.data.isInsert = true;
                event.data.isUpdate = false;
            }
        }
        createCache(data?.filter(ele => ele?.sourceId))
    }

    const onSubmit = () => {
        const gridData = getGridData();
        if (error === true) {
            toast.error(`Please fill missing values for row ${[...new Set(rowIndex)].join(',')}`)
            setRowIndex([])
            error = false;
            return
        } else {
            console.log(gridData)
            setStationaryCombustion(gridData)
            setSteps(0)
        }

    }



    return (
        <Fragment>
            <div className="d-flex">
                <H3 attrH3={{ className: 'f-s-15' }}>Stationary Source Fuel Combustion</H3>
                <button className="manage-asm-but border-1px-solid"
                    onClick={() => onSubmit()}>Back to Summary</button>
            </div>
            {deleteLoading ? <div className="loading-overlay"><i class="fa-solid fa-spinner fa-spin mr-1"></i> Loading....</div> : <></>}
            <div className="mtop-1" style={{ opacity: deleteLoading ? '0.2' : '1' }}>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        agRef={scRef}
                        tableData={data?.filter(ele => ele?.isDelete === false)}
                        columnData={columnData}
                        onCellValueChanged={onCellValueChanged}
                    />
                    <div className="ag-custom-footer">
                        <div className="d-flex ">
                            <button className="add-row-button" onClick={addRows}><Plus className="mr-10" color="#9da6ab" fontSize={13} height={20} width={20} strokeWidth={3} />  Add</button>
                            <input
                                type="number"
                                className="ml-1 input-number"
                                value={noOfRow}
                                onChange={(e) => addingRows(e.target.value)}
                            />
                            <h5 className="ml-1">rows</h5>
                        </div>
                    </div>
                </div>
            </div>
            {/*  */}
        </Fragment>
    )
}

export default StationaryCombustion;