import React, { Fragment, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { X } from "react-feather";
import EvStationForm from "../Forms/EvStationForm";
import { defaultUserType } from "../../api/helper";

const AddEvStation = ({value, setOpenModal, projectId, projectName, setAdd}) => {
    const [open, setOpen] = useState(value);
    const userType = sessionStorage.getItem('userType');

    const onClose = () => {
        setOpen(false);
        setOpenModal(false)
    }
    
    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='lg'>
                <ModalHeader style={{ display: 'flex' }}>
                    <H3 attrH3={{ className: 'modal-title-color' }}>{projectName} - New EV Station</H3>
                    <X className="x-button" onClick={onClose}/>
                </ModalHeader>
                <ModalBody>
                    <EvStationForm action={'Add'} cancel={onClose}  projectId={projectId} setAdd={setAdd}/>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default AddEvStation;