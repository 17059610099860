import React, { Fragment, useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { Container, Row, Col, Card, CardBody, Badge, Label, Button, FormGroup, Spinner } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import ButtonLoader from '../ButtonLoader';
import MonitoringDocUpload from '../MonitoringDocUpload/MonitoringDocUpload';
import { FiPaperclip } from "react-icons/fi";
import { thousandSeparator } from '../../api/helper';
import BiocharMonitoringForm from './BiocharMonitoringForm';
import { Plus, Edit } from 'react-feather';

let total = 0;
const MonthlyBiocharMonitoring = ({ projectId, frequency, userType, month, year, columnType, projectName, type, setRowData,
    setEvidenceUploadOpen, vendor }) => {
    const gridRef = useRef(null)
    const { register, handleSubmit, formState: { errors }, trigger } = useForm();
    const [tableData, setTableData] = useState([]);
    const [totalCollection, setTotal] = useState(0);
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState({
        id: 'total',
        period: 'Total',
        quantity: total,
    });
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [open, setOpenModal] = useState(false);
    const [monitoringData, setMonitoringData] = useState({});

    const handleEvidenceModal = useCallback((data, event) => {
        setRowData(data);
        setEvidenceUploadOpen(true);
    })

    const handleEdit = (data) => {
        setMonitoringData(data);
        setOpenModal(true);
    }

    const columnData = [
        {
            headerName: 'Period',
            field: 'period',
            flex: 1,
            suppressMovable: true
        },
        {
            headerName: 'Unit',
            field: 'vendorName',
            flex: 1,
            suppressMovable: true,
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap'
        },
        {
            headerName: 'Feedstock Type',
            field: 'feedStockType',
            flex: 1.5,
            suppressMovable: true,
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap'
        },
        {
            headerName: 'Production process',
            field: 'productionProcess',
            flex: 1.5,
            suppressMovable: true,
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap'
        },
        {
            headerName: 'Production method',
            field: 'productionMethod',
            flex: 1.5,
            suppressMovable: true,
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap'
        },
        {
            headerName: 'Total Mass(Tonnes)',
            field: 'totalMass',
            flex: 1.5,
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.totalMass ?
                    thousandSeparator(params?.data?.totalMass.toFixed(2)) : ''}</div>;
            },
            suppressMovable: true
        },
        {
            headerName: 'Produced Mass(Tonnes)',
            field: 'producedMass',
            flex: 1.5,
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.producedMass ?
                    thousandSeparator(params?.data?.producedMass.toFixed(2)) : ''}</div>;
            },
            suppressMovable: true
        },
        {
            headerName: 'Applied Mass(Tonnes)',
            field: 'appliedMass',
            flex: 1.5,
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.appliedMass ?
                    thousandSeparator(params?.data?.appliedMass.toFixed(2)) : ''}</div>;
            },
            suppressMovable: true
        },
        {
            headerName: 'Credits',
            field: 'creditUnits',
            flex: 1.5,
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.creditUnits ?
                    params?.data?.creditUnits.toFixed(2) : ''}</div>;
            },
            suppressMovable: true
        },
        {
            headerName: 'Evidence',
            flex: 1,
            cellRenderer: (params) => {
                return <div className='ml-1-5' onClick={(e) => handleEvidenceModal(params.data, e)} >
                    <FiPaperclip />
                    <div></div>
                </div>
            }
        },
        {
            headerName: 'Edit',
            width: 60,
            cellRenderer: params => {
                return (
                    <div onClick={() => handleEdit(params.data)}>
                        <Edit className={`edit-icon `} />
                    </div>
                )
            },
            suppressMovable: true,
        }
    ]

    const getCollectionList = async () => {
        let total = 0, targetURL;
        const dateArray = loadDateArray();
        targetURL = vendor ? `biocharmonitoring/project/${projectId}/vendor/${vendor}/${frequency}/${moment(year).format('YYYY')}` :
            `biocharmonitoring/project/${projectId}/${frequency}/${moment(year).format('YYYY')}`;
        await fetchWrapper(`${API_URL}/${targetURL}`)
            .then((res) => {
                if (res.status === 200) {
                    // const data = res.json;
                    // const formatArray = dateArray?.map((item) => {
                    //     let findItem = data.find((ele) => ele.period.split(',')[0] === item.period);
                    //     return { ...item, ...findItem }
                    // })

                    // data?.map((i) => {
                    //     total = total + Number(i.quantity)
                    // })
                    // setTotal(total)
                    // setPinnedBottomRowData({
                    //     id: 'total',
                    //     period: "Total",
                    //     quantity: total
                    // });
                    setTableData(res?.json)
                }
            }, (err) => console.log(err))
    }

    const getGridData = () => {
        if (gridRef.current) {
            const gridApi = gridRef.current.api;
            const data = gridApi.getModel().rowsToDisplay
                .filter(rowNode => (rowNode.data.facility && rowNode.data.productionMethod && rowNode.data.feedStockType && rowNode.data.productionProcess
                    && rowNode.data.totalMass && rowNode.data.mass && rowNode.data.moistureContent
                ))
                .map((rowNode) => {
                    return {
                        period: rowNode.data.id ? rowNode.data.period : `${rowNode.data.period}, ${moment(year).format('YYYY')}`,
                        facility: rowNode.data.facility,
                        productionMethod: rowNode.data.productionMethod,
                        feedStockType: rowNode.data.feedStockType,
                        productionProcess: rowNode.data.productionProcess,
                        totalMass: rowNode.data.totalMass,
                        mass: rowNode.data.mass,
                        moistureContent: rowNode.data.moistureContent,
                    }
                });
            return data;
        }
        return [];
    }


    const loadDateArray = () => {
        let array = moment.months();
        const monthsArray = array.map((item) => {
            return {
                period: item
            }
        })
        return [...monthsArray];
    }

    useEffect(() => {
        getCollectionList()
    }, [year])


    const onCellValueChanged = (event) => {
        let total = totalCollection;
        const newValue = event.newValue; // The new value of the edited cell
        const columnId = event.column.getId(); // The column ID of the edited cell
        if (columnId === 'quantity') {
            total = total + newValue;
            setTotal(total);
        }
        setPinnedBottomRowData({
            id: 'total',
            period: "Total",
            quantity: total,
        });
    };


    const handleHistory = () => {
        navigate(`${process.env.PUBLIC_URL}/monitoring-history/${projectId}/${projectName}/${type}`)
    }

    const onGridReady = useCallback(async (params) => {
        gridRef.current.api.showLoadingOverlay();
        const dateArray = loadDateArray();
        let total = 0, targetURL;
        setTotal(total)
        setPinnedBottomRowData({
            id: 'total',
            period: "Total",
            quantity: total
        });
        targetURL = vendor ? `biocharmonitoring/project/${projectId}/vendor/${vendor}/${frequency}/${moment(year).format('YYYY')}` :
            `biocharmonitoring/project/${projectId}/${frequency}/${moment(year).format('YYYY')}`;
        await fetchWrapper(`${API_URL}/${targetURL}`)
            .then((res) => {
                gridRef.current.api.hideOverlay();
                if (res.status === 200) {
                    // const data = res.json;
                    // const formatArray = dateArray?.map((item) => {
                    //     let findItem = data.find((ele) => ele.period.split(',')[0] === item.period);
                    //     return { ...item, ...findItem }
                    // })
                    // data?.map((i) => {
                    //     total = total + Number(i.quantity)
                    // })
                    // setTotal(total)
                    // setPinnedBottomRowData({
                    //     id: 'total',
                    //     period: "Total",
                    //     quantity: total
                    // });
                    setTableData(res?.json)
                }
            }, (err) => console.log(err))
    });

    useEffect(() => {
        getCollectionList()
    }, [vendor])

    const handleBack = () => {
        navigate(`${process.env.PUBLIC_URL}/view-project/${projectId}`)
    }

    return (
        <Fragment>
            <Row>
                <Col lg='12'>
                    <div className='biochar-monitoring'>
                        <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                            <AgGridReact
                                columnDefs={columnData}
                                rowData={tableData}
                                ref={gridRef}
                                rowModelType='clientSide'
                                domLayout='autoHeight'
                                animateRows={true}
                                getRowStyle={() => {
                                    return {
                                        background: '#fafafa'
                                    }
                                }}
                                overlayLoadingTemplate={
                                    `<div class="custom-loading-overlay">
                                                    <i class="fa fa-spinner fa-spin"></i> <span class='f-15'>Please wait while your data are loading...</span>
                                                </div>`
                                }
                                overlayNoRowsTemplate={
                                    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">This is a custom \'no rows\' overlay</span>'
                                }
                                onCellValueChanged={onCellValueChanged}
                                enterNavigatesVerticallyAfterEdit={true}
                                stopEditingWhenCellsLoseFocus={true}
                                onGridReady={onGridReady}
                            />
                        </div>
                    </div>
                    <div className='custom-footer-area'>
                        <button className="mtop-1 add-row-button" onClick={() => {
                            setMonitoringData();
                            setOpenModal(true);
                        }}>
                            <Plus className="mr-10" color="#9da6ab" fontSize={13} height={20} width={20} strokeWidth={3} />
                            Add</button>
                    </div>
                </Col>
            </Row>
            <div className='d-flex mtop-1'>
                <Button color='secondary' className='ml-1' onClick={handleBack}>Back</Button>
            </div>
            {
                open && <BiocharMonitoringForm value={open} setOpenModal={setOpenModal} setAdd={(e) => e === true && getCollectionList()}
                    monitoringData={monitoringData} projectId={projectId} frequency={frequency} gridData={tableData} />
            }
        </Fragment>
    )
}

export default MonthlyBiocharMonitoring;