import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { CustomFooter, thousandSeparator } from "../../../api/helper";
import { Button } from "reactstrap";
import moment from "moment";
import { ESimpactData, greivanceData, materialData } from "../../Settings/settingshelper";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";
import { toast } from "react-toastify";

const ResponsibleTransparentAdvocacy = ({ setActive, financialYear, previousYear, previous2Years, mode }) => {
    const [industryAffiliation, setIndustryAffiliation] = useState()
    const [tradeIndustryData, setTradeIndustryData] = useState([...Array(4)].map((_, index) => ({ serialNo: index + 1 })));
    const [regulatoryComplianceData, setRegulatoryData] = useState([...Array(2)].map((_, index) => ({ serialNo: index + 1 })))
    const [publicPolicyData, setPublicPolicy] = useState([...Array(4)].map((_, index) => ({ serialNo: index + 1 })))

    const getTradeIndustryData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/tradeIndustryData/${financialYear}`)
            .then((res) => {
                setTradeIndustryData(res.json)
            }, (err) => {
                setTradeIndustryData([...Array(2)].map((_, index) => ({ serialNo: index + 1 })))
            })
    }

    const getRegulatoryComplianceData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/regulatoryComplianceData/${financialYear}`)
            .then((res) => {
                setRegulatoryData(res.json)
            }, (err) => {
                setRegulatoryData([...Array(2)].map((_, index) => ({ serialNo: index + 1 })))
            })
    }

    const getPublicPolicyData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/publicPolicyData/${financialYear}`)
            .then((res) => {
                setPublicPolicy(res.json)
            }, (err) => {
                setPublicPolicy([...Array(4)].map((_, index) => ({ serialNo: index + 1 })))
            })
    }

    const getAttributesKeyValuesData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/attributesKeyValueData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                const data = res.json?.filter(ele => ele.groupName === 'responsibleTransparentAdvocacy');
                console.log(data)
                setIndustryAffiliation(data?.find(ele => ele.key === 'Q48')?.value);
            }, (err) => console.log(err))
    }

    useEffect(() => {
        if (financialYear) {
            getTradeIndustryData()
            getPublicPolicyData()
            getRegulatoryComplianceData()
            getAttributesKeyValuesData()
        }
    }, [financialYear])

    const tradeIndustryColumns = [
        {
            headerName: 'Name of the trade and industry chambers/ associations',
            field: 'associationName',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Reach of trade and industry chambers/ associations (State/National)',
            field: 'reachLevel',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['State', 'National']
            },
            filter: false,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            flex: 1.5,
            sortable: false
        },
    ];

    const regulatoryComplianceColumns = [
        {
            headerName: 'Name of authority',
            field: 'authorityName',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Brief of the case',
            field: 'caseBrief',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Corrective action taken',
            field: 'correctiveAction',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
    ]

    const publicPolicyColumn = [
        {
            headerName: 'Public policy advocated',
            field: 'policyName',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Method resorted for such advocacy',
            field: 'advocacyMethod',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Whether information available in public domain?',
            field: 'isPublicDomain',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Yes', 'No']
            },
            filter: false,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            flex: 1.5,
            sortable: false
        },
        {
            headerName: 'Frequency of Review by Board',
            field: 'reviewFrequency',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Annually', 'Half-yearly', 'Quarterly', 'Bi-monthly', 'Monthly']
            },
            filter: false,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            flex: 1.5,
            sortable: false
        },
        {
            headerName: 'Web Link, if available',
            field: 'webLink',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agTextCellEditor',
            filter: false,
            flex: 1,
            cellRenderer: (params) => {
                if (params.data.webLink) {
                    return (
                        <a href={params.data.webLink} target="_blank">{params.data.webLink}</a>
                    )
                }
            },
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
    ]

    const onSubmit = async () => {
        const bodyData = {
            financialYear: financialYear,
            tradeIndustryData: tradeIndustryData
                ?.filter(ele => Object.keys(ele)?.length > 1)
                ?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            regulatoryComplianceData: regulatoryComplianceData
                ?.filter(ele => Object.keys(ele)?.length > 1)
                ?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            publicPolicyData: publicPolicyData
                ?.filter(ele => Object.keys(ele)?.length > 1)
                ?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            attributesKeyValueData: [
                {
                    serialNo: 7,
                    groupName: 'responsibleTransparentAdvocacy',
                    key: 'Q48',
                    value: industryAffiliation
                }
            ]
        }
        const options = {
            method: 'POST',
            body: bodyData
        }
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
                if (err.status === 200) {
                    toast.success('Social data saved Successfully!')
                } else if (err.status === 500) {
                    toast.error('Something went wrong')
                }
            })
    }

    return (
        <Fragment>
            <h3>Responsible Transparent Advocacy</h3>
            <h4 className="mtop-1">Essential Indicators</h4>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    1. a. Number of affiliations with trade and industry chambers/ associations
                </h5>
                <input className="form-control ml-1" value={industryAffiliation} type="number" disabled={mode === 'Edit' ? false : true}
                    onChange={(e) => setIndustryAffiliation(e.target.value)} />
            </div>
            <div className="mtop-1 company-profile">
                <h5>b. List the top 10 trade and industry chambers/ associations (determined
                    based on the total members of such body) the entity is a member of/ affiliated to</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={tradeIndustryData}
                        columnData={tradeIndustryColumns}
                    />
                    <CustomFooter handleAdd={() => setTradeIndustryData([...tradeIndustryData, { serialNo: tradeIndustryData?.length + 1 }])} />
                </div>
            </div>
            <div className="mtop-1 company-profile">
                <h5>2. Provide details of corrective action taken or underway on any issues related to anticompetitive conduct by the entity,
                    based on adverse orders from regulatory authorities</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={regulatoryComplianceData}
                        columnData={regulatoryComplianceColumns}
                    />
                    <CustomFooter handleAdd={() => setRegulatoryData([...regulatoryComplianceData, { serialNo: regulatoryComplianceData?.length + 1 }])} />
                </div>
            </div>
            <h4 className="mtop-1">Leadership Indicators</h4>
            <div className="mtop-1 company-profile">
                <h5>Details of public policy positions advocated by the entity</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={publicPolicyData}
                        columnData={publicPolicyColumn}
                    />
                    <CustomFooter handleAdd={() => setPublicPolicy([...publicPolicyData, { serialNo: publicPolicyData?.length + 1 }])} />
                </div>
            </div>
            <div className="d-flex mtop-1" >
                <Button color="secondary" onClick={() => setActive(6)}>Back</Button>
                <Button color="primary" disabled={(!financialYear || (mode !== 'Edit')) ? true : false} className="ml-1" onClick={onSubmit}>Save</Button>
            </div>
        </Fragment>
    )
}

export default ResponsibleTransparentAdvocacy;