import React, { Fragment, useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { Container, Row, Col, Card, CardBody, Badge, Label, Button, FormGroup, Spinner } from 'reactstrap';
import { Breadcrumbs, H3 } from '../../../AbstractElements';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import DataTableComponent from '../DataTable/DataTableComponent';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { Eye, Edit, Trash2 } from 'react-feather';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import { defaultUserType } from '../../api/helper';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import ButtonLoader from '../ButtonLoader';
import MonitoringDocUpload from '../MonitoringDocUpload/MonitoringDocUpload';
import { FiPaperclip } from "react-icons/fi";

const EvStationConsumptionList = () => {
    const projectId = useParams()?.id;
    const projectName = useParams()?.name;
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false)
    const userType = useSelector(state => state.user?.userType);
    const [year, setYear] = useState(new Date());
    const [month, setMonth] = useState(new Date());
    const [quarter, setQuarter] = useState('');
    const [semiAnnual, setSemiannual] = useState('');
    const [reportPeriodFreq, setReportPeriodFreq] = useState('YEARLY');
    const [editableValues, setEditableValues] = useState();
    const gridRef = useRef(null)
    const { register, handleSubmit, formState: { errors }, trigger } = useForm();
    const navigate = useNavigate();
    const [projectData, setProjectData] = useState();
    const [rowData, setRowData] = useState();
    const [evidenceUploadOpen, setEvidenceUploadOpen] = useState(false);
    const [stationsData, setStationsData] = useState([]);
    const [vendorId, setVendorId] = useState();
    const [vendorList, setVendorList] = useState([]);
    const [isDataSave, setIsDataSave] = useState(false);

    const loadProjectData = async () => {
        await fetchWrapper(`${API_URL}/projects/${projectId}`)
            .then((res) => {
                const data = res.json;
                setReportPeriodFreq(data?.dataCollectionFrequency)
                setProjectData(res.json)
            }, (err) => console.log(err))
    }

    const getStationsData = async () => {
        let tempStationData;
        gridRef.current.api.showLoadingOverlay();
        await fetchWrapper(`${API_URL}/evstation/project/${projectId}`)
            .then((res) => {
                setLoading(false)
                gridRef.current.api.hideOverlay();
                tempStationData = res.json;
                setTableData(res.json)
                setStationsData(res.json)
            }, (err) => { console.log(err) })
        return tempStationData;
    }

    const getConsumptionData = async () => {
        gridRef.current.api.showLoadingOverlay();
        const monthCount = moment(month).format('M');
        const yearValue = new Date(year).getFullYear();
        await fetchWrapper(`${API_URL}/evconsumption/project/${projectId}/${yearValue}/MONTHLY/${monthCount}`)
            .then(async(res) => {
                setLoading(false)
                gridRef.current.api.hideOverlay();
                const stations = await getStationsData();
                const data = res.json.map((ele) => ({ ...ele, isDataSave: true }))
                const tempData = stations?.map((item) => {
                    const temp = data?.find(ele => ele.stationId === item.id);
                    return temp ? temp : item
                })
                console.log(tempData)
                setTableData(tempData)
                setStationsData(tempData)
                setIsDataSave(true)
            }, (err) => {
                console.log(err)
                if (err.status === 404) {
                    setIsDataSave(false)
                    getStationsData()
                }
            })
    }

    const getAllVendors = async () => {
        await fetchWrapper(`${API_URL}/vendor/project/${projectId}`)
            .then((res) => {
                console.log(res.json)
                setVendorList(res.json);
            }, (err) => {
                console.log(err)
            })
    }

    const getGridData = () => {
        if (gridRef.current) {
            const gridApi = gridRef.current.api;
            const data = tableData
                .filter((rowNode) => rowNode?.actualConsumption)
                .map((rowNode) => {
                    return {
                        'stationId': rowNode?.isDataSave === true ? rowNode.stationId :  rowNode.id,
                        'actualConsumption': rowNode.actualConsumption,
                        'vendorId': rowNode.vendorId,
                        'dataCollectionDate': rowNode.dataCollectionDate ?
                            moment(rowNode.dataCollectionDate).format('YYYY-MM-DD')
                            : moment(new Date()).format('YYYY-MM-DD')
                    }
                });
            setEditableValues(data)
            return data;
        }
        return [];
    }

    const handleEvidenceModal = (data, event) => {
        setRowData(data);
        setEvidenceUploadOpen(true);
    }

    const columnData = [
        {
            headerName: 'Station Name',
            field: 'name',
            filter: true,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            suppressMovable: true,
            cellClass: 'cell-wrap'
        },
        {
            headerName: 'Vendor',
            field: 'vendorName',
            filter: true,
            flex: 1,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            suppressMovable: true,
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap'
        },
        {
            headerName: 'Country',
            field: 'country',
            filter: true,
            flex: 1,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            suppressMovable: true
        },
        {
            headerName: 'Address',
            field: 'addressLine1',
            filter: true,
            cellRenderer: (params) => {
                return (<div>{`${params.data.addressLine1}, ${params.data.addressLine2}, ${params.data.city}, ${params.data.state}`}</div>)
            },
            flex: 1.5,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            suppressMovable: true,
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap'
        },
        {
            headerName: 'Data Collection Date',
            field: 'dataCollectionDate',
            editable: (params) => {
                return (!params.data?.isDataSave)
            },
            suppressMovable: true,
            singleClickEdit: true,
            cellEditorPopup: false,
            cellEditor: 'agDateCellEditor',
            cellEditorParams: {
                max: moment(new Date()).format('DD MMM, YYYY')
            },
            valueGetter: (params) => {
                return params.data.dataCollectionDate ? moment(params.data?.dataCollectionDate).format('DD MMM, YYYY') : 'dd mmm, yyyy';
            },
            flex: 1.5,
            cellClass: (params) => {
                return params.data?.isDataSave ? 'disable-field text-input-field ml-10 w-11-hf' : 'text-input-field ml-10 w-11-hf'
            },
        },
        {
            headerName: 'Consumption (KWH)',
            field: 'actualConsumption',
            editable: (params) => {
                return (!params.data?.isDataSave)
            },
            cellEditor: 'agNumberCellEditor',
            singleClickEdit: true,
            cellEditorPopup: false,
            cellEditorParams: {
                min: 0,
                precision: 2,
            },
            cellClass: (params) => {
                return params.data?.isDataSave ? 'disable-field text-input-field ml-10 w-11-hf' : 'text-input-field ml-10 w-11-hf'
            },
            suppressMovable: true
        },
        {
            headerName: 'Evidence',
            flex: 0.8,
            cellRenderer: (params) => {
                return <div className='ml-1-5' onClick={(e) => handleEvidenceModal(params.data, e)} >
                    <FiPaperclip />
                    <div></div>
                </div>
            }
        }
    ]

    useEffect(() => {
        loadProjectData();
        getAllVendors();
    }, [])

    useEffect(() => {
        getConsumptionData()
    }, [month, year])

    const getStationsByVendor = (id) => {
        if (id) {
            setVendorId(id)
            setTableData(stationsData?.filter(ele => ele?.vendorId === id))
        } else {
            setVendorId()
            setTableData(stationsData)
        }
    }

    useEffect(() => {

    }, [tableData])

    const loadStationData = async () => {
        await fetchWrapper(`${API_URL}/evstation/project/${projectId}`)
            .then((res) => {
                setLoading(false)
                setTableData(res.json)
                setStationsData(res.json);
            }, (err) => { console.log(err) })
    }

    const onSubmit = async () => {
        let evData = getGridData();
        if (reportPeriodFreq === 'MONTHLY' && !month) {
            toast.error('Please select Month..')
            return
        } else if (reportPeriodFreq === 'QUARTERLY' && !quarter) {
            toast.error('Please select Quarter..')
            return
        } else if (reportPeriodFreq === 'HALFYEARLY' && !semiAnnual) {
            toast.error('Please select Semi-Annual..')
            return
        } else if (!reportPeriodFreq) {
            toast.error('Please select Period..')
            return
        } else {
            let periodData;
            if (reportPeriodFreq === 'MONTHLY' && month) {
                periodData = moment(month).format('M');
            }
            if (reportPeriodFreq === 'QUARTERLY' && quarter) {
                periodData = moment(quarter).quarter();
            }
            if (reportPeriodFreq === 'HALFYEARLY' && semiAnnual) {
                periodData = semiAnnual;
            }
            let bodyData = {
                reportingPeriodFrequency: reportPeriodFreq,
                reportingPeriod: new Date(year).getFullYear(),
                reportingPeriodFrequencyValue: Number(periodData),
                monitoringLevel: "STATION",
                projectId,
                evConsumption: [...evData]
            }
            const options = {
                method: 'POST',
                body: bodyData
            }
            await fetchWrapper(`${API_URL}/evconsumption`, options)
                .then((res) => {
                    if (res.status === 200) {
                        toast.success('EV Station Consumption Added Successfully!')
                        getConsumptionData()
                    } else if (res.status === 406) {
                        toast.error(JSON.stringify(res.json))
                    }
                }, (err) => {
                    console.log(err)
                    if (err.status === 200) {
                        toast.success('EV Station Consumption Added Successfully!')
                        getConsumptionData()
                    } else if (err.status === 406) {
                        toast.error(JSON.stringify(err.json))
                    }
                })
        }
    }

    const onGridReady = useCallback(async (params) => {
        await getConsumptionData()
    }, []);

    const handleBack = () => {
        navigate(`${process.env.PUBLIC_URL}/view-project/${projectId}`)
    }

    const handleHistory = () => {
        navigate(`${process.env.PUBLIC_URL}/ev-consumption-history/${projectId}/${projectName}`)
    }

    return (
        <Fragment>
            <Container fluid>
                <Breadcrumbs parent="EV Charges List" title="List" mainTitle={`${projectName} - Monitoring`} />
                <Card>
                    <CardBody>
                        <div className="d-flex">
                            <H3 attrH3={{ className: 'title-color' }}>EV Station Consumption</H3>
                        </div>
                        <div className='mtop-2'>
                            <Row>
                                <Col lg='3'>
                                    <FormGroup>
                                        <Label>Data Collection Frequency</Label>
                                        <select className='form-select' value={reportPeriodFreq}
                                            disabled={projectData?.dataCollectionFrequency}
                                            {...register('reportingPeriodFrequency', { required: true })}
                                            onChange={(e) => setReportPeriodFreq(e.target.value)}
                                        >
                                            <option value=''>Select Period</option>
                                            <option value={'MONTHLY'}>Month</option>
                                            <option value={'QUARTERLY'}>Quarter</option>
                                            <option value={'HALFYEARLY'}>Semi Annual</option>
                                            <option value={'YEARLY'}>Annual</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col lg='2'>
                                    <FormGroup>
                                        <Label style={{ display: 'block' }}>Select Year</Label>
                                        <DatePicker
                                            className="datepicker-here form-control"
                                            selected={year}
                                            onChange={(e) => setYear(e)}
                                            showYearPicker
                                            dateFormat="yyyy"
                                            maxDate={new Date()}
                                        />
                                    </FormGroup>
                                </Col>

                                {
                                    reportPeriodFreq === 'MONTHLY' && <Col lg='2'>
                                        <FormGroup>
                                            <Label style={{ display: 'block' }}>Select Month</Label>
                                            <DatePicker
                                                className="datepicker-here form-control"
                                                selected={month ? month : new Date()}
                                                onChange={(e) => setMonth(e)}
                                                showMonthYearPicker
                                                dateFormat="MMM"
                                                placeholderText="-Month-"
                                                maxDate={new Date()}
                                            />
                                        </FormGroup>
                                    </Col>
                                }
                                {
                                    reportPeriodFreq === 'QUARTERLY' && <Col lg='2'>
                                        <FormGroup>
                                            <Label style={{ display: 'block' }}>Select Quarter</Label>
                                            <DatePicker
                                                className="datepicker-here form-control"
                                                selected={quarter ? quarter : new Date()}
                                                onChange={(e) => setQuarter(e)}
                                                showQuarterYearPicker
                                                dateFormat="QQQ"
                                                placeholderText="-Quarter-"
                                                maxDate={new Date()}
                                            />
                                        </FormGroup>
                                    </Col>
                                }
                                {
                                    reportPeriodFreq === 'HALFYEARLY' && <Col lg='2'>
                                        <FormGroup>
                                            <Label style={{ display: 'block' }}>Select Semi Annual</Label>
                                            <select className='form-select'
                                                onChange={(e) => setSemiannual(e.target.value)}
                                            >
                                                <option value=''>-Semi Annual-</option>
                                                <option value={1}>Jan - June</option>
                                                <option value={2}>Jul - Dec</option>

                                            </select>
                                        </FormGroup>
                                    </Col>
                                }
                                {
                                    vendorList?.filter(ele => (ele?.status === 'ACTIVE') && (ele?.name !== 'Self'))?.length > 0 && <Col lg={'3'}>
                                        <FormGroup>
                                            <Label>Vendor</Label>
                                            <select className='form-select' name='vendor' value={vendorId}
                                                onChange={(e) => getStationsByVendor(e.target.value)}>
                                                <option value={''}>-Select vendor-</option>
                                                {
                                                    vendorList?.filter(ele => (ele?.status === 'ACTIVE') && (ele?.name !== 'Self'))?.map((item) => (
                                                        <option value={item?.id}>{item?.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>
                                }

                            </Row>
                        </div>
                        <Row>
                            <Col lg={12}>
                                <div className='ev-station-consumption'>
                                    <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                        <AgGridReact
                                            columnDefs={columnData}
                                            rowData={tableData}
                                            ref={gridRef}
                                            rowModelType='clientSide'
                                            domLayout='autoHeight'
                                            animateRows={true}
                                            getRowStyle={() => {
                                                return {
                                                    background: '#fafafa'
                                                }
                                            }}
                                            overlayLoadingTemplate={
                                                `<div class="custom-loading-overlay">
                                                <i class="fa fa-spinner fa-spin"></i> <span class='f-15'>Please wait while your data are loading...</span>
                                            </div>`
                                            }
                                            overlayNoRowsTemplate={
                                                '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No Data</span>'
                                            }
                                            onGridReady={onGridReady}
                                            enterNavigatesVerticallyAfterEdit={true}
                                            stopEditingWhenCellsLoseFocus={true}
                                            pagination={true}
                                            paginationPageSize={10}
                                            paginationPageSizeSelector={[5, 10, 15, 20]}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className='d-flex mtop-2'>
                            {
                                loading ? <ButtonLoader color={'primary'} loaderText={'Submitting'} /> : <Button color='primary' onClick={onSubmit}>Submit</Button>
                            }
                            <Button color='secondary' className='ml-1' onClick={handleBack}>Back</Button>
                            <Button color='primary' className='trans-button' onClick={handleHistory}>History View/Update</Button>
                        </div>
                    </CardBody>
                </Card>
            </Container>
            {
                evidenceUploadOpen && <MonitoringDocUpload value={evidenceUploadOpen} category={'ev'}
                    setOpenModal={setEvidenceUploadOpen} data={rowData} projectId={projectId} year={moment(year).format('yyyy')}
                    month={moment(month).format('M')} frequencyValue={reportPeriodFreq}
                />
            }
        </Fragment>
    )
}

export default EvStationConsumptionList;