import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Card, Row, Col, FormGroup, Label, Button, Table, Spinner, CardBody, Container } from "reactstrap";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";
import { Download, Eye } from 'react-feather';
import moment from "moment";
import { Breadcrumbs } from '../../../../AbstractElements';
import { useSelector } from "react-redux";
import { populateFiscalTwoYears, populateFiscalYear } from "../../../api/helper";
import AgGridTable from "../../AgGridTable";
import ViewReport from "./ViewReport";
import { toast } from "react-toastify";
import { LuFileClock } from "react-icons/lu";
import { AiOutlineFileProtect } from "react-icons/ai";

const Reporting = () => {
    const reportType = useSelector(state => state?.user?.userDetails?.reportType);
    const [reportData, setReportData] = useState([]);
    const [financialYear, setFinancialYear] = useState();
    const [startMonth, setStartMonth] = useState();
    const [fiscalYearArray, setFiscalYear] = useState(populateFiscalTwoYears());
    const [financialYearArray, setFinancialYearArray] = useState(populateFiscalYear());
    const [categorizedData, setCategorizedData] = useState();
    const [open, setOpen] = useState(false);
    const [viewData, setViewData] = useState();
    const [historyState, setHistoryState] = useState(0);
    const [historyData, setHistoryData] = useState([]);

    const getAllReports = async () => {
        await fetchWrapper(`${API_URL}/api/docx/reportsstatus`)
            .then((res) => {
                console.log(res?.json)
                const categorizedData = res?.json?.reduce((acc, item) => {
                    const { financialYear } = item;
                    if (!acc[financialYear]) {
                        acc[financialYear] = [];
                    }
                    acc[financialYear].push(item);
                    return acc;
                }, {});
                const sortedData = Object.entries(categorizedData)
                    .sort(([yearA], [yearB]) => {
                        const parseYear = (year) => parseInt(year.split(" - ")[1], 10);
                        return parseYear(yearB) - parseYear(yearA);
                    })
                    .reduce((acc, [year, reports]) => {
                        acc[year] = reports;
                        return acc;
                    }, {});
                setCategorizedData(sortedData);
                const getUniqueData = Object.values(sortedData).map((ele) => ele[0]);
                setReportData(getUniqueData);
            }, (err) => {
                console.log(err)
            })
    }

    const downloadRef = useRef()

    const getFinancialYearSetup = async () => {
        await fetchWrapper(`${API_URL}/orgattributes`)
            .then((res) => {
                console.log(res.json?.startMonth)
                setStartMonth(res.json?.startMonth)
            }, (err) => { console.log(err) })
    }

    const downloadFile = async (data) => {
        if (data?.status === 'Completed') {
            await fetchWrapper(`${API_URL}/api/docx/getPresignedUrl/BRSR/${data?.financialYear}/${data?.requestId}`)
                .then(async (res) => {
                    console.log(res?.json)
                    const link = document.createElement('a');
                    link.href = res?.json;
                    link.download = 'document.pdf'
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }, (err) => {
                    console.log(err)
                })
        }
    }

    const handleView = (params) => {
        setOpen(true);
        setViewData(params);
    }

    const handleHistory = (data) => {
        setHistoryData(categorizedData[data.financialYear]?.filter((ele, index) => index !== 0))
        setHistoryState(1)
    }

    const fileReport = async (data) => {
        const options = {
            method: 'POST',
            body: {
                reportType: data?.reportType,
                financialYear: data?.financialYear,
                requestId: data?.requestId
            }
        }
        await fetchWrapper(`${API_URL}/api/docx/fileReport`, options)
            .then((res) => {
                console.log(res.json)
                toast.success('Report Filed Successfully!')
                getAllReports()
            }, (err) => {
                console.log(err)
                toast.error('Something went wrong!')
            })
    }

    const columnData = [
        {
            headerName: 'Report ID',
            field: 'requestId',
            filter: true,
            flex: 0.75,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Fiscal year',
            field: 'financialYear',
            filter: true,
            flex: 0.75,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Report Type',
            field: 'reportType',
            filter: true,
            flex: 0.7,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        ...(historyState === 0 ? [{
            headerName: 'Status',
            field: 'status',
            filter: true,
            flex: 0.5,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap'
        }] : []),
        {
            headerName: 'Generated Time (UTC)',
            field: 'status',
            filter: false,
            flex: 1,
            sortable: true,
            suppressMovable: true,
            valueGetter: (params) => {
                return params.data.generatedTime ? moment(params.data.generatedTime).format('DD MMM, YYYY hh:MM A') : ''
            }
        },
        {
            headerName: 'Generated By',
            field: 'username',
            filter: false,
            flex: 1,
            sortable: true,
            suppressMovable: true,
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap'
        },
        ...(historyState === 0 ? [{
            headerName: 'History',
            width: 80,
            sortable: false,
            suppressMovable: true,
            cellRenderer: (params) => {
                return (
                    <div onClick={() => handleHistory(params.data)}>
                        <LuFileClock className="history-icon" />
                    </div>
                )
            }
        }] : []),
        ...(historyState === 0 ? [
            {
                headerName: 'File',
                width: 80,
                sortable: false,
                suppressMovable: true,
                cellRenderer: (params) => {
                    if (params.data.reportFiledStatus !== 'Filed') {
                        return (
                            <div onClick={() => fileReport(params.data)}>
                                <AiOutlineFileProtect className="file-icon" />
                            </div>
                        )
                    } else if (params.data.reportFiledStatus === 'Filed') {
                        return (
                            <AiOutlineFileProtect className="file-icon" style={{ color: '#b4b2ba', cursor: 'default' }} />
                        )
                    }

                }
            }
        ] : []),
        ...(historyState === 0 ? [{
            headerName: 'View',
            width: 60,
            cellRenderer: params => {
                return (
                    <div onClick={() => handleView(params.data)}>
                        <Eye className={`action-icon`} style={{ marginLeft: '0.75rem' }} />
                    </div>
                )
            },
            suppressMovable: true,
        }] : []),
        {
            headerName: 'Downloads',
            cellRenderer: (params) => {
                return (
                    <a className="d-flex" onClick={() => downloadFile(params.data)}>
                        <Download className={`document-icon ${!params.data.status?.includes('Completed') ? 'disable-download' : ''}`} />
                    </a>
                )
            },
            width: 100,
            sortable: false,
            suppressMovable: true,
        },
    ]

    useEffect(() => {
        getFinancialYearSetup()
        getAllReports()
    }, [])

    const generateFile = async () => {
        const requestBody = {
            reportType: reportType,
            financialYear: financialYear
        }
        const options = {
            method: 'POST',
            body: requestBody
        }
        await fetchWrapper(`${API_URL}/api/docx/generate`, options)
            .then((res) => {
                console.log(res.json)

            }, (err) => {
                console.log(err)
                if (err.status === 200) {
                    toast.success('Processing your report. Thank you for your patience.')
                    getAllReports()
                } else if (err.status === 500) {
                    toast.error('Something went wrong!')
                }

            })
    }

    useEffect(() => {

    }, [historyState])

    return (
        <Fragment>
            {
                historyState === 0 ? <Container fluid={true} >
                    <Breadcrumbs mainTitle="Reports" />
                    <Card>
                        <CardBody>
                            <div className="d-flex justify-between">
                                <div className="d-flex">
                                    <FormGroup>
                                        <label>Report Type</label>
                                        <select className="form-select w-12 " name='reportType'
                                            value={reportType} disabled>
                                            <option value={''}>-Select Report Type-</option>
                                            <option value="Default">Default</option>
                                            <option value="BRSR">BRSR</option>
                                            <option value="GRI">GRI</option>
                                            <option value="SASB">SASB</option>
                                            <option value="TCFD">TCFD</option>
                                            <option value="ESG">ESG</option>
                                        </select>
                                    </FormGroup>
                                    <FormGroup className="ml-1 ">
                                        <label>Financial Year</label>
                                        {
                                            startMonth === 'January' ?
                                                <select className="form-select w-15" value={financialYear} onChange={(e) => setFinancialYear(e.target.value)}>
                                                    <option value={''}>-Select Fiscal Year-</option>
                                                    {
                                                        financialYearArray?.length > 0 && financialYearArray?.map((item) =>
                                                            (<option value={item}>{item}</option>))
                                                    }
                                                </select> :
                                                <select className="form-select w-15" value={financialYear} onChange={(e) => setFinancialYear(e.target.value)}>
                                                    <option value={''}>-Select Fiscal Year-</option>
                                                    {
                                                        fiscalYearArray?.length > 0 && fiscalYearArray?.map((item) =>
                                                            (<option value={item}>{item}</option>))
                                                    }
                                                </select>
                                        }
                                    </FormGroup>
                                </div>
                                <div className="d-flex">
                                    <Button color="primary" className="ml-1"
                                        disabled={(reportData?.some(report => (report.financialYear === financialYear && report.reportFiledStatus === 'Filed')) || !financialYear)}
                                        onClick={generateFile}>Generate</Button>
                                </div>

                            </div>
                            <div className="mtop-1">
                                <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                    <AgGridTable
                                        columnData={columnData}
                                        tableData={reportData}
                                        agRef={downloadRef}
                                    />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Container> :
                    historyState === 1 ?
                        <Container>
                            <Breadcrumbs mainTitle="History" />
                            <Card>
                                <CardBody>
                                    <div className="mtop-1">
                                        <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                            <AgGridTable
                                                columnData={columnData}
                                                tableData={historyData}
                                                agRef={downloadRef}
                                            />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            <Button color="secondary" onClick={() => setHistoryState(0)}>Back</Button>
                        </Container> : <></>
            }
            {open && <ViewReport value={open} setOpenModal={setOpen} data={viewData} />}
        </Fragment>
    )
}

export default Reporting;